
import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Loader from '../loader/Loader';
import Network from '../Network/Network';
function CollegeList() {
   const [loader,setloader]=useState(false);
    const [collegelistdata, setstateforcolleglist] = useState([]);
    useEffect(() => { sendDetailsToServer(); },[]);
    const sendDetailsToServer = async () => {
        setloader(true);
        axios.post(Network.Network.api_url+"college_list")
            .then(function (response) {
                 setstateforcolleglist(response.data.data)
                 setloader(false);
                
            })

    }
    setTimeout(function(){
        $('.table').DataTable();
         } ,2000);
    return (<>
      {loader==true ? <Loader></Loader>:''}
        <Header />
        <Sidebar />
        <main id="main" className="main">
            <section className="section Applicationmain"><h5 className="card-title">College List</h5><div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"><div className="dataTable-container">

                <table className="table datatable dataTable-table">

                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Address</th>
                            </tr>
                        
                        </thead>
                        <tbody>
                        {collegelistdata.length > 0 ? collegelistdata.map((items, i) => {
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{items.college_name}</td>
                                    <td>{items.college_email}</td>
                                    <td>{items.college_phone}</td>
                                    <td>{items.college_address}</td>
                                </tr>
                            )
                        })

                        : ""}
                            
                                </tbody>
                            </table>
                
                </div></div></section></main>
        <Footer />
    </>)
}

export default CollegeList;

