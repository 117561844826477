import React from 'react'
import { useEffect,useState} from 'react';
import firebase from 'firebase';

export default function RecentNotification() {

    const color=['text-danger','text-success','text-primary','text-info','text-warning','text-muted'];
    const [Notification,setNotification]=useState([]);
    console.log('Notification',Notification);
    const user_id = localStorage.getItem('token-info');
    const user_type = localStorage.getItem('user_type');
    useEffect(() => {
        if(user_type!=='Admin')
        {
            get_notification();
        }
         
    }, []);

    const get_notification=()=>{
        const db = firebase.database();
        const chatRef = db.ref(`notification/${user_id}`);
        chatRef.on("value",(snapshot) => {
            setNotification(snapshot.val());
            const data = snapshot.val();

            const  sortedNotifications= data ? Object.values(data) : "";
            
            const allNotification=sortedNotifications.sort((a, b) => b.time - a.time);
            const firstsixNotifications = allNotification.slice(0, 6);

            setNotification(firstsixNotifications);

        });  
    }
  return (
    <div>
        <div className="card-body">
                                <h5 className="card-title">Recent Notification <span></span></h5>

                                <div className="activity">
                                    {
                                      Notification ? 
                                      Notification.map((i,e)=>{
                                          return( <div key={e} className="activity-item d-flex">
                                          <div className="activite-label">{new Date(i.time).toLocaleString("en-US", { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })}</div>
                                          <i className={`bi bi-circle-fill activity-badge ${color[Math.floor(Math.random() * color.length)]} align-self-start`}></i>
                                          <div className="activity-content">
                                              {i.noti} 
                                          </div>
                                      </div>)

                                      })
                                      : " "}
                                </div>

                            </div>
    </div>
  )
}
