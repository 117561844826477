

import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";
import RecentNotification from '../Notification/RecentNotification';


// import Header from "../Admin/Header";
import Loader from '../loader/Loader';
import Network from '../Network/Network'


function AgentDashboard() {
    const navigate = useNavigate();
    const [application,setapplication]=useState([]);
    const [countapplication,setcount]=useState('');
    const [error,seterror]=useState('');
    const value= localStorage.getItem('token-info');
    const formdata=new FormData();
    formdata.append('userid',value);     
  
    useEffect(() => {
        agent_dashborad_api();
      },[]);
      
     const agent_dashborad_api= async () => {
        axios.post(Network.Network.api_url+'agent_dashborad_api',formdata)
        .then(response => {
             if(response.data.status=='success')
             {
                 setapplication(response.data.data);
                 setcount(response.data.count_application);
             }
        })
        .catch(error => {
         seterror(error);
        });
     } 
    return (<>
        <Header />
        <Sidebar />

        <link rel="stylesheet" href="/../adminasset/css/style.css" />
        <link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" relation="stylesheet" type="text/css" />

        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Dashboard</h1>
                {/* <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                </nav> */}
            </div>

            <section className="section dashboard">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-xxl-6 col-md-6">
                                <div className="card info-card sales-card">

                                   
                                    <div className="card-body">
                                        <h5 className="card-title">Total Application <span></span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-book"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>{countapplication}</h6>
                                            

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xxl-6 col-md-6">
                                <div className="card info-card revenue-card">

                               

                                    <div className="card-body">
                                        <h5 className="card-title">Approved Application<span></span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-currency-dollar"></i>
                                            </div>
                                            <div className="ps-3">
                                                <h6>0</h6>
                                                <span className="text-success small pt-1 fw-bold">0</span> <span className="text-muted small pt-2 ps-1">0</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                          
                           
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">

                                    <div className="card-body">
                                        <h5 className="card-title">Application<span></span></h5>

                                        <table className="table table-borderless datatable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Application ID</th>
                                                    <th scope="col">Created At</th>
                                                    <th scope="col"> Name</th>
                                                    <th scope="col">College Name </th>

                                            
                                                 
                                                    <th scope="col">Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                 {
                                                 application.length > 0 ?  
                                                   application.map((e,i)=>{  
                                               return (<tr key={i}>
                                                    <td scope="row">
                                                        <Link to={`/agent/viewapplication?yaja=${ e.id}`} >


                                                        {e.application_id}</Link>
                                            
                                                        </td>
                                                        <td>{e.created_at}</td>
                                                    <td>{e.first_name} {e.last_name}</td>
                                                    <td><a href="#" className="text-primary"></a>{e.college_name}</td>
                                                    <td>N/A</td>
                                                   
                                                 
                                                </tr>)
                                                })
                                                 :'' }
                                            </tbody>
                                        </table>

                                    </div>

                                </div>
                            </div>
                        

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                           

                            <div className="card-body">
                                {/* <h5 className="card-title">Recent Notification <span></span></h5> */}

                                <RecentNotification></RecentNotification>

                            </div>
                        </div>


                        <div className="card">
                           
                           
                        </div>
                        <div className="card">
                          
                            <div className="card-body pb-0">
                                <h5 className="card-title">News &amp; Updates <span>| Coming soon</span></h5>

                                {/* <div className="news">
                                    <div className="post-item clearfix">
                                        <img src="assets/img/news-1.jpg" alt="" />
                                        <h4><a href="#">Nihil blanditiis at in nihil autem</a></h4>
                                        <p>Sit recusandae non aspernatur laboriosam. Quia enim eligendi sed ut harum...</p>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/img/news-2.jpg" alt="" />
                                        <h4><a href="#">Quidem autem et impedit</a></h4>
                                        <p>Illo nemo neque maiores vitae officiis cum eum turos elan dries werona nande...</p>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/img/news-3.jpg" alt="" />
                                        <h4><a href="#">Id quia et et ut maxime similique occaecati ut</a></h4>
                                        <p>Fugiat voluptas vero eaque accusantium eos. Consequuntur sed ipsam et totam...</p>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/img/news-4.jpg" alt="" />
                                        <h4><a href="#">Laborum corporis quo dara net para</a></h4>
                                        <p>Qui enim quia optio. Eligendi aut asperiores enim repellendusvel rerum cuder...</p>
                                    </div>

                                    <div className="post-item clearfix">
                                        <img src="assets/img/news-5.jpg" alt="" />
                                        <h4><a href="#">Et dolores corrupti quae illo quod dolor</a></h4>
                                        <p>Odit ut eveniet modi reiciendis. Atque cupiditate libero beatae dignissimos eius...</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer />
    </>)
}
export default AgentDashboard;