import React, { useState, useEffect, useRef } from "react";
import firebase from 'firebase';
import { useLocation, Link } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Loader from "../loader/Loader";
import Decline from "./Decline";
import Header from "../Admin/Header";
import Sidebar from "../Agent/Sidebar";
import Sidebar1 from "../College/Sidebar";
import Sidebar3 from "../Admin/Sidebar";
import Network from "../Network/Network";

export default function Chat() {
    const chatboxRef = useRef(null);
    const [loader, setloader] = useState(false);
    const [selectedImage1, setSelectedImage1] = useState(null);
    const [hideinput, sethideinput] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [application_info, setapplication_info] = useState([]);
    const [depositFeeReceipt, setDepositFeeReceipt] = useState(false);
    const location = useLocation();
    const application_id = new URLSearchParams(location.search).get("id");
    const [messages, setMessages] = useState([]);
    const [message_type, setmessage_type] = useState('');
    const tokenname = localStorage.getItem('token-name');
    const photo = localStorage.getItem('photo');
    const user_type = localStorage.getItem('user_type');
    // alert(user_type);
    const user_id = localStorage.getItem('token-info');
    const [sortedData, setdocument] = useState([]);
    const documents = [...sortedData].sort((a, b) => {
        if (a.status === 'decline' && b.status !== 'decline') {
            return -1;
        } else if (a.status !== 'decline' && b.status === 'decline') {
            return 1;
        } else if (a.status === 'Approved' && b.status !== 'Approved') {
            return -1;
        } else if (a.status !== 'Approved' && b.status === 'Approved') {
            return 1;
        } else {
            return 0;
        }
    });
    //   console.log('documents',documents);
    const [pdf, setpdf] = useState(false);
    const [checkuser, setuser] = useState('');
    // alert(checkuser)
    useEffect(() => {
        get_message();
        get_application_details();
        chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;

        checkuser1();
        setfunction();

    }, []);



    const checkuser1 = () => {

        if (user_type === 'Agent') {
            setuser('agent');
        }
        else if (user_type === 'Admin') {
            setuser('admin');

        }
        else {
            setuser('College');

        }

    }

    const handleChangechat_file = (e) => {
        sethideinput(true);
        setSelectedImage1(URL.createObjectURL(e.target.files[0]));
        const fordata = new FormData();
        fordata.append('file', e.target.files[0]);
        axios.post(Network.Network.api_url + "file_upload", fordata)
            .then(function (response) {
                // console.log('response', response);
                if (response.data.status === "success") {
                    setMessages(response.data.data);
                    setmessage_type(response.data.type);
                }
            })
    };




    const handleSubmit = (event) => {
        event.preventDefault();
        if (messages !== '' && application_id !== null) {
            const db = firebase.database();
            db.ref(`chat/${application_id}`).push({
                read: 0,
                seen: 0,
                messages: messages,
                noti: '',
                mess_type: message_type,
                application_id: application_id,
                sender_id: user_id,
                sender_name: tokenname,
                sender_photo: photo,
                sender_type: user_type,
                time: Date.now()
            });

            //  send notification
            const idddd = user_type === 'Agent' ? application_info.college_id : application_info.added_by;
            db.ref(`notification/${idddd}`).push({
                read: 0,
                seen: 0,
                messages: messages,
                noti: `Received New Message in Application Profile of Student ${application_info.first_name}  ${application_info.last_name}`,
                mess_type: message_type,
                user_type: user_type,
                college_id: application_info.college_id,
                get_id: idddd,
                agent_id: application_info.added_by,
                application_id: application_id,
                url: `chat/chat?id=${application_id}`,
                sender_id: user_id,
                sender_name: tokenname,
                sender_photo: photo,
                sender_type: user_type,
                time: Date.now()
            });

            // 

            setMessages('');
            setmessage_type('');
            setloader(false);
            setSelectedImage1(null);
            sethideinput(false);

        }
        else {
            return false;
        }
        setloader(false);
        sethideinput(false);
        setSelectedImage1(null);

    }
    const inputhandle = (e) => {
        setMessages(e.target.value);
        setmessage_type('text');
    }

    const get_message = () => {
         console.log('application_id-----------------------------',application_id);
        const db = firebase.database();
        const chatRef = db.ref(`chat/${application_id}`);
        chatRef.on("value", (snapshot) => {
            setChatMessages(snapshot.val());
        });

    }

    const get_application_details = () => {
        get_approvedstatus();
        const formdata = new FormData();
        formdata.append('id', application_id);
        axios.post(Network.Network.api_url + "get_application_details_inforename", formdata)
            .then(function (response) {

                if (response.data.status === "success") {
                    setapplication_info(response.data.data[0]);
                    setdocument(response.data.res);
                    setFeeReciept(response.data.data[0].fee_deposit_receipt)
                    setfunction();
                }

            })
    }

    const get_approvedstatus = () => {
        const fordata = new FormData();
        fordata.append('application_id', application_id);
        axios.post(Network.Network.api_url + "get_approvedstatus", fordata)
            .then(function (response) {
                if (response.data.status === "success") {
                    if (response.data.data == "yes" && response.data.income_data == "yes") {
                        setarroved(response.data.data);
                    }
                }
            })
    };
    var action1 = '';
    var column1 = '';
    const [action, setaction] = useState('');
    const [column, setcolumn] = useState('');
    const [reason, setreason] = useState('');
    const [type, setType] = useState('');
    const action_document = (action1, column1, type) => {
        setaction(action1);
        action1 = action1;
        column1 = column1;
        setcolumn(column1);
        setType(type)
        if (action1 == 'Approved') {
            setaction(action1);
            save_documents_response(action1, column1, type);
            setloader(true);
        }

        // alert(action);
    }
    const [formvalue, setformvalue] = useState();
    const onchangevalue = (e) => {
        setformvalue(e.target.value);
    }
    const onsubmitreason = (e) => {
        e.preventDefault();
        setreason(formvalue);
        setloader(true);
        save_documents_response(action, column, type);
    }

    const save_documents_response = (action1, column1, type) => {
        // alert(column1)
        // alert(action1)
        // return false;
        console.log("type", type);
        const formdata = new FormData();
        formdata.append('id', application_id);
        formdata.append('action', action1);
        formdata.append('type', type);
        formdata.append('column', column1);
        formdata.append('reason', formvalue);
        formdata.append('updated_by', user_id);
        // console.log('formdata',formdata)
        axios.post(Network.Network.api_url + "application_doc_validation", formdata)
            .then(function (response) {
                // console.log('response', response);
                if (response.data.status === "success") {
                    alert(response.data.message);
                    send_documents_notification();
                    get_application_details();
                    setloader(false);


                }
                else {
                    setaction('');
                    setcolumn('');
                    setreason('');
                    alert(response.data.message);
                    setloader(false);
                    setformvalue('');
                    get_application_details();
                }

            })

    }

    const send_documents_notification = () => {
        //  send notification
        const db1 = firebase.database();



        const idddd1 = user_type === 'Agent' ? application_info.college_id : application_info.added_by;
        db1.ref(`notification/${idddd1}`).push({
            read: 0,
            seen: 0,
            messages: messages,
            noti: `${tokenname} :- ${action} document Application Profile of Student ${application_info.first_name}  ${application_info.last_name}`,
            mess_type: message_type,
            user_type: user_type,
            college_id: application_info.college_id,
            get_id: idddd1,
            agent_id: application_info.added_by,
            application_id: application_id,
            url: `chat/chat?id=${application_id}`,
            sender_id: user_id,
            sender_name: tokenname,
            sender_photo: photo,
            sender_type: user_type,
            time: Date.now()
        });

        setaction('');
        setcolumn('');
        setreason('');
        setformvalue('');
    }
    // reupload notification 
    const reupload_file = () => {
        const db1 = firebase.database();
        const idddd1 = user_type === 'Agent' ? application_info.college_id : application_info.added_by;
        db1.ref(`notification/${idddd1}`).push({
            read: 0,
            seen: 0,
            messages: messages,
            noti: `${tokenname} :- ${action} document Application Profile of Student ${application_info.first_name}  ${application_info.last_name}`,
            mess_type: message_type,
            user_type: user_type,
            college_id: application_info.college_id,
            get_id: idddd1,
            agent_id: application_info.added_by,
            application_id: application_id,
            url: `chat/chat?id=${application_id}`,
            sender_id: user_id,
            sender_name: tokenname,
            sender_photo: photo,
            sender_type: user_type,
            time: Date.now()
        });

    }
    const [file_type, setupload_type] = useState('');
    const [uploadfiles, setuploads] = useState('');
    const [letterSignedUpload, setLetterSignedUpload] = useState('');
    const uploadapplication_file = (e) => {
        setuploads(e.target.files[0]);
    }
    const uploadSignedOfferLetter = (e) => {
        setLetterSignedUpload(e.target.files[0])
    }

    const uploadOfferLetter = (e) => {
        e.preventDefault();
        setloader(true);
        const formdata = new FormData();
        formdata.append('id', application_info.id);
        if (application_info.offerletter_sign == null) {
            formdata.append('offerletter', letterSignedUpload);
        }
        else {
            formdata.append('fee_deposit_receipt', letterSignedUpload);
        }
        axios.post(Network.Network.api_url + "offerLetterSignedUpload", formdata).then((response) => {
            if (response.data.status === "success") {
                setloader(false);
                setLetterSignedUpload('');
                $('#offerLetter').val('');
                get_application_details();
                alert(response.data.message);
            }
            else {
                get_application_details();
                alert(response.data.message);
            }
        })
    }

    const upladfilesubmit = (e) => {
        e.preventDefault()
        setloader(true);
        const formdata = new FormData();
        console.log("application info", application_info);
        formdata.append('id', application_info.id);
        formdata.append('file_type', file_type);
        formdata.append('file_upload', uploadfiles);
        formdata.append('type', documentType)
        axios.post(Network.Network.api_url + "reupload_file", formdata)
            .then(function (response) {
                //   console.log('response---',response);
                if (response.data.status === "success") {
                    setloader(false);
                    setuploads('')
                    setupload_type('');
                    get_application_details();
                    reupload_file();
                    $('#reupload').val('');
                }
                else {

                    get_application_details();
                }

            })

    }

    const setfunction = () => {
        if (application_info.policy_upload_status === 'Approved'
            && application_info.visa_details_upload_status === 'Approved'
            && application_info.upload_academics_status === 'Approved'
            && application_info.upload_ielts_status === 'Approved'
            && application_info.other_doc_status === 'Approved'
            && application_info.upload_passport_status === 'Approved') {

            setpdf(true)
        }
    }
    const send_offerletter_notification = () => {
        const db1 = firebase.database();
        const idddd1 = user_type === 'Agent' ? application_info.college_id : application_info.added_by;
        db1.ref(`notification/${idddd1}`).push({
            read: 0,
            seen: 0,
            messages: messages,
            noti: `${tokenname} :-Offerletter Generated
       document Application Profile of Student ${application_info.first_name}  ${application_info.last_name}`,
            mess_type: message_type,
            user_type: user_type,
            college_id: application_info.college_id,
            get_id: idddd1,
            agent_id: application_info.added_by,
            application_id: application_id,
            url: `chat/chat?id=${application_id}`,
            sender_id: user_id,
            sender_name: tokenname,
            sender_photo: photo,
            sender_type: user_type,
            time: Date.now()
        });

    }

    const updateFeeStatus = (data) => {
        setloader(true);
        const formdata = new FormData();
        formdata.append('id', application_info.id);
        formdata.append('status', data);
        axios.post(Network.Network.api_url + "updateFeeStatus", formdata).then((response) => {
            setloader(false)
           if(response.data.status == "success")
           {
            setloader(false);
              alert(response.data.message);
              get_application_details();
           }
           else
           {
            setloader(false);
            alert(response.data.message);
            get_application_details();
           }
        }).catch((e) => {
            setloader(false)
            console.log("error", e)
        });
    }

    const onFeeStatusUpdate = (e) => {
        e.preventDefault()
        setloader(true);
        const formdata = new FormData();
        formdata.append('id', application_info.id);
        formdata.append('reason', formvalue);
        axios.post(Network.Network.api_url + "updateFeeStatus", formdata).then((response) => {
            setloader(false);
            console.log("response", response);
            if(response.data.status == "success") {
                alert(response.data.message);
                get_application_details();
                setloader(false)
            }
            else {
                alert(response.data.message);
                setloader(false)
            }
        }).catch((e) => {
            setloader(false)
            console.log("error", e)
        });
    }
    const send_offerletter = () => {
        setloader(true);
        const formdata = new FormData();
        formdata.append('id', application_id);
        axios.post(Network.Network.api_url + "send_offerletter", formdata)
            .then(function (response) {
                alert("Offer Letter Sended Successfully");
                console.log("respoonse", response);
                if (response.data.status === "success") {
                    alert("Offer Letter Sended Successfully");
                    get_application_details();
                    send_offerletter_notification()
                    setloader(false);

                }
                else {
                    setloader(false);
                    get_application_details();
                }
            })
    }
    const [approvestatus, setarroved] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [feeReciept, setFeeReciept] = useState({});
    const reUploadDocument = (name, type) => {
        setupload_type(name);
        setDocumentType(type);
    }
    const reuploadfee = (e) => {
        setloader(true);
        e.preventDefault()
        const formdata = new FormData();
        formdata.append('id', application_id);
        formdata.append('image', uploadfiles)
        console.log("formdata", formdata);
        axios.post(Network.Network.api_url + "reUploadFeeDocument", formdata).then((response) => {
            if (response.data.status === "success") {
                alert(response.data.message);
                setloader(false);
                get_application_details();
                setuploads('');
            }
            else {
                alert(response.data.message)
                setloader(false);
                get_application_details();
            }
        })
    }

    const uploadCoe = (e) => {
        e.preventDefault();
        setloader(true);
        const formdata = new FormData();
        formdata.append('id', application_info.id);
        formdata.append('pdf', uploadfiles);
        axios.post(Network.Network.api_url + "coeUpload", formdata).then((response) => {
          if(response.data.status === "success")
          {
            alert(response.data.message);
            setloader(false);
            get_application_details();
            setuploads('');
          }
          else
          {
             setloader(false);
             alert(response.data.message);
             get_application_details();
             setuploads('');
          }
        });
    }

    console.log('approvestatus', approvestatus)
    return (
        <div>
            {loader == true ? <Loader></Loader> : ''}
            <Header />
            {/* <Sidebar1 /> */}
            {checkuser === 'agent' ? <><Sidebar /></> : checkuser === 'admin' ? <><Sidebar3 /></> : <><Sidebar1 /></>}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Decline Reason </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onsubmitreason}>
                                <div className="mb-3">
                                    <label className="form-label">Enter Decline Reason</label>
                                    <input type="text" className="form-control" name="reason" value={formvalue} onChange={onchangevalue} placeholder="Enter Decline Reason" />
                                </div>
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" >Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* reupload file */}

            <div className="modal fade" id="feerecieptmodel" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Upload File</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onFeeStatusUpdate}>
                                <div className="mb-3">
                                <label className="form-label">Enter Decline Reason</label>
                                    <input type="text" className="form-control" name="reason" value={formvalue} onChange={onchangevalue} placeholder="Enter Decline Reason" />
                                </div>
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" >Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="reuploadfee" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Upload File</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={reuploadfee}>
                            <div class="modal-body">
                                <input type="file"  className="form-control" onChange={uploadapplication_file} accept=".pdf" required />
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Upload File</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={upladfilesubmit}>
                            <div class="modal-body">
                                <input type="file" name="file_upload" id="reupload" className="form-control" onChange={uploadapplication_file} accept=".pdf" required />
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* end */}

            {/* offer letter signed file */}


            <div class="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Upload File</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={uploadOfferLetter}>
                            <div class="modal-body">
                                <input type="file" name="file_upload" id="offerLetter" className="form-control" onChange={uploadSignedOfferLetter} accept=".pdf" required />
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* end */}

           {/* offer letter signed file */}


            <div class="modal fade" id="coeupload" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Upload File</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={uploadCoe}>
                            <div class="modal-body">
                                <input type="file" name="file_upload" id="offerLetter" className="form-control" onChange={uploadapplication_file} accept=".pdf" required />
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* end */}

            <div className=" message-area container">
                <div className="row">
                    <div className="col-12">
                        <div className="chat-area">
                            <div className="chatlist">
                                <div className="modal-dialog-scrollable">
                                    <div className="modal-content">
                                        <div className="chat-header">
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="Open-tab" data-bs-toggle="tab" data-bs-target="#Open" type="button" role="tab" aria-controls="Open" aria-selected="true">Documents</button>
                                                </li>

                                            </ul>
                                        </div>
                                        <Decline></Decline>
                                        <div className="modal-body">
                                            <div className="chat-lists">
                                                <div className="tab-content" id="myTabContent">
                                                    <div className="tab-pane fade show active" id="Open" role="tabpanel" aria-labelledby="Open-tab">
                                                        <div className="chat-list">



                                                            {


                                                                approvestatus == 'yes' ?
                                                                    <>
                                                                        {console.log("aplllication", application_info)}
                                                                        {
                                                                            user_type === "Agent" && approvestatus == 'yes' && application_info.coe_upload !== null ?
                                                                                <div className="set">
                                                                                    <h4>
                                                                                        COE document <a target="_blank" href={`${Network.Network.image_url}${application_info.coe_upload}`} className="align-items-center btn-primary">
                                                                                            <img  src={Network.Network.image_url + `1676533719_Preview.png`} /></a>
                                                                                    </h4>
                                                                                </div>
                                                                                : ""
                                                                        }
                                                                        {user_type === "college" && approvestatus == "yes" && feeReciept && feeReciept.status == "Approved" && application_info.coe_upload == null ?
                                                                           <div className="set">
                                                                               <h4><a data-bs-toggle="modal" data-bs-target="#coeupload" style={{color: "red"}}>COE upload</a></h4>
                                                                           </div>
                                                                        : ""}
                                                                        {user_type === "college" && approvestatus == "yes" && application_info && application_info.coe_upload ?
                                                                           <div className="set">
                                                                               <h4><a>COE uploaded</a></h4>
                                                                           </div>
                                                                        : ""}
                                                                        {user_type === "college" && approvestatus == "yes" && feeReciept ?
                                                                            <div className="set">

                                                                                <h4>Fee depoist reciept<a target="_blank" href={`${Network.Network.image_url}${feeReciept?.document}`} className="align-items-center btn-primary">
                                                                                    <img src={Network.Network.image_url + `1676533719_Preview.png`} />

                                                                                </a></h4>
                                                                                <p><bold><strong >{feeReciept?.status}</strong></bold>  <span style={{ color: "red" }}>{feeReciept?.reason}</span></p>
                                                                                {checkuser === "College" && feeReciept?.status !== 'Approved' && feeReciept?.status !== "decline" ?
                                                                                    <h6>
                                                                                        <button type="button" className="btn-primary" onClick={() => updateFeeStatus('Approved')}>Approve</button>
                                                                                        <button type="button" className="btn-primary" data-bs-toggle="modal" data-bs-target="#feerecieptmodel" onClick={() => updateFeeStatus('decline')}>decline</button>
                                                                                    </h6>
                                                                                    : ""}
                                                                                    
                                                                                     </div>
                                                                            : ""
                                                                        }

                                                                        {
                                                                            user_type === "college" && approvestatus == 'yes' && application_info.offerletter_sign !== null ?
                                                                                <div className="set">
                                                                                    <h4>
                                                                                        Signed offer letter <a target="_blank" href={`${Network.Network.image_url}${application_info.offerletter_sign}`} className="align-items-center btn-primary">
                                                                                            <img  src={Network.Network.image_url + `1676533719_Preview.png`} /></a>
                                                                                    </h4>
                                                                                </div>
                                                                                : ""
                                                                        }
                                                                        {user_type === "college" && approvestatus == 'yes' && application_info.offerletter !== null ?
                                                                            <div className="set">
                                                                                <h6>
                                                                                    <button >
                                                                                        Offer letter Generated </button>
                                                                                </h6>
                                                                            </div> :
                                                                            user_type === "college" && approvestatus == 'yes' ?
                                                                                <div className="set">
                                                                                    <h6>
                                                                                        <button onClick={send_offerletter}>
                                                                                            Generate offerletter</button>
                                                                                    </h6>
                                                                                </div> : ''}
                                                                        {/* {user_type === "Agent" && application_info.offerletter_sign ?
                                                                            <div className="set">
                                                                             {!application_info.fee_deposit_receipt ? <a target="_blank" style={{cursor: "pointer", color: "#4154f1"}} data-bs-toggle="modal" data-bs-target="#exampleModal2">Upload fee reciept</a> : ""}
                                                                            </div>
                                                                            :  ""
                                                                        } */}
                                                                        {console.log("application", application_info)}
                                                                        {user_type === "Agent" && application_info.offerletter_sign == null && application_info.offerletter != null ?
                                                                            <div className="set">
                                                                                <a target="_blank" style={{ cursor: "pointer", color: "#4154f1" }} data-bs-toggle="modal" data-bs-target="#exampleModal2">Upload signed offer letter</a>
                                                                            </div>
                                                                            : ""
                                                                        }
                                                                        {user_type == "Agent" && application_info.offerletter_sign !== null && application_info.fee_deposit_receipt == null ?
                                                                            <div className="set">
                                                                                <a target="_blank" style={{ cursor: "pointer", color: "#4154f1" }} data-bs-toggle="modal" data-bs-target="#exampleModal2">reciept upload</a>

                                                                            </div>
                                                                            : ""}
                                                                        {user_type == "Agent" && application_info.fee_deposit_receipt !== null && !feeReciept?.status ?
                                                                            <div className="set">
                                                                                <a>Fee reciept uploaded</a>
                                                                            </div>
                                                                            : ""}

                                                                        {checkuser !== "College" && checkuser !== "admin" && feeReciept?.status === "decline" ? 
                                                                            <div className="set">
                                                                                <p><bold><strong >{feeReciept?.status}</strong></bold>  <span style={{ color: "red" }}>{feeReciept.reason}</span></p>
                                                                                <p>your reciept is decline please</p>
                                                                                <a className="btn-primary" data-bs-toggle="modal" data-bs-target="#reuploadfee" style={{ color: "red", cursor: "pointer" }}>
                                                                                    Reupload file
                                                                                </a> 
                                                                                </div> : ''}
                                                                               
                                                                        {checkuser !== "College" && checkuser !== "admin" && feeReciept?.status === "Approved" ?
                                                                             <div className="set">

                                                                             <h4>Fee depoist reciept<a target="_blank" href={`${Network.Network.image_url}${feeReciept?.document}`} className="align-items-center btn-primary">
                                                                                 <img src={Network.Network.image_url + `1676533719_Preview.png`} />

                                                                             </a></h4>
                                                                             <p><bold><strong >{feeReciept?.status}</strong></bold>  <span style={{ color: "red" }}>{feeReciept?.reason}</span></p></div> : ''}


                                                                        {user_type == "Agent" && application_info.offerletter_sign !== null ?
                                                                            <div className="set">
                                                                                <a>Signed Offer Letter Uploaded</a>
                                                                            </div>
                                                                            : ""}

                                                                        {user_type === "Agent" && application_info.offerletter !== '' ?
                                                                            <div className="set">

                                                                                <a target="_blank" href={`${Network.Network.image_url}/${application_info.offerletter}`}>Download Offer Letter </a>
                                                                            </div>
                                                                            : ''}  </>
                                                                    : ''



                                                            }
                                                            {
                                                                documents.length > 0 ?
                                                                    documents.map((doc, index) => (
                                                                        doc.document !== '' ?
                                                                            <div key={index} className="set">
                                                                                <h4>{doc.name_use} <a target="_blank" href={`${Network.Network.image_url}${doc.document}`} className="align-items-center btn-primary">
                                                                                    <img src={Network.Network.image_url + `1676533719_Preview.png`} />
                                                                                </a></h4>

                                                                                <p><bold><strong >{doc.status}</strong></bold>  <span style={{ color: "red" }}>{doc.reason}</span></p>
                                                                                <h6>

                                                                                    {
                                                                                        checkuser === "College" && doc.status !== 'Approved' && doc.status !== "decline" ?
                                                                                            <>

                                                                                                <button type="button" className="btn-primary" onClick={() =>
                                                                                                    action_document(`Approved`, doc.name, doc.type)
                                                                                                }>Approve</button>
                                                                                                <button type="button" className="btn-primary" onClick={() => action_document(`decline`, doc.name, doc.type)} data-bs-toggle="modal" data-bs-target="#exampleModal">decline</button></>
                                                                                            : " "}

                                                                                    {checkuser !== "College" && checkuser !== "admin" && doc.status === "decline" ?
                                                                                        <button className="btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => reUploadDocument(doc.name, doc.type)}>
                                                                                            Upload file
                                                                                        </button> : ''}

                                                                                </h6>
                                                                            </div>
                                                                            : ''
                                                                    ))
                                                                    : <Loader></Loader>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="chatbox" >
                                <div className="modal-dialog-scrollable" >
                                    <div className="modal-content">
                                        <div className="msg-head">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="d-flex align-items-center">
                                                        <span className="chat-icon"><img className="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg" alt="image title" /></span>
                                                        {/* <div className="flex-shrink-0">
                                                    <img className="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/user.png" alt="user img"/>
                                                </div> */}
                                                        <div className="flex-grow-1 ms-3">
                                                            <h3>{application_info.first_name} {application_info.last_name}</h3>
                                                            <p>{application_info.application_id}</p>
                                                        </div>

                                                        <div className="flex-grow-1 ms-3">
                                                            Added By
                                                            <h3>{application_info.agentfname} {application_info.agentlname}</h3>
                                                            <p></p>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="modal-body" ref={chatboxRef}>
                                            <div className="msg-body" >
                                                <ul>
                                                    {
                                                        chatMessages ?
                                                            Object.values(chatMessages).map((message, index) => (
                                                                message.sender_id == user_id ?
                                                                    <><li className="repaly" key={index}><p>
                                                                        {message.mess_type === 'png' ||
                                                                            message.mess_type === 'gif' || message.mess_type === 'jpg' || message.mess_type === 'jpeg' ? <img src={`${Network.Network.image_url}${message.messages}`} /> : message.mess_type === 'pdf' ? <a target="_blank" href={`${Network.Network.image_url}${message.messages}`} download><i className="fa-solid fa-file-pdf"></i>
                                                                                {message.messages}</a> : message.mess_type === 'text' ? message.messages : ''}
                                                                    </p> <span className="time">{new Date(message.time).toLocaleString("en-US", { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })}</span></li></>
                                                                    :
                                                                    <><li className="sender"><p>
                                                                        {
                                                                            message.mess_type === 'gif' ||
                                                                                message.mess_type === 'png' || message.mess_type === 'jpg' || message.mess_type === 'jpeg' ? <img src={`${Network.Network.image_url}${message.messages}`} /> : message.mess_type === 'pdf' ? <a href={`${Network.Network.image_url}${message.messages}`} download><i className="fa-solid fa-file-pdf"></i> {message.messages}</a> : message.mess_type === 'text' ? message.messages : ''}
                                                                    </p>
                                                                        <h6>From {message.sender_name}</h6>
                                                                        <span className="time">{new Date(message.time).toLocaleString("en-US", { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })}</span>
                                                                    </li>
                                                                    </>
                                                            ))
                                                            : ""}


                                                </ul>
                                            </div>
                                        </div>

                                        {user_type !== 'Admin' ?
                                            <div className="send-box">
                                                <form onSubmit={handleSubmit}>
                                                    {hideinput == false ?
                                                        <input type="text" className="form-control" aria-label="message…" placeholder="Write message…"
                                                            value={messages}
                                                            onChange={inputhandle} />
                                                        : " "}
                                                    <button type="submit"><i className="fa fa-paper-plane" aria-hidden="true"></i> Send</button>
                                                </form>

                                                <div className="send-btns">
                                                    <div className="attach">
                                                        <div className="button-wrapper">
                                                            <span className="label">
                                                                <img className="img-fluid" src="https://mehedihtml.com/chatbox/assets/img/upload.svg" alt="image title" />
                                                            </span>

                                                            <input type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File" onChange={handleChangechat_file} />

                                                            {selectedImage1 !== null &&

                                                                <img style={{ width: '78px' }} src={selectedImage1} alt="Selected" />}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}






