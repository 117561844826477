
  export default{
    Network:{
               image_url:'https://enrolguru.com/enrolguru_backend/images/',
             // image_url:"https://digittrix-staging.com/staging/enrolguru/images/",
             api_url:'https://enrolguru.com/enrolguru_backend/api/'
           // api_url:"https://digittrix-staging.com/staging/enrolguru/api/"
      }
  }





