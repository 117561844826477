
import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";
import Loader from '../loader/Loader';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { Link } from 'react-router-dom';
//end

import Network from '../Network/Network';


function All_applications() {

    const [applicationlistdata, setstateforapplicationlist] = useState([]);
    const added_by = localStorage.getItem('token-info');
    useEffect(() => { sendDetailsToServer();  }, []);
    const sendDetailsToServer = async () => {
        axios.post(Network.Network.api_url+"get_application_listfor_admin")
            .then(function (response) {
                // console.log('test',response.data.data);
                setstateforapplicationlist(response.data.data)
            })
    }

    
    setTimeout(function(){
        $('.myTable').DataTable();
         } ,6000);
    return (<>
        <Header />
        <Sidebar />
       
        <main id="main" className="main">
            <section className="section Applicationmain">


                <table className="table datatable dataTable-table myTable table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">Application Id</th>
                            <th scope="col">Student Name</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Email</th>
                            <th scope="col">College Name</th>
                            <th scope="col">Program Name</th>
                            <th scope="col">Application Satus</th>
                            <th scope="col">Chat</th>
                            <th scope="col">Added by</th>
                            <th scope="col">Pathway</th>
                            <th scope="col">COE</th>
                            <th scope="col">Created</th>

                        </tr>

                    </thead>
                    <tbody>

                        { applicationlistdata.length!=0 && applicationlistdata.length > 0 ?

                            applicationlistdata.map((items, i) => {
                                return (
                                    <tr key={i} className={items.newsss}>
                                        <td  > <Link to={`/admin/view_applications_admins?yaja=${items.id}`} >


                                            {items.application_id}</Link></td>
                                        <td>{items.first_name} {items.last_name}</td>
                                        <td>{items.mobile_number}</td>
                                        <td>{items.primary_email}</td>
                                        <td>{items.college_name}</td>
                                        <td>{items.course_name}</td>
                                        <td className="text-center">



                                            <div className="dropdown selectBox ">                                                              <button  type="button" data-bs-toggle="modal" data-bs-target={`#exampleModal${items.application_id}`} >
                                                {items.application_status}
                                            </button>          
                 <div className="modal fade " id={`exampleModal${items.application_id}`}  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                 <div className="modal-dialog modal-dialog-centered modal-xl mdl">
                   <div className="modal-content">
                        <div className="modal-header">
                         <h5 className="modal-title" id="exampleModalLabel">Update Application Status</h5>
                       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                         <div className="modal-body multiselect">
                        
                         {items.status_list.length > 0 ?
                            items.status_list.map((itemss, i) => {
                             return (
                             <label key={i}  className={itemss.checked} style={{
                                "background": "#add5ad",
                                "color": "black"
                            }}>

                              {itemss.status_name} <span></span>{itemss.dates}<span className="namestaff"></span><span className="department">  </span></label>
                                 )
                                })
                          :<Loader></Loader>}  
                        </div>
                          <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                               </div>
                          </div>
                     </div>
                  </div> 
                
                           </div>
                        </td>
                    <td><Link to={`/chat/chat?id=${items.id}`}><button className="btn-primary btnchat">View Chat</button></Link></td>
                    <td>{items.agentfname} {items.agentlname}</td>
                    <td>{items.pathway}</td>
                    <td>{items.coe}</td>
                    <td>
                        
                    {new Date(items.created_at).toLocaleString("en-US", { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })}
                        </td>
                        
                        </tr>
                                )
                            })
                            : <Loader></Loader>}
                    </tbody>
                </table>

            </section>
        </main>
        <Footer />
    </>)
}

export default All_applications;



