

import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
// import ReactDOM from 'react-dom';
// import { useNavigate } from "react-router-dom";
import 'jquery/dist/jquery.min.js';
import Header from "../Admin/Header";


//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
//end
import Sidebar from './Sidebar';
// import Header from './Header';
import Loader from '../loader/Loader';
import Network from '../Network/Network';
function Application_list() {

    const [applicationlistdata, setstateforapplicationlist] = useState([]);
    var added_by = localStorage.getItem('token-info');
    const [loader,setloader]=useState(false);
//   alert(added_by)
    useEffect(() => {
         sendDetailsToServer(); },
         []);

    const sendDetailsToServer = async () => {
        var formdata = new FormData();
        formdata.append('added_by', added_by);
        axios.post(Network.Network.api_url+'get_application_list', formdata)

            .then(function (response) {
                console.log('response',response);
                setstateforapplicationlist(response.data.data);
            })
            setloader(true);
    }
        setTimeout(function(){
        $('.myTable').DataTable();
         } ,2000);
   

    function  view_pdf(id){
        var formdata = new FormData();
        formdata.append('id', id);
        axios.post(Network.Network.api_url+"viewpdf", formdata)
            .then(function (response) {
                console.log('response',response);

                // setstateforapplicationlist(response.data.data);
            })
            setloader(true);
    }
    return (<>
        <Header />
        <Sidebar />
         {loader==false ? <Loader></Loader> :''}
        <main id="main" className="main ">
            <section className="section Applicationmain">
                <table className="table datatable dataTable-table myTable table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">Application Id</th>
                            <th scope="col">Student Name</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Email</th>
                            <th scope="col">College Name</th>
                            <th scope="col">Application Satus</th>
                            <th scope="col">Chat</th>
                            <th scope="col">Created</th>
                            <th scope='col'>View</th>


                        </tr>

                    </thead>

                    <tbody>


                        {applicationlistdata.length > 0 ?

                            applicationlistdata.map((items, i) => {
                                return (
                                    <tr key={i} className={items.newsss}>
                                        <td>  <Link to={`/agent/viewapplication?yaja=${ items.id}`} >{items.application_id}</Link></td>
                                        <td>{items.first_name} {items.last_name}</td>
                                        <td>{items.mobile_number}</td>
                                        <td>{items.primary_email}</td>
                                        <td>{items.college_name}</td>
                                        <td className="text-center">
                                    <div className="dropdown selectBox">                                  <button  type="button" data-bs-toggle="modal" data-bs-target={`#exampleModal${items.application_id}`} >
                                    {items.application_status} <i className="fa fa-eye"></i>
                                    </button>          
                                    <div className="modal fade " id={`exampleModal${items.application_id}`}  tabIndex={i+1}aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered modal-xl mdl">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Update Application Status</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                    <div className="modal-body multiselect">

                                    {
                                    items.status_list.map((itemss, i) => {
                                    return (
                                    <label key={i} className={itemss.checked}  style={{
                                        "background": "#add5ad",
                                        "color": "black"
                                    }}>
                                    {/* <input type="checkbox" className="one" /> */}

                                    {itemss.status_name} <span></span>{itemss.dates}<span className="namestaff"></span><span className="department">  </span></label>
                                    )
                                    })
                                   }

                                    </div>
                                    <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                    </div>
                                    </div>
                                    </div>
                                    </div> 

                                    </div>
                                      </td>
                                        <td><Link to={`/chat/chat?id=${items.id}`}><button className="btn-primary btnchat">View Chat</button></Link></td>
                                        <td>
                                        {new Date(items.created_at).toLocaleString("en-US", { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })}</td>
                                        <td><a href={`${Network.Network.image_url}${ items.application_pdf }`} download>
        <i class="bi bi-file-earmark-arrow-down-fill"></i>Download PDF</a></td>
                                    </tr>
                                )
                            })
                            :  ''}
                    </tbody>
                </table>

            </section>
        </main>
        <Footer />
    </>)
}

export default Application_list;

