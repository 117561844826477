import Header from "../Admin/Header";
import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import Loader from '../loader/Loader';
import {useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Country from "../jsonfolder/Country";
import Network from "../Network/Network";
function New_staff() {
    const notify = (mess) => toast.success(mess);
    const notify1 = (mess) => toast.error(mess);
    const navigate=useNavigate();
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [loader, setloader] = useState(true);
    const [imgData, setImgData] = useState('https://www.w3schools.com/howto/img_avatar.png');

    var aid = localStorage.getItem('token-info');
    // var name = localStorage.getItem('token-name');
    var utypex = localStorage.getItem('user_type');

    useEffect(() => {
        setloader(false);
        
    }, []);
   
    const handleFileChange = (event) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setImgData(reader.result);
        });
        reader.readAsDataURL(event.target.files[0]);
    }


    const onSubmit = (data) => {
        console.log('data',data);
        setloader(true);
        let formdata = new FormData();
        formdata.append('dashtype', utypex);
        formdata.append('id', aid);
        formdata.append('country_name', data.country_name);
        formdata.append('countrycode', data.countrycode);
        formdata.append('department', data.department);
        formdata.append('designation', data.designation);
        formdata.append('email', data.email);
        formdata.append('first_name', data.first_name);
        formdata.append('gender', data.gender);
        formdata.append('last_name', data.last_name);
        formdata.append('permission', data.permission);
        formdata.append('phonenumber', data.phonenumber);
        // formdata.append('state', data.state);
        // formdata.append('city', data.city);
        formdata.append('iDproofdocument', data.iDproofdocument[0]);
        formdata.append('ProfilePhoto', data.ProfilePhoto[0]);
        axios.post(Network.Network.api_url+"addstaff", formdata)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status == 'success') {
                    notify(response.data.message);
                    setloader(false);
                    navigate('/admin/mystaff');

                } else {
                    notify1(response.data.message);
                    setloader(false);
                }

            })


    };
    return (<>
        <Header />
        <Sidebar />

        {loader == true ? <Loader></Loader> : ''}
        <link href="../assets/css/new.css" rel="stylesheet" />

        <main id="main" className="main">
            <p></p>
            <div>
                <ToastContainer />
            </div>
            <section className="section collegeadd">
                <form id="stafffrom" onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="row">

                        <div className="form-group col-md-6">
                            <img
                                style={{
                                    width: '16%',
                                    'height': '113px',
                                    'border-radius': ' 72px',
                                    'object-fit': 'cover',
                                    'float': 'left'
                                }} className="playerProfilePic_home_tile" src={imgData} />
                        </div>
                        <div className="form-group col-md-6">

                            <label >Profile Photo: {errors.ProfilePhoto && <p className="error_message">Profile Photo is required.</p>}</label>

                            <input type="file" id="profile-photo"  {...register("ProfilePhoto", { required: true, onChange: handleFileChange })} />

                        </div>
                        <div className="form-group col-md-6">
                            <label >Gender: {errors.gender && <p className="error_message">Gender name is required.</p>}</label>
                            <select id="gender" {...register("gender", { required: true })}>
                                <option value="">--Select--</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label>First Name:  {errors.first_name && <p>First name is required.</p>}</label>
                            <input type="text"      {...register("first_name", { required: true, maxLength: 20 })} />

                        </div>
                        <div className="form-group col-md-6">
                            <label>Last Name: {errors.last_name && <p className="error_message">Last name is required.</p>} </label>
                            <input type="text" id="last-name"  {...register("last_name", { required: true })} />
                        </div>
                        <div className="form-group col-md-6">
                            <label >Email: {errors.email && <p className="error_message">email is required.</p>}</label>
                            <input type="email" id="email"   {...register("email", { required: true })} />
                        </div>
                        <div className="form-group col-md-6">
                            <label >Country Code: {errors.countrycode && <p className="error_message">country-code is required.</p>}</label>
                            <input type="tel" id="country-code"  {...register("countrycode", { required: true })} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Phone Number: {errors.phonenumber && <p className="error_message">phone-number is required.</p>}</label>
                            <input type="tel" id="phone-number"   {...register("phonenumber", { required: true })} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>Country Name: {errors.country_name && <  p className="error_message">Country Name is required.</p>}</label>
                           
                            <select  {...register("country_name", { required: true })}>
                                <option value=''>--select--</option>
                            {
                           Country.country.map((item, index) => (
                               <option key={index} value={item.name}>{item.name}</option>

                           ))}
                            </select>
                        </div>
                        {/* <div className="form-group col-md-6">
                            <label>State: {errors.state && <p className="error_message">State is required.</p>}</label>
                            <input type="text" id="state-name"   {...register("state", { required: true })} />
                        </div> */}
                        {/* <div className="form-group col-md-6">
                            <label>City: {errors.city && <p className="error_message">City is required.</p>}</label>
                            <input type="text" id="city-name"   {...register("city", { required: true })} />
                        </div> */}
                        <div className="form-group col-md-6">
                            <label>Department: {errors.department && <p className="error_message">department is required.</p>}</label>
                            <select id="department" name="department"  {...register("department", { required: true })}   >
                                <option value="">--Select--</option>
                                <option value="sales">Sales</option>
                                <option value="marketing">Marketing</option>
                                <option value="hr">HR</option>
                                <option value="it">IT</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label >Designation: {errors.designation && <p className="error_message">designation is required.</p>}</label>
                            <input type="text" id="designation" {...register("designation", { required: true })} />
                        </div>
                        <div className="form-group col-md-6">
                            <label>ID Proof Document:  {errors.iDproofdocument && <p className="error_message">IDproof document is required.</p>}</label>
                            <input type="file" id="id-documents"  {...register("iDproofdocument", { required: true })} />
                        </div>
                        <div className="form-group col-md-6">
                            <label >Permission:{errors.permission && <p className="error_message">permission is required.</p>}</label>
                            <select   {...register("permission", { required: true })} >
                           
                                 <option value="">--Select--</option>
                                <option value="view">View Only <input  type="checkbox"/></option>
                                <option value="edit">Edit  <input  type="checkbox"/></option>
                                <option value="delete">Delete  <input  type="checkbox"/></option>
                                <option value="addagent">Add Agent  <input  type="checkbox"/></option> *

                            </select>
              {/* <div class="dropdown">
                     <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                     Permission
                     </a>

                     <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <label for="View Only">View Only</label>
                        <input type="checkbox" id="scales" name="scales[]" checked/>
                    <label for="Edit">Edit</label>
                     <input type="checkbox" id="scales" name="scales[]" checked/>
                 <label for="Delete">Delete</label>
                 <input type="checkbox" id="scales" name="scales[]" checked/>
              <label for="scales">Scales</label> 
                  </ul>
                                    </div> */}
                        </div>
                        
                        <div className="form-group">
                            <label ></label>
                            <button type="submit">Submit</button>
                        </div>
                    </div>
                </form>



            </section>

        </main>

        {/* <Forgotpasswordlogin /> */}
        <Footer />
    </>)



}


export default New_staff;