

import Footer from './Footer';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";
import Loader from '../loader/Loader';





function Dashboard() {
    const [loader,setloader]=useState(true);
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: "",
        password: "",
    })
    const [viewpassword, setviewpassword] = useState(false);

    const [error, setError] = useState([]);
    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    useEffect(() => {
        setloader(false);
      },[]);
  
    const logout = () => {
        localStorage.removeItem('token-info');
        navigate('/adminlogin')
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();
        let errors = [];
        const isEmpty = Object.values(state).every(x => x === null || x === '');
        if (isEmpty == false) {
            // sendDetailsToServer()
            // if (state.password === state.confirmPassword) {
            //     
            // } else {
            //     errors.push('password mismatch');
            // }
        }
        setError(errors);
    }

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setviewpassword(!viewpassword);
    };

    return (<>
    <Header/>
        <Sidebar />
    
        <link rel="stylesheet" href="/../adminasset/css/style.css" />
        <link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" relation="stylesheet" type="text/css" />

        {loader ? <Loader></Loader>:''}
        <main id="main" className="main">

            <div className="pagetitle">
                <h1>Dashboard</h1>
                <nav>
                    <ol className="breadcrumb">
                        {/* <li className="breadcrumb-item"><a href="index.html">Home</a></li> */}
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>

                     
                </nav>
            </div>

            <section className="section dashboard">
                <div className="row">

                    
                    <div className="col-lg-8">
                        <div className="row">

                            
                            <div className="col-xxl-4 col-md-6">
                                <div className="card info-card sales-card">
{/* 
                                    <div className="filter">
                                        <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                            <li className="dropdown-header text-start">
                                                <h6>Filter</h6>
                                            </li>

                                            <li><a className="dropdown-item" href="#">Today</a></li>
                                            <li><a className="dropdown-item" href="#">This Month</a></li>
                                            <li><a className="dropdown-item" href="#">This Year</a></li>
                                        </ul>
                                    </div> */}

                                    <div className="card-body">
                                        <h5 className="card-title">All Application<span></span></h5>

                                        <div className="d-flex align-items-center">
                                         
                                            <div className="">
                                                <h6>34</h6>
                                                {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}

                                            </div>
                                               {/* <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-cart"></i>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-12">

                                <div className="card info-card customers-card">

                                    {/* <div className="filter">
                                        <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                            <li className="dropdown-header text-start">
                                                <h6>Filter</h6>
                                            </li>

                                            <li><a className="dropdown-item" href="#">Today</a></li>
                                            <li><a className="dropdown-item" href="#">This Month</a></li>
                                            <li><a className="dropdown-item" href="#">This Year</a></li>
                                        </ul>
                                    </div> */}

                                    <div className="card-body">
                                        <h5 className="card-title">All Agent <span></span></h5>

                                        <div className="d-flex align-items-center">
                                         
                                            <div className="">
                                                <h6>45</h6>
                                                {/* <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span> */}

                                            </div>
                                               {/* <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-people"></i>
                                            </div> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="col-xxl-4 col-xl-12">

                                <div className="card info-card customers-card">

                                    {/* <div className="filter">
                                        <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                            <li className="dropdown-header text-start">
                                                <h6>Filter</h6>
                                            </li>

                                            <li><a className="dropdown-item" href="#">Today</a></li>
                                            <li><a className="dropdown-item" href="#">This Month</a></li>
                                            <li><a className="dropdown-item" href="#">This Year</a></li>
                                        </ul>
                                    </div> */}

                                    <div className="card-body">
                                        <h5 className="card-title">Total Application <span></span></h5>

                                        <div className="d-flex align-items-center">
                                          
                                            <div className="">
                                                <h6>89</h6>
                                                {/* <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span> */}

                                            </div>
                                              {/* <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <i className="bi bi-people"></i>
                                            </div> */}
                                        </div>

                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-12">
                                <div className="card">

                                    <div className="filter">
                                        <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                            <li className="dropdown-header text-start">
                                                <h6>Filter</h6>
                                            </li>

                                            <li><a className="dropdown-item" href="#">Today</a></li>
                                            <li><a className="dropdown-item" href="#">This Month</a></li>
                                            <li><a className="dropdown-item" href="#">This Year</a></li>
                                        </ul>
                                    </div>

                                    <div className="card-body">
                                        <h5 className="card-title">Reports <span>/Today</span></h5>

                                        
                                        <div id="reportsChart"></div>

                                       
                                       

                                    </div>

                                </div>
                            </div> */}
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">

                                    {/* <div className="filter">
                                        <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                            <li className="dropdown-header text-start">
                                                <h6>Filter</h6>
                                            </li>

                                            <li><a className="dropdown-item" href="#">Today</a></li>
                                            <li><a className="dropdown-item" href="#">This Month</a></li>
                                            <li><a className="dropdown-item" href="#">This Year</a></li>
                                        </ul>
                                    </div> */}

                                    <div className="card-body">
                                        <h5 className="card-title">Agent -work in progress<span></span></h5>

                                        {/* <table className="table table-borderless datatable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Customer</th>
                                                    <th scope="col">Program</th>
                                                    
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row"><a href="#">#2457</a></th>
                                                    <td>Brandon Jacob</td>
                                                    <td><a href="#" className="text-primary">At praesentium minu</a></td>
                                                 
                                                    <td><span className="">Approved</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><a href="#">#2147</a></th>
                                                    <td>Bridie Kessler</td>
                                                    <td><a href="#" className="text-primary">Blanditiis dolor omnis similique</a></td>
                                                   
                                                    <td><span className="">Pending</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><a href="#">#2049</a></th>
                                                    <td>Ashleigh Langosh</td>
                                                    <td><a href="#" className="text-primary">At recusandae consectetur</a></td>
                                                   
                                                    <td><span className="">Approved</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><a href="#">#2644</a></th>
                                                    <td>Angus Grady</td>
                                                    <td><a href="#" className="text-primar">Ut voluptatem id earum et</a></td>
                                                  
                                                    <td><span className="">Rejected</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><a href="#">#2644</a></th>
                                                    <td>Raheem Lehner</td>
                                                    <td><a href="#" className="text-primary">Sunt similique distinctio</a></td>
                                                
                                                    <td><span className="">Approved</span></td>
                                                </tr>
                                            </tbody>
                                        </table> */}

                                    </div>

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card top-selling overflow-auto">

                                    {/* <div className="filter">
                                        <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                            <li className="dropdown-header text-start">
                                                <h6>Filter</h6>
                                            </li>

                                            <li><a className="dropdown-item" href="#">Today</a></li>
                                            <li><a className="dropdown-item" href="#">This Month</a></li>
                                            <li><a className="dropdown-item" href="#">This Year</a></li>
                                        </ul>
                                    </div> */}

                                    <div className="card-body ">
                                        <h5 className="card-title">College-- work in progress<span></span></h5>
{/* 
                                        <table className="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Sold</th>
                                                    <th scope="col">Revenue</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row"><a href="#"><img src="assets/img/product-1.jpg" alt=""/></a>123</th>
                                                    <td><a href="#" className="text-primary">Ut inventore ipsa voluptas nulla</a></td>
                                                    <td>$64</td>
                                                    <td className="fw-bold">124</td>
                                                    <td>$5,828</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><a href="#"><img src="assets/img/product-2.jpg" alt=""/></a>124</th>
                                                    <td><a href="#" className="text-primary">Exercitationem similique doloremque</a></td>
                                                    <td>$46</td>
                                                    <td className="fw-bold">98</td>
                                                    <td>$4,508</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><a href="#"><img src="assets/img/product-3.jpg" alt=""/></a>125</th>
                                                    <td><a href="#" className="text-primary ">Doloribus nisi exercitationem</a></td>
                                                    <td>$59</td>
                                                    <td className="fw-bold">74</td>
                                                    <td>$4,366</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><a href="#"><img src="assets/img/product-4.jpg" alt=""/></a>126</th>
                                                    <td><a href="#" className="text-primary">Officiis quaerat sint rerum error</a></td>
                                                    <td>$32</td>
                                                    <td className="fw-bold">63</td>
                                                    <td>$2,016</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><a href="#"><img src="assets/img/product-5.jpg" alt=""/></a>127</th>
                                                    <td><a href="#" className="text-primary">Sit unde debitis delectus repellendus</a></td>
                                                    <td>$79</td>
                                                    <td className="fw-bold">41</td>
                                                    <td>$3,239</td>
                                                </tr>
                                            </tbody>
                                        </table> */}

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4">

                        
                        <div className="card">
                            {/* <div className="filter">
                                <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                    <li className="dropdown-header text-start">
                                        <h6>Filter</h6>
                                    </li>

                                    <li><a className="dropdown-item" href="#">Today</a></li>
                                    <li><a className="dropdown-item" href="#">This Month</a></li>
                                    <li><a className="dropdown-item" href="#">This Year</a></li>
                                </ul>
                            </div> */}

                            <div className="card-body">
                                <h5 className="card-title">Recent Notification  -work in progress<span></span></h5>

                                {/* <div className="activity">

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">32 min</div>
                                        <i class='bi bi-circle-fill activity-badge text-success align-self-start'></i>
                                        <div className="activity-content">
                                            Quia quae rerum <a href="#" className="fw-bold text-dark">explicabo officiis</a> beatae
                                        </div>
                                    </div>

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">56 min</div>
                                        <i class='bi bi-circle-fill activity-badge text-danger align-self-start'></i>
                                        <div className="activity-content">
                                            Voluptatem blanditiis blanditiis eveniet
                                        </div>
                                    </div>

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">2 hrs</div>
                                        <i class='bi bi-circle-fill activity-badge text-primary align-self-start'></i>
                                        <div className="activity-content">
                                            Voluptates corrupti molestias voluptatem
                                        </div>
                                    </div>

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">1 day</div>
                                        <i class='bi bi-circle-fill activity-badge text-info align-self-start'></i>
                                        <div className="activity-content">
                                            Tempore autem saepe <a href="#" className="fw-bold text-dark">occaecati voluptatem</a> tempore
                                        </div>
                                    </div>

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">2 days</div>
                                        <i class='bi bi-circle-fill activity-badge text-warning align-self-start'></i>
                                        <div className="activity-content">
                                            Est sit eum reiciendis exercitationem
                                        </div>
                                    </div>

                                    <div className="activity-item d-flex">
                                        <div className="activite-label">4 weeks</div>
                                        <i class='bi bi-circle-fill activity-badge text-muted align-self-start'></i>
                                        <div className="activity-content">
                                            Dicta dolorem harum nulla eius. Ut quidem quidem sit quas
                                        </div>
                                    </div>

                                </div> */}

                            </div>
                        </div>

                
                        <div className="card">
                            {/* <div className="filter">
                                <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                    <li className="dropdown-header text-start">
                                        <h6>Filter</h6>
                                    </li>

                                    <li><a className="dropdown-item" href="#">Today</a></li>
                                    <li><a className="dropdown-item" href="#">This Month</a></li>
                                    <li><a className="dropdown-item" href="#">This Year</a></li>
                                </ul>
                            </div> */}

                            {/* <div className="card-body pb-0">
                                <h5 className="card-title">Budget Report <span>| This Month</span></h5>

                                <div id="budgetChart"  style={{ minHeight: "400px" }} className="echart"></div>
                            </div> */}
                        </div>

                       
                        {/* <div className="card">
                            <div className="filter">
                                <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                    <li className="dropdown-header text-start">
                                        <h6>Filter</h6>
                                    </li>

                                    <li><a className="dropdown-item" href="#">Today</a></li>
                                    <li><a className="dropdown-item" href="#">This Month</a></li>
                                    <li><a className="dropdown-item" href="#">This Year</a></li>
                                </ul>
                            </div>

                            <div className="card-body ">
                                <h5 className="card-title">Website Traffic <span>| Today</span></h5>

                                <div id="trafficChart"  style={{ minHeight: "400px" }} className="echart"></div>

                            </div>
                        </div> */}

                        
                        <div className="card">
                         

                            <div className="card-body ">
                                <h5 className="card-title">News &amp; Updates  work in progress<span></span></h5>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </main>
        {/* <Forgotpasswordlogin /> */}
        <Footer />
    </>)



}


export default Dashboard;