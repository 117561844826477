
import Footer from './Footer';
import React, { useEffect, useState } from "react";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";
import Loader from '../loader/Loader';
import axios from 'axios';
import Network from '../Network/Network';


 function Myprofile() {
    const id = localStorage.getItem('token-info');
    const [state, setstate] = useState({});
    const [country_name_arr, setcountry] = useState({});
    const [file1, setfile1] = useState({});
    const [file2, setfile2] = useState({});

 
    const [loader, setloading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage1, setSelectedImage1] = useState(null);
  


    useEffect(() => {
        fetch_collegeDetails();
        country();
      },[]);

      const fetch_collegeDetails = async () => {
        var formdata = new FormData();
       formdata.append('id',id);
        axios.post(Network.Network.api_url+"fetch_collegeDetails", formdata)
        .then(function (response) {

            setstate(response.data.data[0]);
            setloading(true);
        }) 
      };

      const country = async () => {
        axios.post(Network.Network.api_url+"country_list")
        .then(function (response) {

            setcountry(response.data.data);
            setloading(true);
        }) 
      };
  

      const handleImageChange = (event) => {
          // for logo
        setSelectedImage(URL.createObjectURL(event.target.files[0]));
        setfile1(
            event.target.files[0]
             );
      };
      const handleImageChange1 = (event) => {

        //college photo
        setSelectedImage1(URL.createObjectURL(event.target.files[0]));

        setfile2(
            event.target.files[0]
             );
      };
      
     const handlechangeform=(event)=>{

        setstate({
             ...state,
              [event.target.name]:
               event.target.value });       
     };
  return (
       <><Header /><Sidebar /><div>
          <main id="main" className="main">
               { loader ? '' :  <Loader></Loader>}
              <div className="pagetitle">
                  <h1>Profile</h1>
              </div>
              <section className="section profile">
                  <div className="row">
                      <div className="col-xl-4">

                          <div className="card">
                              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                              {state ? 
                                  <><img src={`${Network.Network.image_url}${state.college_logo}`} alt="Profile" className="rounded-circle" /><h2>{state.college_name}</h2></>
                             :"" }
                              </div>
                          </div>

                      </div>

                      <div className="col-xl-8">

                          <div className="card">
                              <div className="card-body pt-3">
                                  <ul className="nav nav-tabs nav-tabs-bordered">

                                      <li className="nav-item">
                                          <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                                      </li>

                                      {/* <li className="nav-item">
                                          <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                                      </li>  */}
                                  </ul>
                                  <div className="tab-content pt-2">

                                      <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                         
                                           {state ? 
                                          <><h5 className="card-title">Profile Details</h5><div className="row">
                                                  <div className="col-lg-3 col-md-4 label">CRICOS</div>
                                                  <div className="col-lg-9 col-md-8">{state.college_unique_id}</div>
                                              </div><div className="row">
                                                      <div className="col-lg-3 col-md-4 label">College Logo</div>
                                                      <div className="col-lg-9 col-md-8">{state ? 
                                  <><img src={`${Network.Network.image_url}${state.college_logo}`} alt="Profile" className="rounded-circle" /></>
                             :"" }</div>
                                                  </div><div className="row">
                                                      <div className="col-lg-3 col-md-4 label ">College Name</div>
                                                      <div className="col-lg-9 col-md-8">{state.college_name}</div>
                                                  </div><div className="row">
                                                      <div className="col-lg-3 col-md-4 label">Email</div>
                                                      <div className="col-lg-9 col-md-8">{state.college_email}</div>
                                                  </div><div className="row">
                                                      <div className="col-lg-3 col-md-4 label">Contact Number</div>
                                                      <div className="col-lg-9 col-md-8">{state.college_phone}</div>
                                                  </div><div className="row">
                                                      <div className="col-lg-3 col-md-4 label">Address</div>
                                                      <div className="col-lg-9 col-md-8">{state.college_address}</div>
                                                  </div></>

                                     
                                          : <Loader></Loader> }
                                      </div>

                                      <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                                        {state ? 
                                          <form>

                                          <div className="row mb-3">
                                                  <label for="fullName" className="col-md-4 col-lg-3 col-form-label">CRICOS</label>
                                                  <div className="col-md-8 col-lg-9">
                                                      <input name="college_unique_id" type="text" className="form-control" id="fullName" value={state.college_unique_id} onChange={handlechangeform}/>
                                                  </div>
                                              </div>
                                              <div className="row mb-3">
                                                  <label for="profileImage" className="col-md-4 col-lg-3 col-form-label">College Logo </label>
                                                  <div className="col-md-8 col-lg-9">
                                                  <input type="file" onChange={handleImageChange}  name="logo_file" />
                                                {selectedImage && <img src={selectedImage} alt="Selected" />}
                                        
                                                  </div>
                                              </div>
                                              <div className="row mb-3">
                                                  <label for="profileImage" className="col-md-4 col-lg-3 col-form-label">College Photo</label>
                                                  <div className="col-md-8 col-lg-9">
                                                  <input type="file" onChange={handleImageChange1} name="college_photo"/>
                                                {selectedImage1 && <img src={selectedImage1} alt="Selected" />}
                                                     
                                                  </div>
                                              </div>

                                              <div className="row mb-3">
                                                  <label for="fullName" className="col-md-4 col-lg-3 col-form-label">College Name</label>
                                                  <div className="col-md-8 col-lg-9">
                                                      <input name="college_name" type="text" className="form-control" id="college_name" value={state.college_name} onChange={handlechangeform}/>
                                                  </div>
                                              </div>
                                           

                                              <div className="row mb-3">
                                                  <label for="Job" className="col-md-4 col-lg-3 col-form-label">Email</label>
                                                  <div className="col-md-8 col-lg-9">
                                                      <input name="college_email" type="email" className="form-control" id="college_email" value={state.college_email} onChange={handlechangeform} disabled/>
                                                  </div>
                                              </div>

                                              <div className="row mb-3">
                                                  <label for="Country" className="col-md-4 col-lg-3 col-form-label">Number</label>
                                                  <div className="col-md-8 col-lg-9">
                                                      <input name="college_phone" type="text" className="form-control" id="college_phone" value={state.college_phone} onChange={handlechangeform}/>
                                                  </div>
                                              </div>

                                              <div className="row mb-3">
                                                  <label for="Address" className="col-md-4 col-lg-3 col-form-label">Address</label>
                                                  <div className="col-md-8 col-lg-9">
                                                      <input name="college_address" type="text" className="form-control" id="college_address" value={state.college_address} onChange={handlechangeform}/>
                                                      
                                                  </div>
                                              </div>

                                              <div className="row mb-3">
                                                  <label for="country_id" className="col-md-4 col-lg-3 col-form-label">Country</label>
                                                  <div className="col-md-8 col-lg-9">
                                                      <select className="form-control" name="country_id"
                                                      value={state.country_id} onChange={handlechangeform}>
                                                          <option value="" selected disabled>--select country--</option>
                                                      { country_name_arr.length > 0   ?
                                                     
                                                     country_name_arr.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.country_name}</option>
                                                    ))
                                                   : '---'}
                                                      </select>
                                                      
                                                  </div>
                                              </div>
                                            
                                              <div className="text-center">
                                                  <button type="submit" className="btn btn-primary">Save Changes</button>
                                              </div>
                                          </form>
                                        : <Loader></Loader>}
                                      </div>

                                     

                                    

                                  </div>

                              </div>
                          </div>

                      </div>
                  </div>
              </section>

          </main>
      </div></>
  )
}

export default  Myprofile;












