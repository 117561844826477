import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router';

export default function Logout() {
    const navigate=useNavigate();


    useEffect(() => {
        logut();
   }, []);

   const logut=()=>{
    const user_type = localStorage.getItem('user_type');
    // alert(user_type)
    localStorage.removeItem('token-info');
    if(user_type==='Agent')
    {
      
        navigate('/agent/login')
    }
    else if(user_type==='Admin')
    {
        navigate('/admin/login')

    }
    else 
    {
        navigate('/college/login')
    }
   }
  
   
   
      
}
