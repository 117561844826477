
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import $ from 'jquery';
// import { useNavigate } from "react-router-dom";  
  
function Sidebar() {
    return (<>
    
  
  <aside id="sidebar" className="sidebar">

    <ul className="sidebar-nav" id="sidebar-nav">

      <li className="nav-item">
            <a className="nav-link " href="/agent/Dashboard">
          <i className="bi bi-grid"></i>
          <span>Dashboard</span>
        </a>
      </li>
         
          <li className="nav-item">
            <a className="nav-link collapsed" data-bs-target="#Program-nav" data-bs-toggle="collapse" href="#">
              <i className="bi bi-menu-button-wide"></i><span>Application</span><i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul id="Program-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">

              <li>


                <Link to="/agent/addapplication"><i className="bi bi-circle"></i><span>Add Application</span></Link>
                <Link to="/agent/applicationlist"><i className="bi bi-circle"></i><span>Application List</span></Link>
            
              </li>
              
            </ul>
          </li>
    </ul>
<a className="Logout" href="/logout/logoutuser">Logout <img src="../assets/img/logout.png" alt="icon"/> </a>
  </aside>

    </>)
}


export default Sidebar;