import { Link } from "react-router-dom";
import axios from "axios";
import React, {useState } from "react";
import { useNavigate } from "react-router-dom";
import Network from "../Network/Network";

function AgentLogin() {
 console.log("token-info", localStorage.getItem('token-info'));
    console.log("token-name", localStorage.getItem('token-name'));
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: "",
        password: "",
    })
    const [viewpassword, setviewpassword] = useState(false);
    const [error, setError] = useState([]);
    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }

    const sendDetailsToServer = async () => {
        setError([]);
        // const hashedPassword = md5(state.password)
        let formdata = new FormData();
        formdata.append('email', state.email);
        formdata.append('password', state.password);
        // formdata.append('hashpassword')
        
        axios.post(Network.Network.api_url+"agent_login", formdata)
            .then(function (response) {

                console.log('response', response.data);
                if (response.data.status == 'success') {
                    localStorage.setItem('token-info', response.data.data.id);
                    localStorage.setItem('token-name', response.data.data.first_name);
                    localStorage.setItem('token-name1', response.data.data.last_name);
                    localStorage.setItem('photo', response.data.data.user_photo);
                    localStorage.setItem('user_type', response.data.data.user_type);
                    alert(response.data.message);

                    navigate('/agent/Dashboard')

                } else {
                    // console.log("not correct")
                    alert(response.data.message);

                }
            })
        // .catch(function (error) {
        //     console.log('error', error);
        // });
    }

    const logout = () => {
        localStorage.removeItem('token-info');
        navigate('/adminlogin')
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();
        let errors = [];
        const isEmpty = Object.values(state).every(x => x === null || x === '');
        if (isEmpty == false) {
            sendDetailsToServer()
            // if (state.password === state.confirmPassword) {
            //     
            // } else {
            //     errors.push('password mismatch');
            // }
        }
        setError(errors);
    }

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setviewpassword(!viewpassword);
    };

    return (<>
        <link rel="stylesheet" href="/../adminasset/css/style.css" />
        <link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" relation="stylesheet" type="text/css" />

        <main className="mainlogin">
            <div className="container">

                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-md-9 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <a href="#" className="logo d-flex align-items-center w-auto">
                                        <img src="assets/img/logo.png" alt="" />
                                        <span className="d-none d-lg-block">Enrolguru</span>
                                    </a>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">
                                    <div className="row">
                                    <div className="col-sm-6">
                                    <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Sign in</h5>
                                            <p className=" small">Enter your username & password to login</p>
                                        </div>

                                        <form className="row g-3 needs-validation" onSubmit={handleSubmitClick} novalidate>

                                            <div className="col-12">
                                                <div className="input-group has-validation">

                                                    <input type="text" name="email" placeholder="Email ID" className="form-control" id="yourUsername" required value={state.email} onChange={handleChange} />
                                                    <div className="invalid-feedback">Please enter your username.</div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <input type="password" name="password" placeholder="Password" className="form-control" id="yourPassword" value={state.password} onChange={handleChange} required />
                                                <div className="invalid-feedback">Please enter your password!</div>
                                            </div>

                                            {/* <div className="col-12">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                                    <label className="form-check-label" for="rememberMe">Remember me</label>
                                                </div>
                                            </div> */}
                                            <div className="col-12">
                                                <button className="btn btn-primary w-100" type="submit">Login</button>
                                            </div>
                                            {/* <div className="col-12">
                                                <p className="small mb-0">Don't have account? <a href="pages-register.html">Create an account</a></p>
                                            </div> */}
                                        </form>
                                    </div>
                                    <div className="col-sm-6">
                                    <Link to="/"><img style={{width: "100%"}} src={Network.Network.image_url+`enrollog.png`}/>
                                    </Link>
                                    </div>
                                    </div>

                                        

                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
        {/* <Forgotpasswordlogin /> */}
    </>)



}


export default AgentLogin;


