import Footer from "./Footer";
import axios from "axios";
import React, { useEffect, useState } from "react";
// import ReactDOM from 'react-dom';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "../Admin/Header";
import Loader from "../loader/Loader";
import Master from "./Master";
import Dimploma from "./Dimploma";
import Graduate from "./Graduate";
import Network from "../Network/Network";
import { useRef } from 'react';
import '../Newcss/stepform.css';

function Addapplication() {
    const inputRef = useRef('');


    const handleClick = () => {
        inputRef.current.click();
    };
    const userid = localStorage.getItem('token-info');
    const [preview, setpreview] = useState(true);
    const [previewupload, setpreviewupload] = useState(false);
    const [submit, setsubmit] = useState(true);
    const [preview_section, setpreview_section] = useState('none');
    const [first_section, setfirst_section] = useState('block');
    const [addClass, setAddClass] = useState('');
    const [disbaledButton, setDisabledButton] = useState(false);

    const [pdftype, settyspe_pdf] = useState('pdf');
    function themainfunction(id) {


        settyspe_pdf('pdf');

        setpreview_section('block');
        setpreview(true);
        setsubmit(true);
        sendDetailsToServer();

        // setfirst_section('none');
    }
    const [inputFields, setInputFields] = useState([
        {
            label: "",
            document: "",
            docdate: "",
        },
    ]);
    const [isDuplicateFamily, setIsDuplicateFamily] = useState(false)
    const addInputField = () => {
        setInputFields([
            ...inputFields,
            {
                label: "",
                document: "",
                docdate: "",
            },
        ]);
    };
    // const removeInputFields = (index)=>{
    //     console.log('indexxxxxx',index)
    // const rows = [...inputFields];
    // rows.splice(index, 1);
    // setInputFields(rows);
    // }
    const removeInputFields = (index) => {
        setInputFields((inputFields) => inputFields.filter((_, i) => i !== index));
    };

    const handleChange_fee = (index, evnt) => {
        const { name, value } = evnt.target;
        console.log("name", name);
        console.log("value", value);
        if (name === "source_doc") {
            const list = [...inputFields];
            list[index][name] = evnt.target.files[0];
            setInputFields(list);
        }
        else if (name == 'label') {

            inputFields.map((e) => {
                if (e.label == value) {
                    alert("label is already exist");
                    return false;
                }
                // else {
                //   const list = [...inputFields];
                //   list[index][name] = value;
                //   setInputFields(list);
                // }
            })
            const list = [...inputFields];
            list[index][name] = value;
            setInputFields(list);
        }
        else {
            const list = [...inputFields];
            list[index][name] = value;
            setInputFields(list);
        }
    };

    const [program_intake_list, setstateforprogramintakelist] = useState([]);
    const [pathway_intake_list, setstateforpathwayprogramintakelist] = useState([]);
    const [anotherForProgramIntakeList, setStateAnotherForProgramIntakeList] = useState([]);
    const [addCourse, setAddCourse] = useState(false);
    const [courselist, setstateforcourselist] = useState([]);
    const [stateforpathwaycourselist, setstateforpathwaycourselist] = useState([]);
    const [pathwayCourseList, setPathwayCourseList] = useState([]);

    //   console.log('courselist',courselist)
    const [state, setState] = useState({
        passport_and_visa: "",
        program_id: "",
        multiple_program_id: '',
        program_intake: "",
        multiple_program_intake: '',
        added_by: "",
        user_type: "Agent",
        program_level: "",
        college_id: "",
        gender: "",
        first_name: "",
        last_name: "",
        marital_status: "",
        student_country: "",
        student_state: "",
        student_city: "",
        address: "",
        area: "",
        postal_zipcode: "",
        passport_number: "",
        scmqualifications: "",
        father_occupation: "",
        family_income: "",
        test_score: "",
        exam_date: "",
        overall_score: "",
        listing_score: "",
        reading_score: "",
        writing_score: "",
        speaking_score: "",
        visa_permit: "",
        primary_email: "",
        mobile_number: "",
        other_doc: "",
        upload_academics: "",
        upload_passport: "",
        upload_ielts: "",
        shore: "",
        shore_country: "",
        visadetails: "",
        visaexpirydate: "",
        policydetails: "",
        policyexpirydate: "",
        courseand_intakes: "",
        pathway: "",
        coe: "",
        tenth_doc: "",
        twelve_doc: "",
        first_sem: "",
        second_sem: "",
        third_sem: "",
        fourth_sem: "",
        fiveth_sem: "",
        sixth_sem: "",
        seventh_sem: "",
        eighth_sem: "",
        visa_number: "",
        country_code: "",
        signature: "",
        campus_location: "",
        visa_type: "",
        student_health_cover: "",
        student_health_cover_status: "",
        your_provider: "",
        membership_number: "",
        membership_file: "",
        apply_your_behalf_status: "",
        student_location: "",
        home_phone: "",
        work_phone: "",
        mobile_phone: "",
        email_address: "",
        address_international: "",
        int_address_country: "",
        postal_address: "",
        emergency_name: "",
        relationship: "",
        emergency_contact_number: "",
        emergency_address: "",
        born_country: "",
        speak_language: "",
        speak_language_specify_status: "",
        speak_language_specify: "",
        english_language_proficiency: "",
        english_language_proficiency_status: "",
        disability: "",
        disability_status: "",
        disability_area: "",
        disability_language_status: "",
        disability_language_status_about: "",
        disability_health_status_about: "",
        disability_health_status: "",
        qualifications: "",
        prior_Learning_status: "",
        rpl_apply_status: "",
        rpl_application_status: "",
        credit_transfer_apply_status: "",
        credit_transfer_application_satus: "",
        payment_option: "",
        study_period: "",
        payment_plan: "",
        qualification_details: "",
        study_period_notes: "",
        intakes_months_study: "",
        reason_of_study: "",
        employment_status: "",
        disability_health_status_other: "",
        check_tuition_fee: "",
        dob: "",
        highschoolcertificate: "",
        document_attached: "",
        qualificationfourthabove: "",
        AETA: "",
        itelsorEquivalent: "",
        admission: "",
        admission1: "",
        admission2: "",
        admission3: "",
        admission4: "",
        admission5: "",
        admission6: "",
        credit_transfer_studies_completed: "",
        first_lang_english: "",
        other_medical_condition: "",
        typeOfPassport: "",
        passportname: "",
        passportFamilname: "",
        typeOfApplication: "",
        australianindigenouspersonstatus: "",
        yearArrivalAuustralia: "",
        countryOfResidence: "",
        educationInAustralia: "",
        usi: "",
        courseAustraliaCredit: "",
        academicPurposesProgram: "",
        EAPprogram: "",
        startingDateEAPprogam: "",
        EAPprogramWeeks: "",
        disabilitiesList: "",
        typeOfCover: "",
        periodInsurance: "",
        englishUploadDetails: "",
        entranceRequirement: "",
        documentPreviousName: "",
        profileApplicantForm: "",
        yearOfCompletedEducation: "",
        passport_expiry_date: "",
        mother_occupation: "",
        expemtion: false,
        pathway_college: "",
        pathway_course: "",
        tenth_board_of_education: "",
        tenth_year_of_pass: "",
        tenth_country: "",
        tenth_4_core_subject: "",
        twelve_board_of_education: "",
        twelve_year_of_pass: "",
        twelve_country: "",
        twelve_4_core_subject: ""
    });
    useEffect(() => {
        if (state.college_id == 42) {
            setAddClass("della");
        }
        else if (state.college_id == 39) {
            setAddClass("scm");
        }
        else if (state.college_id == 38) {
            setAddClass("accresited");
        }
        else if (state.college_id == 60) {
            setAddClass("centuron");
        }
        else if (state.college_id == 41) {
            setAddClass("actor");
        }
        else if (state.college_id == 36) {
            setAddClass("sisitc");
        }
        else {
            setAddClass("");
        }
    }, [state.college_id]);
    console.log('state', state)

    const [signature, setsignaute] = useState('');
    const [membership_file, setmembership_file] = useState();

    // const handleChange_signature = (evnt)=>{
    //    const { name, value } = evnt.target;
    //        setsignaute(evnt.target.files[0]);
    //        setState(prevState => ({
    //         ...prevState,
    //         [name]: value,
    //     }))
    //    }

    //    const handleChange_signature = (e) => {
    //     const { name, value } = e.target
    //                setsignaute(e.target.files[0]);
    //                console.log('signature',e.target.files[0]);

    //     setState(prevState => ({
    //         ...prevState,
    //         [name]: value,
    //     }))

    // }

    const navigate = useNavigate();
    const [errors, seterrors] = useState([]);
    console.log('errors', errors);

    const onSubmit = (e) => {
        e.preventDefault();
        // alert('tt')
        settyspe_pdf('');
        const dd = validationfunction();
        if (dd) {
            setloader(true);
            settyspe_pdf('');
            sendDetailsToServer();
        } else {
            return false;
        }
    };
    const [page, setPage] = useState(1);
    const nextFunction = (e, n) => {
        if (n == "previous") {
            setPage(e);
        }
        else {
            let valid = true;
            let errors = {};
            if (page == 1) {
                if (state.dob == "") {
                    valid = false;
                    errors["dob"] = "Please enter the dob";
                }


                else {

                    var parts = state.dob.split("-");
                    var dtDOB = new Date(parts[2] + "/" + parts[1] + "/" + parts[0]);
                    var dtCurrent = new Date();
                    console.log("DTBOB", dtCurrent.getFullYear())
                    if (dtCurrent.getFullYear() - parts[0] < 17) {
                        valid = false;
                        errors["dob"] = "Eligibility minimum 18 years.";
                    }

                    if (dtCurrent.getFullYear() - dtDOB.getFullYear() == 18) {

                        //CD: 11/06/2018 and DB: 15/07/2000. Will turned 18 on 15/07/2018.
                        if (dtCurrent.getMonth() < parts[1]) {
                            valid = false;
                            errors["dob"] = "Eligibility minimum 18 years.";
                        }
                        if (dtCurrent.getMonth() == parts[1]) {
                            //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
                            if (dtCurrent.getDate() < parts[0]) {
                                valid = false;
                                errors["dob"] = "Eligibility minimum 18 years.";
                            }
                        }
                    }

                }


                if (state.gender == "") {
                    valid = false;
                    errors["gender"] = "Gender Must";
                }
                if (state.first_name == "") {
                    valid = false;
                    errors["first_name"] = "please enter  first name";
                }
                if (state.last_name == "") {
                    valid = false;
                    errors["last_name"] = "please enter last name";
                }
                if (state.marital_status == "") {
                    valid = false;
                    errors["marital_status"] = "please select a marital status";
                }
                if (state.address == "") {
                    valid = false;
                    errors["address"] = "please enter address";
                }
                if (state.student_city == "") {
                    valid = false;
                    errors["student_city"] = "please enter student city";
                }
                if (state.student_country == "") {
                    valid = false;
                    errors["student_country"] = "please enter  student country";
                }
                if (state.student_state == "") {
                    valid = false;
                    errors["student_state"] = "please enter  student state";
                }

                if (!state.expemtion) {
                    if (state.primary_email == "") {
                        valid = false;
                        errors["primary_email"] = "please enter primary email";
                    }
                }
                if (state.mobile_number == "") {
                    valid = false;
                    errors["mobile_number"] = "please enter mobile number";
                } else if (state.mobile_number !== "") {
                    const phoneNumberRegex = /^[0-9]{10}$/;
                    const isValid = phoneNumberRegex.test(state.mobile_number);
                    if (!isValid) {
                        errors["mobile_number"] = "please enter 10 digit mobile number";
                    }
                }
                if (state.shore_country == "") {
                    valid = false;
                    errors["shore_country"] = "please select a shore country";
                }
                if (state.area == "") {
                    valid = false;
                    errors["area"] = "please enter the area";
                }
                if (state.father_occupation == "") {
                    valid = false;
                    errors["father_occupation"] = "Please enter the father occupation"
                }
                if (state.mother_occupation == "") {
                    valid = false;
                    errors["mother_occupation"] = "Please enter the mother occupation"
                }
                if (state.country_code == "") {
                    valid = false;
                    errors["country_code"] = "Country Code must";
                }
            }

            else if (page == 2) {
                if (state.college_id == "") {
                    valid = false;
                    errors["collgenmae"] = "please select a college";
                }
                if (state.shore == "") {
                    valid = false;
                    errors["shore"] = "Please shore is must"
                }

                if (inputList.length < 1) {
                    valid = false;
                    errors["course"] = "please course and intakes";
                }
            }

            else if (page == 3) {
                if (state.tenth_doc == "") {
                    valid = false;
                    errors['tenth_doc'] = 'Please upload the 10th document';
                }
                if (state.tenth_board_of_education == "") {
                    valid = false;
                    errors['tenth_board_of_education'] = "Please entrer the board of education";
                }
                if (state.tenth_year_of_pass == "") {
                    valid = false;
                    errors['tenth_year_of_pass'] = "Please enter the year of pass";
                }
                if (state.tenth_country == "") {
                    valid = false;
                    errors['tenth_country'] = "Please enter the country";
                }
                if (state.tenth_4_core_subject == "") {
                    valid = false;
                    errors['tenth_4_core_subject'] = "Please enter the core subject";
                }
                if (state.twelve_doc == "") {
                    valid = false;
                    errors['twelve_doc'] = "Please upload the 12th document";
                }
                if (state.twelve_board_of_education == "") {
                    valid = false;
                    errors['twelve_board_of_education'] = "Please enter the board of exams";
                }
                if (state.twelve_year_of_pass == "") {
                    valid = false;
                    errors['twelve_year_of_pass'] = "Please enter the year of pass";
                }
                if (state.twelve_country == "") {
                    valid = false;
                    errors['twelve_country'] = "Please enter the country";
                }
                if (state.twelve_4_core_subject == "") {
                    valid = false;
                    errors['twelve_4_core_subject'] = "Please enter the 4 core subject"
                }
                if (state.twelve_doc == "") {
                    valid = false;
                    errors['twelve_doc'] = 'Please upload the 12th document';
                }
                if (programdata.course_name == "Master of Information Technology") {
                    if (state.first_sem == '') {
                        valid = false;
                        errors['first_sem'] = 'please upload the 1th semester';
                    }

                    if (state.second_sem == '') {
                        valid = false;
                        errors['second_sem'] = 'please upload the 2th semester';
                    }

                    if (state.third_sem == '') {
                        valid = false;
                        errors['third_sem'] = 'please upload the 3th semester';
                    }

                    if (state.fourth_sem == '') {
                        valid = false;
                        errors['fourth_sem'] = 'please upload the 4th semester';
                    }
                }


            }

            else if (page == 4) {

                if (state.passport_number == "" && state.shore == "Onshore") {
                    valid = false;
                    errors["passport_number"] = "please enter country passport";
                }
                if (state.address == "") {
                    valid = false;
                    errors["address"] = "please enter address";
                }

                if (!state.expemtion) {
                    if (state.overall_score == "") {
                        valid = false;
                        errors["overall_score"] = "please enter overall score";
                    }
                    if (state.overall_score != "") {
                        if (state.college_id == 36 && state.overall_score <= 6) {
                            valid = false;
                            errors["overall_score"] = "Minimum requirement 6+ score";
                        }
                    }
                    if (state.listing_score == "") {
                        valid = false;
                        errors["listing_score"] = "please enter listing score";
                    }
                    if (state.reading_score == "") {
                        valid = false;
                        errors["reading_score"] = "please enter reading score";
                    }
                    if (state.writing_score == "") {
                        valid = false;
                        errors["writing_score"] = "please enter writing score";
                    }
                    if (state.speaking_score == "") {
                        valid = false;
                        errors["speaking_score"] = "please enter speaking score";
                    }
                    if (state.test_score == "") {
                        valid = false;
                        errors["test_score"] = "please enter test score";
                    }
                }
            }
            seterrors(errors);
            if (valid) {
                setPage(e);
            }
        }

    }

    const validationfunction = () => {
        let valid = true;
        let errors = {};
        if (state.college_id == "") {
            valid = false;
            errors["collgenmae"] = "please select a college";
        }

        if (state.gender == "") {
            valid = false;
            errors["gender"] = "Gender Must";
        }
        if (state.first_name == "") {
            valid = false;
            errors["first_name"] = "please enter  first name";
        }
        if (state.last_name == "") {
            valid = false;
            errors["last_name"] = "please enter last name";
        }

        if (state.marital_status == "") {
            valid = false;
            errors["marital_status"] = "please select a marital status";
        }
        if (state.student_country == "") {
            valid = false;
            errors["student_country"] = "please enter  student country";
        }

        if (state.student_state == "") {
            valid = false;
            errors["student_state"] = "please enter  student state";
        }

        if (state.student_city == "") {
            valid = false;
            errors["student_city"] = "please enter student city";
        }
        if (state.address == "") {
            valid = false;
            errors["address"] = "please enter address";
        }
        if (state.address == "") {
            valid = false;
            errors["address"] = "please enter address";
        }

        // if (state.postal_zipcode == "") {
        //   valid = false;
        //   errors["postal_zipcode"] = "please enter postal zipcode";
        // } 
        // else if (state.postal_zipcode !== "") {
        // const phoneNumberRegex = /^[0-9]{6}$/;
        // const isValid = phoneNumberRegex.test(state.postal_zipcode);
        // if (!isValid) {
        //   errors["postal_zipcode"] = "please enter 6 digit postal zipcode";
        // }
        // }
        if (state.passport_number == "" && state.shore == "Onshore") {
            valid = false;
            errors["passport_number"] = "please enter country passport";
        }
        if (!state.expemtion) {
            if (state.test_score == "") {
                valid = false;
                errors["test_score"] = "please enter test score";
            }
        }
        if (inputList.length < 1) {
            valid = false;
            errors["course"] = "please course and intakes";
        }


        if (programdata.course_name == "Master of Information Technology") {
            if (state.first_sem == '') {
                valid = false;
                errors['first_sem'] = 'please upload the 1th semester';
            }

            if (state.second_sem == '') {
                valid = false;
                errors['second_sem'] = 'please upload the 2th semester';
            }

            if (state.third_sem == '') {
                valid = false;
                errors['third_sem'] = 'please upload the 3th semester';
            }

            if (state.fourth_sem == '') {
                valid = false;
                errors['fourth_sem'] = 'please upload the 4th semester';
            }
        }
        if (!state.expemtion) {
            if (state.overall_score == "") {
                valid = false;
                errors["overall_score"] = "please enter overall score";
            }

            if (state.overall_score != "") {
                if (state.college_id == 36 && state.overall_score <= 6) {
                    console.log("error_college_id", state.college_id);
                    console.log("state.overall_score", state.overall_score);
                    valid = false;
                    errors["overall_score"] = "Minimum requirement 6+ score";
                }
            }

            if (state.listing_score == "") {
                valid = false;
                errors["listing_score"] = "please enter listing score";
            }

            if (state.reading_score == "") {
                valid = false;
                errors["reading_score"] = "please enter reading score";
            }

            if (state.writing_score == "") {
                valid = false;
                errors["writing_score"] = "please enter writing score";
            }

            if (state.speaking_score == "") {
                valid = false;
                errors["speaking_score"] = "please enter speaking score";
            }

            if (state.primary_email == "") {
                valid = false;
                errors["primary_email"] = "please enter primary email";
            }
        }
        if (state.mobile_number == "") {
            valid = false;
            errors["mobile_number"] = "please enter mobile number";
        } else if (state.mobile_number !== "") {
            const phoneNumberRegex = /^[0-9]{10}$/;
            const isValid = phoneNumberRegex.test(state.mobile_number);
            if (!isValid) {
                errors["mobile_number"] = "please enter 10 digit mobile number";
            }
        }

        if (state.shore_country == "") {
            valid = false;
            errors["shore_country"] = "please select a shore country";
        }

        seterrors(errors);

        return valid;
    };

    const [inputList, setInputList] = useState([{ program_id: "", intake: "" }]);
    console.log("inputList", inputList);
    const [inputList1, setInputList1] = useState([{ program_id: "", intake: "" }]);

    const [loader, setloader] = useState(false);
    const user_id = localStorage.getItem("token-info");
    const [desh, setdesh] = useState([]);
    const [successmessage, setstateforsuccess] = useState("");
    const [selectedValues, setSelectedValues] = useState([]);
    const [colleglist, setstateforcolleglist] = useState([]);
    const [file, setFile] = useState();
    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    const [file3, setFile3] = useState();
    const [visafile, visadetails_file] = useState();
    const [policyfile, policydetails_file] = useState();
    const [familyIncome, setFamilyIncome] = useState(false);
    const [englishUploadDetails1, setEnglishUploadDetails1] = useState();
    const [entranceRequirement1, setentranceRequirement1] = useState()
    const [documentPreviousName1, setDocumentPreviousName1] = useState()
    const [profileApplicantForm1, setProfileApplicantForm1] = useState()


    const [shore, setshore] = useState(false);
    const [level, setlevel] = useState("");
    const [programdata, setprogramdata] = useState({
        course_name: '',
        cor_id: '',
        application_fee: '',
        total_fee: '',
    });
    const [multipleProgramData, setMultipleProgramData] = useState({
        course_name: '',
        cor_id: '',
        application_fee: '',
        total_fee: ''
    });
    //   console.log('programdata',programdata);
    // handle input change
    const handleInputChange = (e, index) => {
        var val = e.target.value;
        courselist.map((e, key) => {
            if (e.id == val) {
                setprogramdata(e);
                setstateforprogramintakelist(e.program_intake);
                setlevel(e.program_level);
            }
        });

        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const handleInputChange2 = (e, index) => {
        var val = e.target.value;
        console.log("proogram_id", val);
        state.program_id = val;
        courselist.map((e, key) => {
            if (e.id == val) {
                setMultipleProgramData(e);
                setStateAnotherForProgramIntakeList(e.program_intake);
            }
        });

        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList1(list);
    };



    // handle click event of the Remove button
    const handleRemoveClick = (index) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { program_id: "", intake: "" }]);
    };

    const [collegedata, setcollegedata] = useState({
        college_name: '',
        college_unique_id: "",
        college_logo: "",
    });
    const [inter, setinternational] = useState();

    const handleChange = (e) => {
        let { name, value } = e.target;
        // console.log('value',value);

        if (name == 'expemtion') {
            value = !state.expemtion;
        }
        console.log("value", value);
        // if (state.expemtion == "yes" && name == "test_score") {
        //    value = "";
        // }

        if (name === "check_tuition_fee") {
            setinternational(false);
        }
        else {
            setinternational(true);
        }
        if (name == "college_id") {
            senddetailsforcollegeid(value);
            switch(value){  
                case "36":  
                senddetailsforpathwaycollegeid(41);
                break;  
                case "41":  
                senddetailsforpathwaycollegeid(36);
                break;  
            }
            colleglist.map((e, key) => {
                if (e.id == value) {
                    setcollegedata(e);

                }
            });
        }

        // if(name == "pathway_college")
        // {
        //     switch(value){  
        //         case "36":  
        //         senddetailsforpathwaycollegeid(41);
        //         break;  
        //         case "41":  
        //         senddetailsforpathwaycollegeid(36);
        //         break;  
        //     }
        // }

        if (name == "shore" && value == "Onshore") {
            setshore(true);
        } else if (name == "shore" && value == "Offshore") {
            setshore(false);
        }

        if (e.target.name === "intake_month") {
            let selectedValues = Array.from(e.target.selectedOptions).map(
                (opt) => opt.value
            );
            setSelectedValues(selectedValues);
        }
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setFile(e.target.files[0]);
    };

    const [sig, setSelectedImage1] = useState();

    const handleChange_signature = (e) => {

        const { name, value } = e.target;
        if (value) {
            setDisabledButton(true);
        }
        if (value.slice(-3) == 'pdf') {
            alert("pdf is not allowed");
            setDisabledButton(false);
        } else {
            setSelectedImage1(URL.createObjectURL(e.target.files[0]));

            setsignaute(e.target.files[0]);
        }
    };
    const handleChange_membership_file = (e) => {
        const { name, value } = e.target;
        setmembership_file(e.target.files[0]);
    };
    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setFile1(e.target.files[0]);
    };
    const handleChange3 = (e) => {
        const { name, value } = e.target;
        setFile2(e.target.files[0]);
    };
    const handleChange4 = (e) => {
        const { name, value } = e.target;
        setFile3(e.target.files[0]);
    };

    const englishUploadDetailsFun = (e) => {
        const { name, value } = e.target;
        setEnglishUploadDetails1(e.target.files[0]);
    }

    const entranceRequirementFun = (e) => {
        const { name, value } = e.target;
        setentranceRequirement1(e.target.files[0]);
    }

    const documentPreviousNameFun = (e) => {
        const { name, value } = e.target;
        setDocumentPreviousName1(e.target.files[0]);
    }

    const profileApplicantFormFun = (e) => {
        const { name, value } = e.target;
        setProfileApplicantForm1(e.target.files[0]);
    }

    const visadetails = (e) => {
        const { name, value } = e.target;
        visadetails_file(e.target.files[0]);
    };

    const policydetails = (e) => {
        const { name, value } = e.target;
        policydetails_file(e.target.files[0]);
    };
    const handleChange5 = (e) => {
        const { name, value } = e.target;
        senddetailsforcollegeid(value);
    };
    const handleChange6 = (e) => {
        const { name, value } = e.target;
    };
    const sendDataToParent = (e, b) => {
        console.log("send data to parent b", b);
        console.log("send data to parent e", e);
        // the callback. Use a better name
        setState((prevState) => ({
            ...prevState,
            [b]: e,
        }));
    };

    useEffect(() => {

        // const localStorage = localStorage.getItem();
        //   console.log('localStorage',localStorage);
        // agent_oradmin_profile();
        // $('.select2').select2();

        country_list();
        Collegelist();
    }, []);



    const senddetailsforcollegeid = async (value) => {
        let formdata = new FormData();
        formdata.append("id", value);
        axios
            .post(Network.Network.api_url + "program_list_for_application", formdata)
            .then(function (response) {
                if ((response.data.status = "success")) {
                    // alert('tyws')
                    // console.log('all',response.data.data);

                    if (response.data.data.length > 0) {
                        setstateforcourselist(response.data.data);
                        var thisdata = response.data.data[0].program_intake;
                        setstateforprogramintakelist(thisdata);
                    } else {
                        setstateforprogramintakelist({});
                    }
                } else {
                    setstateforprogramintakelist({});
                }
            });
    };

    const senddetailsforpathwaycollegeid = async (value) => {
        let formdata = new FormData();
        console.log("pathway_value", value)
        formdata.append("id", value);
        axios
            .post(Network.Network.api_url + "program_list_for_application", formdata)
            .then(function (response) {
                console.log("response", response);
                if ((response.data.status = "success")) {
                    // alert('tyws')
                    // console.log('all',response.data.data);

                    if (response.data.data.length > 0) {
                        setstateforpathwaycourselist(response.data.data);
                        var thisdata = response.data.data[0].program_intake;
                        setstateforpathwayprogramintakelist(thisdata);
                    } else {
                        setstateforpathwayprogramintakelist({});
                    }
                } else {
                    setstateforpathwayprogramintakelist({});
                }
            });
    };
    const Collegelist = async () => {
        axios
            .post(Network.Network.api_url + "college_list_for_application")
            .then(function (response) {
                setstateforcolleglist(response.data.data);
            });
    };

    const country_list = async () => {
        axios
            .post(Network.Network.api_url + "country_list")
            .then(function (response) {
                setdesh(response.data.data);
            });
    };
    let storeBooleanValue = [];
    const sendDetailsToServer = async () => {
        setloader(true);
        // alert('sumbi')
        let formdata = new FormData();
        //   console.log('inputList',inputList);
        for (let index = 0; index < inputList.length; index++) {
            formdata.append("program_id[]", inputList[index].program_id);
            formdata.append("program_intake[]", inputList[index].program_intake);
        }
        for (let index = 0; index < inputList1.length; index++) {
            formdata.append("multiple_program_id[]", inputList1[index].program_id);
            formdata.append("multiple_program_intake[]", inputList1[index].program_intake);
        }
        for (let index = 0; index < inputFields.length; index++) {
            formdata.append("label[]", inputFields[index].label);
            formdata.append("document[]", inputFields[index].source_doc);
        }
        //  console.log('signature',signature);
        // formdata.append('courseand_intakes',inputList);
        formdata.append("other_doc", file);
        formdata.append("upload_academics", file1);
        formdata.append("upload_passport", file2);
        formdata.append("upload_ielts", file3);
        formdata.append("signature", signature);

        // formdata.append('program_id',state.program_id);
        // formdata.append('program_intake',state.program_intake);
        formdata.append("added_by", user_id);
        formdata.append("user_type", "Agent");
        formdata.append("program_level", state.program_level);
        formdata.append("college_id", state.college_id);
        formdata.append("gender", state.gender);
        formdata.append("first_name", state.first_name);
        formdata.append("last_name", state.last_name);
        formdata.append("marital_status", state.marital_status);
        formdata.append("student_country", state.student_country);
        formdata.append("student_state", state.student_state);
        formdata.append("student_city", state.student_city);
        formdata.append("address", state.address);
        formdata.append("area", state.area);
        formdata.append("postal_zipcode", state.postal_zipcode);
        formdata.append("passport_number", state.passport_number);
        formdata.append("father_occupation", state.father_occupation);
        formdata.append("family_income", inputFields);
        formdata.append("test_score", state.test_score);
        formdata.append("exam_date", selectedDate);
        formdata.append("overall_score", state.overall_score);
        formdata.append("listing_score", state.listing_score);
        formdata.append("reading_score", state.reading_score);
        formdata.append("writing_score", state.writing_score);
        formdata.append("speaking_score", state.speaking_score);
        // formdata.append('visa_permit',state.visa_permit);
        formdata.append("primary_email", state.primary_email);
        formdata.append("mobile_number", state.mobile_number);

        formdata.append("shore", state.shore);
        formdata.append("shore_country", state.shore_country);
        formdata.append("visa_details_upload", visafile);
        formdata.append("visa_details_expire_date", state.visaexpirydate);
        formdata.append("policy_upload", policyfile);
        formdata.append("policy_expire_date", state.policyexpirydate);
        formdata.append("pathway", state.pathway);
        formdata.append("coe", state.coe);
        formdata.append("country_code", state.country_code);
        formdata.append("tenth_doc", state.tenth_doc);
        formdata.append("twelve_doc", state.twelve_doc);
        formdata.append("first_sem", state.first_sem);
        formdata.append("second_sem", state.second_sem);
        formdata.append("third_sem", state.third_sem);
        formdata.append("fourth_sem", state.fourth_sem);
        formdata.append("fiveth_sem", state.fiveth_sem);
        formdata.append("seventh_sem", state.seventh_sem);
        formdata.append("eighth_sem", state.eighth_sem);
        formdata.append("visa_number", state.visa_number);

        formdata.append("campus_location", state.campus_location);

        formdata.append("check_tuition_fee", state.check_tuition_fee);
        formdata.append("visa_type", state.visa_type);
        formdata.append("student_health_cover", state.student_health_cover);
        formdata.append(
            "student_health_cover_status",
            state.student_health_cover_status
        );
        console.log('state', state)
        formdata.append("your_provider", state.your_provider);
        formdata.append("membership_number", state.membership_number);
        formdata.append("membership_file", membership_file);
        formdata.append("apply_your_behalf_status", state.apply_your_behalf_status);
        formdata.append("student_location", state.student_location);
        formdata.append("home_phone", state.home_phone);
        formdata.append("work_phone", state.work_phone);
        formdata.append("mobile_phone", state.mobile_phone);
        formdata.append("email_address", state.email_address);
        formdata.append("address_international", state.address_international);
        formdata.append("int_address_country", state.int_address_country);
        formdata.append("postal_address", state.postal_address);
        formdata.append("emergency_name", state.emergency_name);
        formdata.append("relationship", state.relationship);
        formdata.append("emergency_contact_number", state.emergency_contact_number);
        formdata.append("emergency_address", state.emergency_address);
        formdata.append("born_country", state.born_country);
        formdata.append("speak_language", state.speak_language);
        formdata.append(
            "speak_language_specify_status",
            state.speak_language_specify_status
        );
        formdata.append("speak_language_specify", state.speak_language_specify);
        formdata.append(
            "english_language_proficiency",
            state.english_language_proficiency
        );
        formdata.append(
            "english_language_proficiency_status",
            state.english_language_proficiency_status
        );
        formdata.append("disability", state.disability);
        formdata.append("disability_status", state.disability_status);
        formdata.append("disability_area", state.disability_area);
        formdata.append(
            "disability_language_status",
            state.disability_language_status
        );
        formdata.append(
            "disability_language_status_about",
            state.disability_language_status_about
        );

        formdata.append(
            "disability_health_status_about",
            state.disability_health_status_about
        );
        formdata.append("disability_health_status", state.disability_health_status);
        formdata.append("prior_Learning_status", state.prior_Learning_status);
        formdata.append("rpl_apply_status", state.rpl_apply_status);
        formdata.append("rpl_application_status", state.rpl_application_status);
        formdata.append(
            "credit_transfer_apply_status",
            state.credit_transfer_apply_status
        );
        formdata.append(
            "credit_transfer_application_satus",
            state.credit_transfer_application_satus
        );
        formdata.append("payment_option", state.payment_option);
        formdata.append("study_period", state.study_period);
        formdata.append("payment_plan", state.payment_plan);
        formdata.append("qualification_details", state.qualification_details);
        formdata.append("study_period_notes", state.study_period_notes);
        formdata.append("reason_of_study", state.reason_of_study);
        formdata.append("employment_status", state.employment_status);
        formdata.append("disability_health_status_other", state.disability_health_status_other);
        formdata.append("course_name", programdata.course_name);
        formdata.append('multiple_course_name', multipleProgramData.course_name)
        formdata.append("cor_id", programdata.cor_id);
        formdata.append("college_unique_id", collegedata.college_unique_id);
        formdata.append("application_fee", programdata.application_fee);
        formdata.append("total_fee", programdata.total_fee);
        formdata.append("dob", state.dob);
        formdata.append("qualifications", state.qualifications);
        formdata.append("scmqualifications", state.scmqualifications);
        formdata.append("document_attached", state.document_attached);
        formdata.append("passport_and_visa", state.passport_and_visa);
        formdata.append("qualificationfourthabove", state.qualificationfourthabove);
        formdata.append("highschoolcertificate", state.highschoolcertificate);
        formdata.append("itelsorEquivalent", state.itelsorEquivalent);
        formdata.append('AETA', state.AETA);


        formdata.append("weeks", program_intake_list && program_intake_list[0].year != '' ? program_intake_list[0].year : "");
        formdata.append("pdf", pdftype);
        formdata.append("admission", state.admission);
        formdata.append("admission1", state.admission1);
        formdata.append("admission2", state.admission2);
        formdata.append("admission3", state.admission3);
        formdata.append("admission4", state.admission4);
        formdata.append("admission5", state.admission5);
        formdata.append("admission6", state.admission6);
        formdata.append("credit_transfer_studies_completed", state.credit_transfer_studies_completed)
        formdata.append("first_lang_english", state.first_lang_english);
        formdata.append("agentId", userid);
        formdata.append("other_medical_condition", state.other_medical_condition);

        formdata.append("englishUploadDetails", englishUploadDetails1)
        formdata.append("entranceRequirement", entranceRequirement1)
        formdata.append("documentPreviousName", documentPreviousName1)
        formdata.append("profileApplicantForm", profileApplicantForm1)

        formdata.append("EAPprogram", state.EAPprogram);
        formdata.append("EAPprogramWeeks", state.EAPprogramWeeks);
        formdata.append("academicPurposesProgram", state.academicPurposesProgram);
        formdata.append("australianindigenouspersonstatus", state.australianindigenouspersonstatus);
        formdata.append("countryOfResidence", state.countryOfResidence);
        formdata.append("courseAustraliaCredit", state.courseAustraliaCredit);
        formdata.append("disabilitiesList", state.disabilitiesList);
        formdata.append("educationInAustralia", state.educationInAustralia);
        formdata.append("passportname", state.passportname);
        formdata.append("passportFamilname", state.passportFamilname);
        formdata.append("periodInsurance", state.periodInsurance);
        formdata.append("startingDateEAPprogam", state.startingDateEAPprogam);
        formdata.append("typeOfApplication", state.typeOfApplication);
        formdata.append("typeOfCover", state.typeOfCover);
        formdata.append("typeOfPassport", state.typeOfPassport);
        formdata.append("usi", state.usi);
        formdata.append("yearArrivalAuustralia", state.yearArrivalAuustralia);
        formdata.append("yearOfCompletedEducation", state.yearOfCompletedEducation);
        formdata.append("passport_expiry_date", state.passport_expiry_date);
        formdata.append("backlog_certificate", state.backlogCertificate);
        formdata.append("pathway_college", state.pathway_college);
        formdata.append("pathway_course", state.pathway_course);
        formdata.append("mother_occupation", state.mother_occupation);
        formdata.append("apply_expemtion", state.expemtion);
        formdata.append('tenth_board_of_education', state.tenth_board_of_education);
        formdata.append('tenth_year_of_pass', state.tenth_year_of_pass);
        formdata.append('tenth_country', state.tenth_country);
        formdata.append('tenth_4_core_subject', state.tenth_4_core_subject);
        formdata.append('twelve_board_of_education', state.twelve_board_of_education);
        formdata.append('twelve_year_of_pass', state.twelve_year_of_pass);
        formdata.append('twelve_country', state.twelve_country);
        formdata.append('twelve_4_core_subject', state.twelve_4_core_subject);
        formdata.append('upload_official_document', state.upload_official_document);



        console.log("formdata", formdata);
        // console.log('---------------',state);
        // setloader(false);
        let storeBooleanValue = [];
        if (pdftype == 'pdf') {
            const uniqueIds = [];
            inputFields.filter(element => {
                const isDuplicate = uniqueIds.includes(element.label);
                if (isDuplicate) {
                    setIsDuplicateFamily(true);
                    storeBooleanValue.push(true);
                }
                else {
                    uniqueIds.push(element.label);
                    storeBooleanValue.push(false);
                }
            });
            if (!storeBooleanValue.includes(true)) {
                axios({
                    url: Network.Network.api_url + "dowloadpdf",
                    data: formdata,
                    method: 'POST',
                    responseType: 'blob', // important
                }).then((response) => {
                    setpreviewupload(true);
                    // console.log(response);
                    setloader(false);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                });

                return false;
            }
            else {
                alert("income field is duplicate");
                setloader(false);
            }
        }
        else {

            const uniqueIds = [];

            inputFields.filter(element => {
                const isDuplicate = uniqueIds.includes(element.label);

                if (isDuplicate) {
                    setIsDuplicateFamily(true);
                    storeBooleanValue.push(true);
                }
                else {
                    uniqueIds.push(element.label);
                    storeBooleanValue.push(false);
                }
            });

            if (!storeBooleanValue.includes(true)) {
                axios.post(Network.Network.api_url + "addapplication", formdata)
                    .then(function (response) {
                        if (response.data.status == "success") {
                            setloader(false);
                            alert('Add Application Successfully')
                            setstateforsuccess("Add Application Successfully");
                            navigate('/agent/applicationlist');
                        } else {
                            setloader(false);
                            alert('something else worng')
                            navigate("/agent/applicationlist");
                            setstateforsuccess("Add Application Successfully")
                        }
                    });
            }
            else {
                alert("duplicate family income")
            }
        }



        // setloader(false);
        // console.log('sdssuuuuff',state);

    };













    const [selectedDate, setSelectedDate] = useState("");
    // Function to update the selected date based on the dropdowns
    // function handleDateChange() {
    //   const day = document.getElementById('dob-day').value;
    //   const month = document.getElementById('dob-month').value;
    //   const year = document.getElementById('dob-year').value;
    //   setSelectedDate(`${year}-${month}-${day}`);
    // }

    // Function to generate an array of options for a dropdown
    // function generateOptions(start, end) {
    //   const options = [];
    //   for (let i = start; i <= end; i++) {
    //     options.push(<option key={i} value={i}>{i}</option>);
    //   }
    //   return options;
    // }

    return (
        <>
            <Header />
            <Sidebar />

            {loader == true ? <Loader></Loader> : ""}


            <main id="main" className="main">
                <p>{successmessage ? successmessage : ""}</p>
                {/* <div className="pagetitle">

                <p>{successmessage ? successmessage : ''}</p>
                <h1>Form Elements</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item">Forms</li>
                        <li className="breadcrumb-item active">Elements</li>
                    </ol>
                </nav>
            </div> */}



                <div class="container-fluid">
                    <div class="row justify-content-center stpform">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2">
                            <div class="card px-0 pt-4 pb-0 mt-3 mb-3">
                                <h2 id="heading">Add Application</h2>

                                <div>
                                    {collegedata.college_logo != '' ?
                                        <img style={{ width: "200px", float: "right" }} src={Network.Network.image_url + collegedata.college_logo} />
                                        : ""}
                                </div>

                                <form onSubmit={onSubmit} encType="multipart/form-data" id="msform">

                                    <ul id="progressbar">
                                        <li className={`${page == 1 ? "active" : ""}`} id="account"><strong>Personal Detail</strong></li>
                                        <li className={`${page == 2 ? "active" : ""}`} id="personal"><strong>Course / Institude</strong></li>
                                        <li className={`${page == 3 ? "active" : ""}`} id="payment"><strong>Academics</strong></li>
                                        <li className={`${page == 4 ? "active" : ""}`} id="step_4"><strong>English Proficiency</strong></li>
                                        <li className={`${page == 5 ? "active" : ""}`} id="step_5"><strong>Review & Submit</strong></li>
                                    </ul> <br></br>

                                    <div class="form-card" style={{ display: page == 1 ? "block" : "none" }}>
                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputNumber"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Given Name <span style={{ color: "red" }}>*</span> <h6 style={{ color: "white" }}>{`( Please ensure you add given name and surname as per passport. if applicant has either only a surname or  only a given name. Please fill the enter name in the family name column )`}</h6><h6>{errors.first_name}</h6>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="first_name"
                                                            value={state.first_name}
                                                            onChange={handleChange}
                                                            placeholder="Given Name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputNumber"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Family Name <span style={{ color: "red" }}>*</span><h6>{errors.last_name}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="last_name"
                                                            value={state.last_name}
                                                            onChange={handleChange}
                                                            placeholder="Family Name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputNumber"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >

                                                        Date of Birth <span style={{ color: "red" }}>*</span><h6>{errors.dob}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="dob"
                                                            value={state.dob}
                                                            onChange={handleChange}
                                                            placeholder="dob"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col">

                                                <div className="set" >
                                                    <label
                                                        for="inputNumber"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Gender <span style={{ color: "red" }}>*</span> <h6 style={{ color: "red" }}>{errors.gender}</h6>
                                                    </label>
                                                    <div className="col" style={{ display: "flex" }}>
                                                        Male{" "}
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value="Male"
                                                            onClick={handleChange}
                                                        />
                                                        Female{" "}
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value="Female"
                                                            onClick={handleChange}
                                                        />&nbsp;&nbsp;
                                                        Other{" "}
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value="Other"
                                                            onClick={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="set ">
                                                    <label
                                                        for="inputNumber"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Marital Status <span style={{ color: "red" }}>*</span><h6>{errors.marital_status}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <select
                                                            name="marital_status"
                                                            value={state.marital_status}
                                                            onChange={handleChange}
                                                            className="col-form-label form-control"
                                                        >
                                                            <option value="">Please Select</option>
                                                            <option value="Married">Married</option>
                                                            <option value="Single">Single</option>
                                                            <option value="Divorce">Divorce</option>
                                                            <option value="Separated">Separated</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Nationality  <span style={{ color: "red" }}>*</span><h6 style={{ color: "red" }}>{errors.shore_country}</h6>
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <select
                                                            className="form-control"
                                                            name="shore_country"
                                                            value={state.shore_country}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="" disabled>
                                                                --select--
                                                            </option>
                                                            {desh.length > 0
                                                                ? desh.map((e, key) => {
                                                                    return (
                                                                        <option key={key} value={e.country_name}>
                                                                            {e.country_name}
                                                                        </option>
                                                                    );
                                                                })
                                                                : ""}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Address line 1 <span style={{ color: "red" }}>*</span><h6 style={{ color: "red" }}>{errors.area}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="area"
                                                            value={state.area}
                                                            onChange={handleChange}
                                                            placeholder="Area"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="set col-md-6">
                                                <label
                                                    for="inputPassword"
                                                    className={`col-sm-3 col-form-label ${addClass}`}
                                                >
                                                    Postal Zipcode <h6>{errors.postal_zipcode}</h6>
                                                </label>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="postal_zipcode"
                                                        value={state.postal_zipcode}
                                                        onChange={handleChange}
                                                        placeholder="Postal Zipcode"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Address Line 2 <span style={{ color: "red" }}>*</span><h6 style={{ color: "red" }}>{errors.address}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="address"
                                                            value={state.address}
                                                            onChange={handleChange}
                                                            placeholder="Address"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        City/District/Suburb of Residence <span style={{ color: "red" }}>*</span> <h6 style={{ color: "red" }}>{errors.student_city}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="student_city"
                                                            value={state.student_city}
                                                            onChange={handleChange}
                                                            placeholder="Student City"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Province/State of Residence <span style={{ color: "red" }}>*</span> <h6 style={{ color: "red" }}>{errors.student_state}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="student_state"
                                                            value={state.student_state}
                                                            onChange={handleChange}
                                                            placeholder="Student State"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Country of Residence Address <span style={{ color: "red" }}>*</span> <h6 style={{ color: "red" }}>{errors.student_country}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="student_country"
                                                            value={state.student_country}
                                                            onChange={handleChange}
                                                            placeholder="Student Country"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {state.college_id == 61 ?
                                                <div className="col">
                                                    <div className="set">
                                                        <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}> Country of Residence (what country will you return to when your visa expires?)</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="countryOfResidence"
                                                            value={state.countryOfResidence}
                                                            onChange={handleChange}
                                                            placeholder="Country of Residence (what country will you return to when your visa expires?)"
                                                        />
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>



                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        <h6>{errors.postal_zipcode}</h6> Primary Email <span style={{ color: "red" }}>*</span> <h6>{errors.primary_email}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="primary_email"
                                                            value={state.primary_email}
                                                            onChange={handleChange}
                                                            placeholder="Primary Email"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Country code  <span style={{ color: "red" }}>*</span> <h6>{errors.country_code}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="country_code"
                                                            value={state.country_code}
                                                            onChange={handleChange}
                                                            placeholder="country code Number"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            maxLength="2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Mobile Number  <span style={{ color: "red" }}>*</span><h6>{errors.mobile_number}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="mobile_number"
                                                            value={state.mobile_number}
                                                            onChange={handleChange}
                                                            placeholder="Mobile Number"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            maxLength="10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Father's/guardian's (sponsor's) occupation  <span style={{ color: "red" }}>*</span><h6 style={{ color: "red" }}>{errors.father_occupation}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="father_occupation"
                                                            value={state.father_occupation}
                                                            onChange={handleChange}
                                                            placeholder="Father Occupation"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Mother occupation  <span style={{ color: "red" }}>*</span><h6>{errors.mother_occupation}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="mother_occupation"
                                                            value={state.mother_occupation}
                                                            onChange={handleChange}
                                                            placeholder="Mother Occupation"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Family Income
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {inputFields.map((data, index) => {
                                            const { label, document, docdate } = data;
                                            return (
                                                <>
                                                    <div className="row set col-md-12" key={index}>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    onChange={(evnt) =>
                                                                        handleChange_fee(index, evnt)
                                                                    }
                                                                    value={label}
                                                                    name="label"
                                                                    className=""
                                                                    placeholder="Enter  Label"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                type="file"
                                                                onChange={(evnt) =>
                                                                    handleChange_fee(index, evnt)
                                                                }
                                                                name="source_doc"
                                                                className=""
                                                                placeholder="Enter Source"
                                                                accept=".pdf"
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                type="date"
                                                                onChange={(evnt) =>
                                                                    handleChange_fee(index, evnt)
                                                                }
                                                                value={docdate}
                                                                name="docdate"
                                                                className=""
                                                                placeholder="Enter Source"
                                                                accept=".pdf"
                                                            />
                                                        </div>

                                                        <div className="col">
                                                            {inputFields.length !== 1 ? (
                                                                <button
                                                                    className="btn btn-outline-danger"
                                                                    type="button"
                                                                    onClick={() => removeInputFields(index)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                        <div className="set row">
                                            <div className="col">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-success "
                                                    onClick={addInputField}
                                                >
                                                    Add New
                                                </button><br></br>
                                                <p>(Note that it has to be filled in AUD and add $ sign)</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label className={addClass}>Contact Details:</label>
                                                    <p>
                                                        {" "}
                                                        Home Phone:{" "}
                                                        <input type="text" name="home_phone" value={state.home_phone} onChange={handleChange} />
                                                    </p>
                                                    <p>
                                                        Work Phone:{" "}
                                                        <input type="text" name="work_phone" value={state.work_phone} onChange={handleChange} />
                                                    </p>
                                                    <p>
                                                        Mobile Phone:{" "}
                                                        <input type="text" name="mobile_phone" value={state.mobile_phone} onChange={handleChange} />
                                                    </p>
                                                    {state.college_id != 42 ? <p>
                                                        Email Address:{" "}
                                                        <input type="text" name="email_address" value={state.email_address} onChange={handleChange} />
                                                    </p> : ""}
                                                </div>
                                            </div>
                                            <div className="set col-md-6">
                                                <label className={addClass}>Address:</label>
                                                <p>
                                                    Address in home country (If International Student):{" "}
                                                    <input
                                                        type="text"
                                                        name="address_international"
                                                        value={state.address_international}
                                                        onChange={handleChange}
                                                    />
                                                </p>

                                                {state.shore == "Offshore" ?
                                                    ""
                                                    : <p>
                                                        Address in Australia:{" "}
                                                        <input type="text" name="int_address_country" value={state.int_address_country} onChange={handleChange} />
                                                    </p>}
                                                {state.college_id != 42 ? <p>
                                                    Postal address (if different from above):{" "}
                                                    <input type="text" name="postal_address" value={state.postal_address} onChange={handleChange} />
                                                </p> : ""}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="set ">
                                                    <label className={addClass}>
                                                        Emergency Contact Details: (LOCAL or INTERNATIONAL){" "}
                                                    </label>

                                                    <div className="row">
                                                        <div className="col-6">
                                                            <p className="mb-0">Name:</p>
                                                            <input type="text" name="emergency_name" value={state.emergency_name} onChange={handleChange} />


                                                        </div>


                                                        <div className="col-6">
                                                            <p className="mb-0">Relationship:</p>
                                                            <input type="text" name="relationship" value={state.relationship} onChange={handleChange} />
                                                        </div>

                                                        <div className="col-6">
                                                            <p className="mb-0">
                                                                Contact Number:</p> {" "}
                                                            <input
                                                                type="text"
                                                                name="emergency_contact_number"
                                                                value={state.emergency_contact_number}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <p className="mb-0">Address:</p>{" "}
                                                            <input type="text" name="emergency_address" value={state.emergency_address} onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="set">
                                                    <label className={addClass}> Disability:</label>
                                                    {state.college_id != 39 ? <p>
                                                        English Language proficiency Result (if available){" "}
                                                        <input type="text" name="disability" value={state.disability} onChange={handleChange} />
                                                    </p> : ""}

                                                    <p>
                                                        Do you consider yourself to have a disability, impairment
                                                        or long-term condition?:<br></br>
                                                        <input
                                                            type="radio"
                                                            name="disability_status"
                                                            value="Yes"
                                                            onChange={handleChange}
                                                        /> Yes

                                                        <br></br>
                                                        <input type="radio" name="disability_status" value="No" onChange={handleChange} />
                                                        No

                                                    </p>
                                                    <p>
                                                        {" "}
                                                        If yes please select the area/s:
                                                        <input type="text" name="disability_area" value={state.disability_area} onChange={handleChange} />
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        English Language proficiency Result (if available)
                                                        <input
                                                            type="text"
                                                            name="disability_language_status_about"
                                                            value={state.disability_language_status_about}
                                                            onChange={handleChange}
                                                        />

                                                        {state.college_id == 61 ? <div><p>To ensure your success the Institute needs to ask the following question.</p>
                                                            <p>Do you have any of the disabilities listed in the next question?</p>
                                                            <input
                                                                type="radio"
                                                                name="disabilitiesList"
                                                                value="Not Stated"
                                                                onChange={handleChange}
                                                            />Not Stated<br></br>
                                                            <input
                                                                type="radio"
                                                                name="disabilitiesList"
                                                                value="No"
                                                                onChange={handleChange}
                                                            />No<br></br>
                                                            <input
                                                                type="radio"
                                                                name="disabilitiesList"
                                                                value="Yes"
                                                                onChange={handleChange}
                                                            />Yes <br></br>

                                                            <p>Period Insurance required for</p>
                                                            <input type="text" name="periodInsurance" value={state.periodInsurance} onChange={handleChange} />
                                                        </div> : ""}
                                                        {state.college_id != 36 && state.college_id != 61 ?
                                                            <div>
                                                                <p><input
                                                                    type="radio"
                                                                    name="disability_language_status"
                                                                    value="Hearing/deaf"
                                                                    onChange={handleChange}
                                                                />
                                                                    Hearing/deaf</p>
                                                                <p><input
                                                                    type="radio"
                                                                    name="disability_language_status"
                                                                    value="Physical"
                                                                    onChange={handleChange}
                                                                />
                                                                    Physical</p>
                                                                <p><input
                                                                    type="radio"
                                                                    name="disability_language_status"
                                                                    value="Intellectual"
                                                                    onChange={handleChange}
                                                                />
                                                                    Intellectual</p>
                                                                <p><input
                                                                    type="radio"
                                                                    name="disability_language_status"
                                                                    value="Learning"
                                                                    onChange={handleChange}
                                                                />
                                                                    Learning</p></div> :
                                                            <div>
                                                                <p><input
                                                                    type="radio"
                                                                    name="disability_language_status"
                                                                    value="Hearing"
                                                                    onChange={handleChange}
                                                                />
                                                                    Hearing</p>
                                                                <p><input
                                                                    type="radio"
                                                                    name="disability_language_status"
                                                                    value="Mobility"
                                                                    onChange={handleChange}
                                                                />
                                                                    Mobility</p>
                                                                <p><input
                                                                    type="radio"
                                                                    name="disability_language_status"
                                                                    value="Vision"
                                                                    onChange={handleChange}
                                                                />
                                                                    Vision</p>
                                                                <p><input
                                                                    type="radio"
                                                                    name="disability_language_status"
                                                                    value="other"
                                                                    onChange={handleChange}
                                                                />
                                                                    Other</p>
                                                                <p>Other medical condition, please specify</p>
                                                                <input type="textarea" name="other_medical_condition" onChange={handleChange} />
                                                            </div>

                                                        }
                                                        {/* <input 
                       type="radio"
                       name="disability_language_status"
                       value="Mental_Illness"
                      //  onChange={handleChange}
                      />
                      Mental Illness
                      <input 
                       type="radio"
                       name="disability_language_status"
                       value="Acquired_brain_impairment"
                       onChange={handleChange}
                      />
                      Acquired brain impairment */}
                                                    </p>

                                                    {state.college_id != 36 ? <div><p>
                                                        English Language proficiency Result (if available)
                                                        <input
                                                            type="text"
                                                            name="disability_health_status_about"
                                                            value={state.disability_health_status_about}
                                                            onChange={handleChange}
                                                        />
                                                        <input
                                                            type="radio"
                                                            name="disability_health_status"
                                                            value="Mental Health Condition"
                                                            onChange={handleChange}

                                                        />
                                                        Mental Health Condition
                                                        <input
                                                            type="radio"
                                                            name="disability_health_status"
                                                            value="Acquired brain impairment"
                                                            onChange={handleChange}

                                                        />
                                                        Acquired brain impairment
                                                        <input
                                                            value="Vision"
                                                            type="radio"
                                                            name="disability_health_status"
                                                            onChange={handleChange}

                                                        />
                                                        Vision
                                                        <input
                                                            value="Medical condition"
                                                            type="radio"
                                                            name="disability_health_status"
                                                            onChange={handleChange}

                                                        />
                                                        Medical condition
                                                    </p>

                                                        <p>
                                                            <input type="radio" name="disability_health_status" />
                                                            Other
                                                            <input
                                                                type="text"
                                                                name="disability_health_status_other"
                                                                value={state.disability_health_status_other}
                                                                onChange={handleChange}

                                                            />
                                                        </p> </div> : ""}
                                                </div>
                                            </div>
                                        </div>

                                        <input type="button" name="next" class="next action-button" value="Next" onClick={() => nextFunction(2)} />

                                    </div>

                                    <fieldset style={{ display: page == 2 ? "block" : "none" }}>
                                        <div class="form-card">

                                            <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label className={addClass}> Select Student Type</label>

                                                        <p>
                                                            <input
                                                                type="radio"
                                                                name="check_tuition_fee"
                                                                value="International Student (Fee for Service)"
                                                                onChange={handleChange}

                                                            />
                                                            International Student (Fee for Service)
                                                        </p>

                                                        <p>
                                                            <input
                                                                type="radio"
                                                                name="check_tuition_fee"
                                                                value="Domestic Student (Fee for Service)"
                                                                onChange={handleChange}
                                                            />
                                                            Domestic Student (Fee for Service)
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="set">
                                                        <label className={addClass}>
                                                            College List <span style={{ color: "red" }}>*</span><h6 style={{ color: "red" }}>{errors.collgenmae}</h6>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <select
                                                                name="college_id"
                                                                onChange={handleChange}
                                                                className="col-form-label form-control"
                                                                value={state.college_id}
                                                            >
                                                                <option value=""> --Select--</option>
                                                                {colleglist.length > 0
                                                                    ? colleglist.map((e, key) => {
                                                                        return (
                                                                            <option key={key} value={e.id}>
                                                                                {e.college_name}
                                                                            </option>
                                                                        );
                                                                    })
                                                                    : ""}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {inputList.map((x, i) => {
                                                return (
                                                    <div className="row">
                                                        <div key={i} className="set col-md-6">
                                                            <label
                                                                for="inputNumber"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >
                                                                Course Name <h6 style={{ color: "red" }}>{errors.course}</h6>
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <select
                                                                    name="program_id"
                                                                    onChange={(e) => handleInputChange(e, i)}
                                                                    className="col-form-label form-control select2"
                                                                    value={i.program_id}
                                                                    required
                                                                >
                                                                    <option value="">Please Select</option>

                                                                    {courselist.length > 0
                                                                        ? courselist.map((e, key) => {
                                                                            return (
                                                                                <option key={key} value={e.id}>
                                                                                    {e.course_name}
                                                                                </option>
                                                                            );
                                                                        })
                                                                        : ""}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="set col-md-6">
                                                            <label
                                                                for="inputNumber"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >
                                                                Program Intake
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <select
                                                                    name="program_intake"
                                                                    onChange={(e) => handleInputChange(e, i)}
                                                                    className="col-form-label form-control"
                                                                    value={i.program_intake}
                                                                    required
                                                                >
                                                                    <option value="">Please Select</option>

                                                                    {program_intake_list.length > 0
                                                                        ? program_intake_list.map((e, key) => {
                                                                            return (
                                                                                <option
                                                                                    key={key}
                                                                                    value={e.month + " " + e.year}
                                                                                >
                                                                                    {e.month}{" "}
                                                                                </option>
                                                                            );
                                                                        })
                                                                        : ""}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {/* <div className=" btn-box">
                                                     {inputList.length !== 1 && (
                                                         <button className="mr10" onClick={() => handleRemoveClick(i)}>
                                                             Remove
                                                         </button>
                                                     )}
                                                     {inputList.length - 1 === i && (
                                                         <button className="Addbtn" onClick={handleAddClick}>Add</button>
                                                     )}
                                         </div> */}
                                                    </div>
                                                );
                                            })}

                                            <div className="row">
                                                <div className="set col-md-6">
                                                    <label
                                                        for="inputNumber"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Weeks
                                                    </label>
                                                    <div className="col-sm-19">
                                                        {program_intake_list.length > 0 ? (
                                                            <label style={{ background: "white", width: "100%", fontWeight: 500, color: "black" }}>{program_intake_list[0].year} </label>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="set col">
                                                    <label
                                                        for="inputNumber"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        Availability <span style={{ color: "red" }}>*</span><h6 style={{ color: "red" }}>{errors.shore}</h6>
                                                    </label>
                                                    <div className="col-sm-9">
                                                        Onshore{" "}
                                                        <input
                                                            type="radio"
                                                            name="shore"
                                                            value="Onshore"
                                                            onClick={handleChange}
                                                        />
                                                        Offshore{" "}
                                                        <input
                                                            type="radio"
                                                            name="shore"
                                                            value="Offshore"
                                                            onClick={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className=" set">
                                                        <label
                                                            for="inputNumber"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            Is this a pathway ?{" "}
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="radio"
                                                                name="pathway"
                                                                value="Pathway"
                                                                onClick={handleChange}
                                                            />
                                                            <p>Is this a pathway</p>
                                                        </div></div>
                                                </div>
                                                {state.pathway == "Pathway" && state.college_id == 36 || state.college_id == 41 ?
                                                    <div className="row">

                                                        <div className="set">
                                                            <label
                                                                for="inputNumber"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >Select The Pathway College</label>

                                                            <select
                                                                name="pathway_college"
                                                                className="col-form-label form-control"
                                                                onChange={handleChange}
                                                            >
                                                                {colleglist.length > 0
                                                                    ? colleglist.map((e, key) => {
                                                                        if (state.college_id == 36 && inputList[0].program_id == 206 && e.id == 41) {
                                                                            return <option key={key} value={e.id}>{e.college_name}</option>
                                                                        }
                                                                        else if (state.college_id == 41  && (inputList[0].program_id == 187 || inputList[0].program_id == 188) && e.id == 36)
                                                                        {
                                                                            return <option key={key} value={e.id}>{e.college_name}</option>
                                                                        }
                                                                    })
                                                                    : ""}
                                                            </select>

                                                        </div>
                                                        <div className="set">
                                                            <label
                                                                for="inputNumber"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >Course</label>
                                                            <select
                                                                name="pathway_course"
                                                                className="col-form-label form-control"
                                                                onChange={handleChange}
                                                            >   <option value="">Please Select</option>
                                                                {stateforpathwaycourselist.length > 0
                                                                    ? stateforpathwaycourselist.map((e, key) => {
                                                                        if (state.college_id == 36 && inputList[0].program_id == 206 && e.id == 187) {
                                                                            return <option key={key} value={e.id} selected>{e.course_name}</option>              
                                                                        }
                                                                        else if (state.college_id == 41 && (inputList[0].program_id == 187 || inputList[0].program_id == 188) && e.id == 206)
                                                                        {
                                                                            return <option key={key} value={e.id} selected>{e.course_name}</option>
                                                                        }
                                                                    })
                                                                    : ""}
                                                            </select>
                                                        </div>

                                                    </div>
                                                    : ""}
                                                {/* {state.college_id != 36 ? <div className="set col-md-6">
                        <label
                          for="inputNumber"
                          className={`col-sm-3 col-form-label ${addClass}`}
                        >
                          Is this a COE ?
                        </label>
                        <div className="col">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="pathway"
                            value="COE"
                            onClick={handleChange}
                          />
                          <p>Is this a COE</p>
                        </div>
                      </div> : ""} */}
                                            </div>

                                            {/* <div className="row"> */}

                                            {/* add the fields as PIA form */}
                                            {console.log("typeOfPassport", state.typeOfPassport)}
                                            {state.college_id == 61 ? <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}> Passport has only one set of names?</label>
                                                        <input type="radio" value="Yes - the passport only has given name(s)" name="typeOfPassport" onChange={handleChange} />Yes - the passport only has given name(s)<br></br>
                                                        <input type="radio" value="Yes - the passport only has family name(s)/surname(s)" name="typeOfPassport" onChange={handleChange} />Yes - the passport only has family name(s)/surname(s)<br></br>
                                                        <input type="radio" value="No - the passport has both given and family name(s)/surname(s)" name="typeOfPassport" onChange={handleChange} />No - the passport has both given and family name(s)/surname(s)<br></br>
                                                    </div>
                                                </div>
                                            </div> : ""}

                                            {/* type of application */}
                                            {state.college_id == 61 ? <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Type of Application</label>
                                                        <select
                                                            name="typeOfApplication"
                                                            value={state.typeOfApplication}
                                                            onChange={handleChange}
                                                            className="col-form-label form-control"
                                                        >
                                                            <option value="">Please Select</option>
                                                            <option value="Overseas student outside of Australia">Overseas student outside of Australia</option>
                                                            <option value="Overseas student in Australia">Overseas student in Australia</option>
                                                            <option value="Domestic Australian student">Domestic Australian student</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="set">
                                                        <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}> Australian Indigenous Person Status</label>
                                                        <select
                                                            name="australianindigenouspersonstatus"
                                                            value={state.australianindigenouspersonstatus}
                                                            onChange={handleChange}
                                                            className="col-form-label form-control">
                                                            <option value="">Please Select</option>
                                                            <option value="Yes, Aboriginal">Yes, Aboriginal</option>
                                                            <option value="Yes, Torres Strait Islander">Yes, Torres Strait Islander</option>
                                                            <option value="Yes, Aboriginal AND Torres Strait Islander">Yes, Aboriginal AND Torres Strait Islander</option>
                                                            <option value="No, Neither Aboriginal nor Torres Strait Islander">No, Neither Aboriginal nor Torres Strait Islander</option>
                                                            <option value="Not stated">Not stated</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> : ""}
                                            {state.college_id == 61 && state.typeOfApplication != "Domestic Australian student" ?
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="set">
                                                            <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Year of Arrival in Australia</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="yearArrivalAuustralia"
                                                                value={state.yearArrivalAuustralia}
                                                                onChange={handleChange}
                                                                placeholder="Year of Arrival in Australia"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                            {/* End type of application */}
                                            {/* end the fields as PIA form */}

                                            <button class="next add-course" onClick={() => setAddCourse(true)}>Add another course</button>

                                            <div className="col">
                                                <div className="set">
                                                    {inputList1.map((x, i) => {
                                                        return (
                                                            <div className="row">
                                                                <div key={i} className="set col-md-6">
                                                                    <label
                                                                        for="inputNumber"
                                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                                    >
                                                                        Course Name <h6 style={{ color: "red" }}>{errors.course}</h6>
                                                                    </label>
                                                                    {console.log("multiple_program_id", state.multiple_program_id)}
                                                                    <div className="col-sm-9">
                                                                        <select
                                                                            name="multiple_program_id"
                                                                            onChange={(e) => handleInputChange2(e, i)}
                                                                            className="col-form-label form-control select2"
                                                                            value={i.program_id}
                                                                            required
                                                                        >
                                                                            <option value="">Please Select</option>

                                                                            {courselist.length > 0
                                                                                ? courselist.map((e, key) => {
                                                                                    return (
                                                                                        <option key={key} value={e.id}>
                                                                                            {e.course_name}
                                                                                        </option>
                                                                                    );
                                                                                })
                                                                                : ""}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="set col-md-6">
                                                                    <label
                                                                        for="inputNumber"
                                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                                    >
                                                                        Program Intake
                                                                    </label>
                                                                    <div className="col-sm-9">
                                                                        <select
                                                                            name="multiple_program_intake"
                                                                            onChange={(e) => handleInputChange2(e, i)}
                                                                            className="col-form-label form-control"
                                                                            value={i.program_intake}
                                                                            required
                                                                        >
                                                                            <option value="">Please Select</option>

                                                                            {anotherForProgramIntakeList.length > 0
                                                                                ? anotherForProgramIntakeList.map((e, key) => {
                                                                                    return (
                                                                                        <option
                                                                                            key={key}
                                                                                            value={e.month + " " + e.year}
                                                                                        >
                                                                                            {e.month}{" "}
                                                                                        </option>
                                                                                    );
                                                                                })
                                                                                : ""}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                {/* <div className=" btn-box">
                                                     {inputList.length !== 1 && (
                                                         <button className="mr10" onClick={() => handleRemoveClick(i)}>
                                                             Remove
                                                         </button>
                                                     )}
                                                     {inputList.length - 1 === i && (
                                                         <button className="Addbtn" onClick={handleAddClick}>Add</button>
                                                     )}
                                         </div> */}
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputNumber"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            Weeks
                                                        </label>
                                                        <div className="col-sm-19">
                                                            {anotherForProgramIntakeList.length > 0 ? (
                                                                <label style={{ background: "white", width: "100%", fontWeight: 500, color: "black" }}>{anotherForProgramIntakeList[0].year} </label>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <input type="button" name="next" class="next action-button" value="Next" onClick={() => nextFunction(3, "next")} /> <input type="button" name="previous" class="previous action-button-previous" value="Previous" onClick={() => nextFunction(1, "previous")} />
                                    </fieldset>


                                    <fieldset style={{ display: page == 3 ? "block" : "none" }}>
                                        <div class="form-card">

                                            <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        {level === "Masters Degree (Coursework)" ? (
                                                            <Master sendDataToParent={sendDataToParent} color={addClass} errors={errors}></Master>
                                                        ) : level === "Certificate III" ||
                                                            level === "Certificate IV" ||
                                                            level === "Non AQF Award" ||
                                                            level === "Advanced Diploma" ||
                                                            level === "Diploma" ? (
                                                            <Dimploma
                                                                sendDataToParent={sendDataToParent}
                                                                color={addClass}
                                                                level={level}
                                                                errors={errors}
                                                            ></Dimploma>
                                                        ) : level === "Graduate Certificate" ||
                                                            level === "Bachelor Degree" ||
                                                            level === "Graduate Diploma" ? (
                                                            <Graduate
                                                                sendDataToParent={sendDataToParent}
                                                                color={addClass}
                                                                errors={errors}
                                                            ></Graduate>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>



                                            {/* <div className="row"> */}

                                            {/* <div className="card"> */}
                                            {/* <div className="card-body"> */}
                                            {/* <br/> */}
                                            {/* <br/> */}


                                            {/* <div className="set col-sm-6"> 
     <label>Upload  signature</label>
                                    <input type="file" onChange={handleChange_signature}  name="signature"
                                    className="form-control" /> </div> */}

                                            {/* 00000000 */}


                                            {/* </div>  */}


                                        </div>
                                        <input type="button" name="next" class="next action-button" value="Next" onClick={() => nextFunction(4, "next")} /> <input type="button" name="previous" class="previous action-button-previous" value="Previous" onClick={() => nextFunction(2, "previous")} />
                                    </fieldset>

                                    <fieldset style={{ display: page == 4 ? "block" : "none" }}>
                                        <div class="form-card">

                                            <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            English proficiency test type  <span style={{ color: "red" }}>*</span><h6>{errors.test_score}</h6>
                                                        </label>
                                                        <div className="col">
                                                            <select
                                                                className="form-control"
                                                                name="test_score"
                                                                id="test_scoredd"
                                                                value={state.test_score}
                                                                disabled={state.expemtion ? true : false}
                                                                onChange={handleChange}
                                                            >
                                                                <option value="">Please Select</option>
                                                                <option value="IELTS">IELTS</option>
                                                                <option value="PTE">PTE</option>
                                                                <option value="TOEFL">TOEFL</option>
                                                                <option value="OTHER">OTHER</option>
                                                            </select>

                                                            <div>
                                                                <input
                                                                    type="checkbox"
                                                                    value={state.expemtion}
                                                                    name="expemtion"
                                                                    id="expemtiom"
                                                                    onChange={handleChange}
                                                                />Apply Expemtion
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {!state.expemtion ? <div className="set col-md-6">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >

                                                        Upload Test{" "}
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            name="upload_ielts"
                                                            id="upload_ielts"
                                                            onChange={handleChange4}
                                                        />
                                                    </div>
                                                </div> : ""}
                                            </div>

                                            {state.college_id == 61 ? <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            {" "}
                                                            Copy of your English language qualification or provide details of your English proficiency{" "}
                                                        </label>
                                                        <div className="col">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="englishUploadDetails"
                                                                onChange={englishUploadDetailsFun}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            {" "}
                                                            Additional documents where applicable and as outlined by the course entrance requirements - eg. folio, personal statement{" "}
                                                        </label>
                                                        <div className="col">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="entranceRequirement"
                                                                onChange={entranceRequirementFun}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""}

                                            {state.college_id == 61 ? <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            {" "}
                                                            Documentation verifying name change - if your academic documents are under a previous name{" "}
                                                        </label>
                                                        <div className="col">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="documentPreviousName"
                                                                onChange={documentPreviousNameFun}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            {" "}
                                                            Statement of Purpose (SOP) Genuine Student (GS) and Genuine Temporary Entrant (GTE) Profile Applicant Form (Dependent/Single) Form{" "}
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="profileApplicantForm"
                                                                onChange={profileApplicantFormFun}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""}



                                            {!state.expemtion ? <div className="row">

                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            Overall Score  <span style={{ color: "red" }}>*</span><h6>{errors.overall_score}</h6>{" "}
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="overall_score"
                                                                value={state.overall_score}
                                                                onChange={handleChange}
                                                                placeholder="Overall Score"
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""}

                                            <div className="row">
                                                {!state.expemtion ?
                                                    <div className="col">
                                                        <div className="set">
                                                            <label
                                                                for="inputPassword"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >
                                                                Listing Score  <span style={{ color: "red" }}>*</span><h6> {errors.listing_score}</h6>
                                                            </label>
                                                            <div className="col">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="listing_score"
                                                                    value={state.listing_score}
                                                                    onChange={handleChange}
                                                                    placeholder="Listing Score"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}

                                                {!state.expemtion ?
                                                    <div className="col">
                                                        <div className="set">
                                                            <label
                                                                for="inputPassword"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >
                                                                Reading Score  <span style={{ color: "red" }}>*</span><h6>{errors.reading_score}</h6>
                                                            </label>
                                                            <div className="col">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="reading_score"
                                                                    value={state.reading_score}
                                                                    onChange={handleChange}
                                                                    placeholder="Reading Score"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>

                                            <div className="row">
                                                {!state.expemtion ?
                                                    <div className="col">
                                                        <div className="set">
                                                            <label
                                                                for="inputPassword"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >
                                                                Writing Score  <span style={{ color: "red" }}>*</span><h6>{errors.writing_score}</h6>
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="writing_score"
                                                                    value={state.writing_score}
                                                                    onChange={handleChange}
                                                                    placeholder="Writing Score"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {!state.expemtion ?
                                                    <div className="col">
                                                        <div className="set">
                                                            <label
                                                                for="inputPassword"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >
                                                                Speaking Score  <span style={{ color: "red" }}>*</span><h6>{errors.speaking_score}</h6>
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="speaking_score"
                                                                    value={state.speaking_score}
                                                                    onChange={handleChange}
                                                                    placeholder="Speaking Score"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>

                                            <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            Other Doc
                                                        </label>
                                                        <div className="col">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="other_doc"
                                                                onChange={handleChange1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {shore == true ? (
                                                <>
                                                    <div className="col">
                                                        <div className="set">
                                                            <label
                                                                for="inputPassword"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >
                                                                {" "}
                                                                Visa Number{" "}
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="visa_number"
                                                                    value={state.visa_number}
                                                                    onChange={handleChange}
                                                                    placeholder="Visa Number Must be betwwen 10 to 16 digit"
                                                                    minLength="10"
                                                                    maxLength="16"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="set">
                                                            <label
                                                                for="inputPassword"
                                                                className={`col-sm-3 col-form-label ${addClass}`}
                                                            >
                                                                Upload Visa details {" "}
                                                            </label>
                                                            <div className="col-sm-9">
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name="visadetails"
                                                                    onChange={visadetails}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="set row mb-3">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            Visa Expiry date
                                                        </label>
                                                        <div className="col">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="visaexpirydate"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="set row mb-3">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            {" "}
                                                            Upload overseas student health insurance policy certificate{" "}
                                                        </label>
                                                        <div className="col">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="policydetails"
                                                                onChange={policydetails}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="set row mb-3">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            Policy Expiry date
                                                        </label>
                                                        <div className="col">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="policyexpirydate"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                ""
                                            )}

                                            {state.shore == "Onshore" ? <div className="row">
                                                <div className="col">
                                                    <div className=" set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            Upload Passport
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="upload_passport"
                                                                onChange={handleChange3}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            {" "}
                                                            Passport issuing country/Nationality{" "} <span style={{ color: "red" }}>*</span>
                                                            <h6>{errors.passport_number}</h6>
                                                        </label>
                                                        <div className="col">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="passport_number"
                                                                value={state.passport_number}
                                                                onChange={handleChange}
                                                                placeholder="Which Country Passport"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""}
                                            {/* {state.shore == "Offshore" && state.typeOfApplication != "Domestic Australian student" ? <div className="row">
                                                <div className="set col">
                                                    <label
                                                        for="inputPassword"
                                                        className={`col-sm-3 col-form-label ${addClass}`}
                                                    >
                                                        {" "}
                                                        Passport Number <span style={{color: "red"}}>*</span><h6 style={{ color: "red" }}>{errors.passport_number}</h6>
                                                    </label>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="passport_number"
                                                            value={state.passport_number}
                                                            onChange={handleChange}
                                                            placeholder="Passport Number"
                                                        />
                                                    </div>
                                                </div>

                                            </div> : ""} */}
                                            {state.college_id == 61 ?
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="set">
                                                            <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Passport Expiry Date</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="passport_expiry_date"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                            {!state.expemtion ?
                                                <div className="col">
                                                    <div className="set">
                                                        <label
                                                            for="inputPassword"
                                                            className={`col-sm-3 col-form-label ${addClass}`}
                                                        >
                                                            Exam Date  <span style={{ color: "red" }}>*</span><h6 style={{ color: "red" }}>{errors.exam_date}</h6>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input type="date" className="form-control" name="exam_date" id="exam_date" value={state.exam_date} onChange={handleChange} />

                                                            {/* <input
                            className="form-control"
                            type="date"
                            id="dob"
                            value={selectedDate}
                            onChange={(e) => { setSelectedDate(e.target.value) }}
                          /> */}

                                                            {/* New dropdowns for day, month, and year */}
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}

                                            <div className="row">
                                                {state.college_id == 36 || state.college_id == 61 ?
                                                    <div className="col">
                                                        <div className="set">
                                                            <label className={addClass}> Advanced Standing/ Credit Transfer</label><br></br>
                                                            <input type="checkbox" name="credit_transfer_studies_completed" onChange={handleChange} value="Yes" />  Are you seeking advanced standing or  credit transfer for studies completed?
                                                            <p>(If yes, please provide a completed “Advanced Standing Application form” and submit it with this application)</p>
                                                            <p>(Note for Offshore)</p>
                                                        </div>
                                                    </div>
                                                    : ""}

                                                {state.college_id != 42 && state.college_id != 39 && state.college_id != 38 && state.college_id != 60 && state.college_id != 41 && state.college_id != 36 ? <div className="set col-md-6">
                                                    <label>2. Choose Your Campus Location:</label>

                                                    <p>
                                                        <input
                                                            type="radio"
                                                            name="campus_location"
                                                            value="Victoria"
                                                            onChange={handleChange}

                                                        />{" "}
                                                        Victoria
                                                    </p>

                                                    <p>
                                                        <input
                                                            type="radio"
                                                            name="campus_location"
                                                            value="New South Wales"
                                                            onChange={handleChange}

                                                        />{" "}
                                                        New South Wales
                                                    </p>
                                                </div> : ""}
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label className={addClass}>
                                                            International Student Visa Details Visa Type (Please
                                                            tick):
                                                        </label>

                                                        <p>
                                                            <input type="radio" name="visa_type" value="Student"
                                                                onChange={handleChange}
                                                            />
                                                            Student
                                                        </p>

                                                        <p>
                                                            <input type="radio" name="visa_type" value="Tourist" onChange={handleChange} />{" "}
                                                            Tourist
                                                        </p>
                                                        <p>
                                                            <input
                                                                type="radio"
                                                                name="visa_type"
                                                                value="Working holiday"
                                                                onChange={handleChange}

                                                            />{" "}
                                                            Working holiday
                                                        </p>
                                                        <p>
                                                            <input
                                                                type="radio"
                                                                name="visa_type"
                                                                value="Other Specify"
                                                                onChange={handleChange}

                                                            />{" "}
                                                            Other Specify:
                                                        </p>
                                                        <p>
                                                            <input
                                                                type="radio"
                                                                name="visa_type"
                                                                value="Year of Arrival 20"
                                                                onChange={handleChange}

                                                            />{" "}
                                                            Year of Arrival 20
                                                        </p>
                                                        <p>
                                                            <input
                                                                type="radio"
                                                                name="student_health_cover"
                                                                onChange={handleChange}

                                                                value="Yes"
                                                            />{" "}
                                                            Do you have an Overseas Student Health Cover (OSHC)? Yes
                                                            <input
                                                                type="radio"
                                                                name="student_health_cover_status"
                                                                value="Yes"
                                                                onChange={handleChange}
                                                            />
                                                            No
                                                            <input
                                                                type="radio"
                                                                name="student_health_cover_status"
                                                                value="No"
                                                                onChange={handleChange}

                                                            />
                                                            {state.student_health_cover_status == "No" && state.college_id == 61 ?
                                                                <p>Type of Cover<br></br>
                                                                    <input type="checkbox" name="typeOfCover" value={"Single"} onChange={handleChange} />Single
                                                                    <input type="checkbox" name="typeOfCover" value={"Couple"} onChange={handleChange} />Couple
                                                                </p>
                                                                : ""}
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            If yes, who is your provider?
                                                            <input type="text" name="your_provider" value={state.your_provider} onChange={handleChange}
                                                            />
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            Membership Number:
                                                            <input type="text" name="membership_number" value={state.membership_number} onChange={handleChange}
                                                            />
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            Expiry: / /(Please attach a copy of your membership
                                                            details)
                                                            <input
                                                                type="file"
                                                                name="membership_file"
                                                                onChange={handleChange_membership_file}
                                                            />
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            If not, would you like {collegedata.college_name} to apply on your behalf? Yes
                                                            <input
                                                                type="radio"
                                                                name="apply_your_behalf_status"
                                                                value="Yes"
                                                                onChange={handleChange}

                                                            />
                                                            No
                                                            <input
                                                                type="radio"
                                                                name="apply_your_behalf_status"
                                                                value="No"
                                                                onChange={handleChange}

                                                            />
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            {state.college_id != 42 ?
                                                                <>
                                                                    Student location at the time of Application: In Australia
                                                                    <input
                                                                        type="radio"
                                                                        name="student_location"
                                                                        value="in Australia"
                                                                        onChange={handleChange}

                                                                    /></> : ""}
                                                            Outside Australia
                                                            <input
                                                                type="radio"
                                                                name="student_location"
                                                                value="Outside Australia"
                                                                onChange={handleChange}

                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label className={addClass}>Language and Cultural Diversity:</label>
                                                        {state.college_id != 42 ?
                                                            <>
                                                                <p>
                                                                    In which country were you born?:{" "}
                                                                    <input type="text" name="born_country" value={state.born_country} onChange={handleChange} />
                                                                </p>
                                                                {state.college_id == 36 ? <p>
                                                                    Is English your first language?:{" "}
                                                                    <input type="radio" name="first_lang_english" value="Yes" onChange={handleChange} />Yes
                                                                    <input type="radio" name="first_lang_english" value="No" onChange={handleChange} /> No
                                                                </p> : ""}

                                                                <p>
                                                                    Do you speak a language other than English at home?: No
                                                                    <input type="radio" name="speak_language" value="No" onChange={handleChange} />
                                                                    Please Specify:
                                                                    <input
                                                                        type="radio"
                                                                        name="speak_language_specify_status"
                                                                        value="Yes"
                                                                        onChange={handleChange}
                                                                    />
                                                                    <input type="text" name="speak_language_specify" value={state.speak_language_specify} onChange={handleChange} />
                                                                </p>
                                                            </>
                                                            : ""}
                                                        <p>
                                                            {" "}
                                                            English Language proficiency Result (if available)
                                                            <input type="text" name="english_language_proficiency" value={state.english_language_proficiency} onChange={handleChange} />
                                                            IELTS
                                                            <input
                                                                type="radio"
                                                                name="english_language_proficiency_status"
                                                                value="IELTS"
                                                                onChange={handleChange}
                                                            />
                                                            PTE
                                                            <input
                                                                type="radio"
                                                                name="english_language_proficiency_status"
                                                                value="PTE"
                                                                onChange={handleChange}
                                                            />
                                                            CAE
                                                            <input
                                                                type="radio"
                                                                name="english_language_proficiency_status"
                                                                value="CAE"
                                                                onChange={handleChange}
                                                            />
                                                            TOEFL
                                                            <input
                                                                type="radio"
                                                                name="english_language_proficiency_status"
                                                                value="TOEFL"
                                                                onChange={handleChange}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* Only scm */}
                                            {state.college_id == 39 || state.college_id == 38 || state.college_id == 42 || state.college_id == 60 || state.college_id == 41 ? <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label className={addClass}>Schooling</label>
                                                        <p>What is your highest COMPLETED school level? (Tick ONE box only)</p>
                                                        <p>
                                                            <input type="radio" name="scmqualifications" value="Year 12 or equivalent" onChange={handleChange} /> Year 12 or equivalent
                                                        </p>
                                                        <p>
                                                            <input type="radio" name="scmqualifications" value="Year 11 or equivalent" onChange={handleChange} /> Year 11 or equivalent
                                                        </p>
                                                        <p>
                                                            <input type="radio" name="scmqualifications" value="Year 10 or equivalent" onChange={handleChange} /> Year 10 or equivalent
                                                        </p>
                                                        <p>
                                                            <input type="radio" name="scmqualifications" value="Year 9 or below" onChange={handleChange} /> Year 9 or below
                                                        </p>
                                                        <p>
                                                            <input type="radio" name="scmqualifications" value="Never attended school" onChange={handleChange} /> Never attended school
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> : ""}
                                            {/* scm */}
                                            {/* {PIA ADD THE FELD OF STUDY} */}
                                            {state.college_id == 61 ? <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Year completed Highest Education</label>
                                                        <input
                                                            type="text"
                                                            style={{ marginTop: "15px" }}
                                                            className="form-control"
                                                            name="yearOfCompletedEducation"
                                                            value={state.yearOfCompletedEducation}
                                                            onChange={handleChange}
                                                            placeholder="Year completed Highest Education"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="set">
                                                        <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Have you completed any education in Australia prior to this course you are applying for?</label>
                                                        <input
                                                            type="radio"
                                                            name="educationInAustralia"
                                                            value={"Not Stated"}
                                                            onChange={handleChange}
                                                        />Not Stated<br></br>
                                                        <input
                                                            type="radio"
                                                            name="educationInAustralia"
                                                            value={"No"}
                                                            onChange={handleChange}
                                                        />No<br></br>
                                                        <input
                                                            type="radio"
                                                            name="educationInAustralia"
                                                            value={"Yes"}
                                                            onChange={handleChange}
                                                        />Yes
                                                    </div>
                                                </div>
                                            </div> : ""}
                                            {state.college_id == 61 ? <div className="row">
                                                <div className="col">
                                                    <div className="set">
                                                        <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Unique Student Identifier (USI)</label>
                                                        <p>Don't have a USI? Apply here: <a href="https://www.usi.gov.au/">https://www.usi.gov.au/</a></p>
                                                        <input type="text" name="usi" onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="set">
                                                        <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Have you studied a course in Australia, or elsewhere, that is similar to the one for which you are applying for which you would like credit?</label>
                                                        <input type="radio" name="courseAustraliaCredit" value="Yes" onChange={handleChange} />Yes<br></br>
                                                        <input type="radio" name="courseAustraliaCredit" value="No" onChange={handleChange} />No
                                                    </div>
                                                </div>
                                            </div> : ""}

                                            {state.college_id == 61 ?
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="set">
                                                            <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Do you need to complete an English for Academics Purposes (EAP) program</label>
                                                            <input type="radio" name="academicPurposesProgram" value="Yes" onChange={handleChange} />Yes <br></br>
                                                            <input type="radio" name="academicPurposesProgram" value="No" onChange={handleChange} />No
                                                        </div>  </div>
                                                    <div className="col">
                                                        <div className="set">

                                                            <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Which EAP program to complete</label>
                                                            <input type="radio" name="EAPprogram" value={"EAP1"} onChange={handleChange} />EAP1<br></br>
                                                            <input type="radio" name="EAPprogram" value={"EAP2"} onChange={handleChange} />EAP2<br></br>
                                                        </div>  </div>
                                                </div> : ""}

                                            {state.college_id == 61 ?
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="set">
                                                            <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>Preferred starting date for EAP program</label>
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                name="startingDateEAPprogam"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="set">
                                                            <label for="inputPassword" className={`col-sm-3 col-form-label ${addClass}`}>EAP Program Duration (in weeks)</label>
                                                            <input
                                                                type="text"
                                                                name="EAPprogramWeeks"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}

                                            <div className="row">
                                                {state.educationInAustralia != "No" && state.educationInAustralia != "Not Stated" ? <div className="set col">
                                                    <label className={addClass}> {state.college_id == 39 || state.college_id == 38 || state.college_id == 60 || state.college_id == 41 || state.college_id == 61 ? "Previous Qualifications Achieved" : "Schooling"}:</label>
                                                    <p>
                                                        Which of the qualifications listed below have you
                                                        SUCCESSFULLY completed? (Please tick below)
                                                    </p>

                                                    {state.college_id == 36 ? <p>
                                                        <input type="radio" name="qualifications" value="Year 11" onChange={handleChange} /> Year 11
                                                    </p> : ""}
                                                    {state.college_id == 36 ? <p>
                                                        <input type="radio" name="qualifications" value="Year 12" onChange={handleChange} /> Year 12
                                                    </p> : ""}
                                                    {state.college_id == 36 ? <p>
                                                        <input type="radio" name="qualifications" value="Diploma" onChange={handleChange} /> Diploma
                                                    </p> : ""}
                                                    {state.college_id == 36 ? <p>
                                                        <input type="radio" name="qualifications" value="Associate Diploma" onChange={handleChange} /> Associate Diploma
                                                    </p> : ""}
                                                    {state.college_id == 36 ? <p>
                                                        <input type="radio" name="qualifications" value="Postgraduate Degree" onChange={handleChange} /> Postgraduate Degree
                                                    </p> : ""}

                                                    <p>
                                                        <input type="radio" name="qualifications" value="Certificate III" onChange={handleChange} /> Certificate
                                                        III (or trade certificate)
                                                    </p>
                                                    <p>
                                                        <input type="radio" name="qualifications" value="Certificate IV" onChange={handleChange} /> Certificate
                                                        IV (or advanced certificate/technician)
                                                    </p>
                                                    <p>
                                                        <input type="radio" name="qualifications" value="Certificate II" onChange={handleChange} /> Certificate
                                                        II
                                                    </p>
                                                    <p>
                                                        <input type="radio" name="qualifications" value="Certificate I" onChange={handleChange} /> Certificate I
                                                    </p>
                                                    <p>
                                                        <input type="radio" name="qualifications" value="Certificate IV" onChange={handleChange} /> Certificate
                                                        IV (or advanced certificate/technician)
                                                    </p>
                                                    <p>
                                                        <input type="radio" name="qualifications" value="Advanced diploma or associate degree" onChange={handleChange} /> Advanced
                                                        diploma or associate degree
                                                    </p>
                                                    <p>
                                                        <input type="radio" name="qualifications" value="Bachelor degree or higher degree" onChange={handleChange} /> Bachelor
                                                        degree or higher degree
                                                    </p>
                                                    <p>
                                                        <input type="radio" name="qualifications" value="other" onChange={handleChange} /> Other
                                                        education (including certificates or
                                                        <input type="text" name="qualifications" value={state.qualifications} onChange={handleChange} />
                                                    </p>
                                                </div> : ""}

                                                {state.college_id != 36 && state.college_id != 61 ? <div className="set col">
                                                    <label className={addClass}>
                                                        Recognition of Prior Learning & Credit Transfer:{" "}
                                                    </label>
                                                    <p>
                                                        <input
                                                            type="radio"
                                                            name="prior_Learning_status"
                                                            value="other"
                                                            onChange={handleChange}
                                                        />
                                                        Other
                                                    </p>

                                                    <p>
                                                        I wish to apply for RPL:{" "}
                                                        <input type="radio" name="rpl_apply_status" value="Yes" onChange={handleChange}
                                                        />
                                                        YES
                                                        <input type="radio" name="rpl_apply_status" value="No" onChange={handleChange}
                                                        />
                                                        No
                                                    </p>
                                                    {state.college_id != 38 ? <p>
                                                        I have attached my RPL Application Form:{" "}
                                                        <input type="radio" name="rpl_application_status" value="Yes" onChange={handleChange} />
                                                        YES
                                                        <input type="radio" name="rpl_application_status" value="No" onChange={handleChange} />
                                                        No
                                                    </p> : ""}
                                                    <p>
                                                        I wish to apply for Credit Transfer:{" "}
                                                        <input type="radio" name="credit_transfer_apply_status" value="Yes" onChange={handleChange} />
                                                        YES
                                                        <input type="radio" name="credit_transfer_apply_status" value="No" onChange={handleChange} />
                                                        No
                                                    </p>
                                                    {state.college_id != 38 ? <p>
                                                        I have attached my Credit Tra<div className="row">
                                                            <div className="set col-md-12">
                                                                <label className={addClass}> Choose Your Course</label>
                                                                <p>
                                                                    Please note that application fees and material fees for
                                                                    all courses are non-refundabl
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="set col-md-12">
                                                                <label className={addClass}> {programdata.course_name}</label>
                                                                <p>
                                                                    Please note that application fees and material fees for
                                                                    all courses are non-refundabl
                                                                </p>

                                                                <p>
                                                                    {" "}
                                                                    CHOOSE YOUR QUALIFCATION
                                                                    <input type="radio" name="" />
                                                                    {programdata.cor_id} {programdata.course_name}
                                                                </p>
                                                                <p>CRICOS CODE : {collegedata.college_unique_id}</p>
                                                                <p>   {program_intake_list.length > 0 ? (
                                                                    <p> DURATION : {program_intake_list[0].year} </p>
                                                                ) : (
                                                                    ""
                                                                )} weeks</p>
                                                                <p>TOTAL FEES : ${programdata.total_fee}</p>
                                                                <p>
                                                                    Fees include Material and Application Fees (${programdata.application_fee}
                                                                    Application fee + $750 Materials Fee non-refundable) :{" "}
                                                                    <input type="radio" name="" /> Payment as per study
                                                                    periods* $3000 x 4 + (${programdata.application_fee} Application fee + $750
                                                                    Materials Fee non-refundable)
                                                                </p>
                                                                <p>
                                                                    <h5>Course Entry Requirements NIL</h5> :
                                                                    <h5>  {collegedata.college_name} College Student Entry Requirements</h5>
                                                                    Successful completion of year 12 or equivalent. Successful
                                                                    completion of Determining Suitability Assessment 18 years
                                                                    of age or above. Any enrolment specific requirements or
                                                                    conditions are detailed on the 1st page of Letter of
                                                                    Offer. Minimum English level of IELTS 5.5 or equivalent
                                                                    (International Student) or a  {collegedata.college_name} College Placement Test
                                                                    *These entry requirements are in addition to Course Entry
                                                                    Requirements
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="set col-md-12">
                                                                <label className={addClass}> Payment Details</label>
                                                                <p>
                                                                    {/* <input type="radio" name=""/>  */}
                                                                    Payment Options prior to commencement:
                                                                </p>
                                                                <p>
                                                                    Full
                                                                    <input type="radio" name="payment_option" value="Full"
                                                                        onChange={handleChange} />
                                                                </p>
                                                                <p>
                                                                    Half
                                                                    <input type="radio" name="payment_option" value="Half" onChange={handleChange} />
                                                                </p>
                                                                <p>
                                                                    Study period* <input type="radio" name="study_period" value="Study period" onChange={handleChange} />
                                                                </p>
                                                                <p>
                                                                    Payment plan
                                                                    <input type="radio" name="payment_plan" value="Payment Plan" onChange={handleChange} />
                                                                    qualification/s details:
                                                                    <input type="text" name="qualification_details" value={state.qualification_details} onChange={handleChange} />
                                                                </p>
                                                            </div>

                                                        </div>nsfer Application Form:
                                                        <input
                                                            type="radio"
                                                            name="credit_transfer_application_satus"
                                                            value="Yes"
                                                            onChange={handleChange}
                                                        />
                                                        YES
                                                        <input
                                                            type="radio"
                                                            name="credit_transfer_application_satus"
                                                            value="No"
                                                            onChange={handleChange}
                                                        />
                                                        No
                                                    </p> : ""}
                                                </div> : <div className="set col-md-6">
                                                    <label className={addClass}> Admission Checklist</label>
                                                    <p>Check that you have:</p><br></br>
                                                    <input type="checkbox" value="Completed_all_sectionsof the application form" name="admission" onChange={handleChange} />  Completed all sections of the application form<br />
                                                    <input type="checkbox" value="CoRead and understood the Conditions of Enrolment and Refund Policy" name="admission1" onChange={handleChange} />  CoRead and understood the Conditions of Enrolment and Refund Policy<br />
                                                    <input type="checkbox" value="Your academic qualifications" name="admission2" onChange={handleChange} />  Your academic qualifications<br />
                                                    <input type="checkbox" value="Evidence of your English language proficiency" name="admission3" onChange={handleChange} />  Evidence of your English language proficiency (if required)<br />
                                                    <input type="checkbox" value="Certified copy of your passport or birth certificate and certified copy of your visa" onChange={handleChange} name="admission4" />  Certified copy of your passport or birth certificate and certified copy of your visa<br />
                                                    <input type="checkbox" value="Any relevant employment documentation" name="admission5" onChange={handleChange} />  Any relevant employment documentation<br />
                                                    <input type="checkbox" value="Certified copy of your Overseas Student Health Cover (OSHC)" name="admission6" onChange={handleChange} />  Certified copy of your Overseas Student Health Cover (OSHC)
                                                </div>
                                                }
                                            </div>
                                            {state.college_id != 36 ? <div className="row">
                                                <div className="set col-md-12">
                                                    <label className={addClass}> Payment Details</label>
                                                    <p>
                                                        {/* <input type="radio" name=""/>  */}
                                                        Payment Options prior to commencement:
                                                    </p>
                                                    <p>
                                                        Full
                                                        <input type="radio" name="payment_option" value="Full"
                                                            onChange={handleChange} />
                                                    </p>
                                                    <p>
                                                        Half
                                                        <input type="radio" name="payment_option" value="Half" onChange={handleChange} />
                                                    </p>
                                                    <p>
                                                        Study period* <input type="radio" name="study_period" value="Study period" onChange={handleChange} />
                                                    </p>
                                                    <p>
                                                        Payment plan
                                                        <input type="radio" name="payment_plan" value="Payment Plan" onChange={handleChange} />
                                                        qualification/s details:
                                                        <input type="text" name="qualification_details" value={state.qualification_details} onChange={handleChange} />
                                                    </p>
                                                </div>

                                                {/* <div className="set ">
                    <p>
                      *Please note: Study period refers to the number of terms
                      within the delivery of a course. If you have chosen this
                      option, payment is due at the commencement of the study
                      period.
                    </p>
                  </div> */}

                                                {/* <div className="set">
                    <p>
                      <input type="radio" name="intakes_months_study"  value="Jan" onChange={handleChange}/>
                      Jan |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="Feb" onChange={handleChange}/>
                      Feb |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="Mar" onChange={handleChange}/>
                      Mar |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="Apr" onChange={handleChange}/>
                      Apr |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="May" onChange={handleChange}/>
                      May |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="Jun" onChange={handleChange}/>
                      Jun |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="July" onChange={handleChange}/>
                      July |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="Aug" onChange={handleChange}/>
                      Aug |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="Sep" onChange={handleChange}/>
                      Sep |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="Oct" onChange={handleChange}/>
                      Oct |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="Nov" onChange={handleChange}/>
                      Nov |
                    </p>
                    <p>
                      <input type="radio" name="intakes_months_study"  value="dec" onChange={handleChange}/>
                       |
                    dec |
                    </p>
                    <p>
                      <input type="radio" name="intakes_year_study"  value="2023" onChange={handleChange}/>
                      2023 |
                    </p>
                    <p>
                      <input type="radio" name="intakes_year_study"  value="2024" onChange={handleChange}/>
                      2024 |
                    </p>
                    <p>
                      <input type="radio" name="intakes_year_study"  value="2025" onChange={handleChange}/>
                      2025 |
                    </p>
                  </div> */}
                                            </div> : ""}

                                            <div className="row">
                                                <div className="set col-md-6">
                                                    <label className={addClass}>               Reason of Study:</label>
                                                    <p>
                                                        Of the following categories, select the one which BEST
                                                        describes the main reason you are undertaking this course
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="To get a
                      Job" onChange={handleChange} /> To get a
                                                        Job
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="To develop
                      my existing business" onChange={handleChange} /> To develop
                                                        my existing business
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="To start my
                      own business" onChange={handleChange} /> To start my
                                                        own business
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="To try for a
                      different career" onChange={handleChange} /> To try for a
                                                        different career
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="To get a
                      better Job or Promotion" onChange={handleChange} /> To get a
                                                        better Job or Promotion
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="It is a
                      requirement of my current job" onChange={handleChange} /> It is a
                                                        requirement of my current job
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="I wanted
                      extra skills for my job" onChange={handleChange} /> I wanted
                                                        extra skills for my job
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="To get into
                      another course of study" onChange={handleChange} /> To get into
                                                        another course of study
                                                    </p>

                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="For personal
                      interest or self-development" onChange={handleChange} /> For personal
                                                        interest or self-development
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="reason_of_study" value="Other
                      reasons" onChange={handleChange} /> Other
                                                        reasons
                                                    </p>
                                                </div>

                                                <div className="set col-md-6">
                                                    <label className={addClass}> Employment:</label>
                                                    <p>
                                                        Of the following categories, which BEST describes your
                                                        current employment status?
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="employment_status" value="Full-time
                      employee" onChange={handleChange} /> Full-time
                                                        employee
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="employment_status" value="Part-time
                      employee" onChange={handleChange} /> Part-time
                                                        employee
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="employment_status" value="Self-employed not employing others" onChange={handleChange} />{" "}
                                                        Self-employed – not employing others
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="employment_status" value="Self-employed employing others" onChange={handleChange} />{" "}
                                                        Self-employed – employing others
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="employment_status" value="Employed-unpaid worker in a family business" onChange={handleChange} /> Employed –
                                                        unpaid worker in a family business
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="employment_status" value="Unemployed-seeking full-time work" onChange={handleChange} /> Unemployed
                                                        – seeking full-time work
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="employment_status" value="Unemployed-seeking part-time work" onChange={handleChange} /> Unemployed
                                                        – seeking part-time work
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        <input type="radio" name="employment_status" value="Not employed-not seeking employment" onChange={handleChange} /> Not
                                                        employed – not seeking employment
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="button" name="next" class="next action-button" value="Next" onClick={() => nextFunction(5, "next")} /> <input onClick={() => nextFunction(3, "previous")} type="button" name="previous" class="previous action-button-previous" value="Previous" />
                                    </fieldset>

                                    <fieldset style={{ display: page == 5 ? "block" : "none" }}>
                                        <div class="form-card">

                                            {state.college_id != 36 ? <div className="row">
                                                <div className="set col-md-12">
                                                    <label className={addClass}> Choose Your Course</label>
                                                    <p>
                                                        Please note that application fees and material fees for
                                                        all courses are non-refundabl
                                                    </p>
                                                </div>
                                            </div> : ""}
                                            {state.college_id != 36 ? <div className="row">
                                                <div className="set col-md-12">
                                                    <label className={addClass}> {programdata.course_name}</label>
                                                    <p>
                                                        Please note that application fees and material fees for
                                                        all courses are non-refundabl
                                                    </p>

                                                    <p>
                                                        {" "}
                                                        CHOOSE YOUR QUALIFCATION
                                                        <input type="radio" name="" />
                                                        {programdata.cor_id} {programdata.course_name}
                                                    </p>
                                                    <p>CRICOS CODE : {collegedata.college_unique_id}</p>
                                                    <p>   {program_intake_list.length > 0 ? (
                                                        <p> DURATION : {program_intake_list[0].year} </p>
                                                    ) : (
                                                        ""
                                                    )} weeks</p>
                                                    <p>TOTAL FEES : ${programdata.total_fee}</p>
                                                    <p>
                                                        Fees include Material and Application Fees (${programdata.application_fee}
                                                        Application fee + $750 Materials Fee non-refundable) :{" "}
                                                        <input type="radio" name="" /> Payment as per study
                                                        periods* $3000 x 4 + (${programdata.application_fee} Application fee + $750
                                                        Materials Fee non-refundable)
                                                    </p>
                                                    <p>
                                                        <h5>Course Entry Requirements NIL</h5> :
                                                        <h5>  {collegedata.college_name} College Student Entry Requirements</h5>
                                                        Successful completion of year 12 or equivalent. Successful
                                                        completion of Determining Suitability Assessment 18 years
                                                        of age or above. Any enrolment specific requirements or
                                                        conditions are detailed on the 1st page of Letter of
                                                        Offer. Minimum English level of IELTS 5.5 or equivalent
                                                        (International Student) or a  {collegedata.college_name} College Placement Test
                                                        *These entry requirements are in addition to Course Entry
                                                        Requirements
                                                    </p>
                                                </div>
                                            </div> : ""}


                                            <div className="row">
                                                <div className="set col-md-12">
                                                    <label className={addClass}> Unique Student Identifier (USI):</label>
                                                    <p>
                                                        From 1 January 2015, we – <h5> {collegedata.college_name} College</h5>, can be
                                                        prevented from issuing you with a nationally recognised
                                                        VET qualification or statement of attainment when you
                                                        complete your course if you do not have a Unique Student
                                                        Identifier (USI). In addition, we are required to include
                                                        your USI in the data we submit to NCVER. If you have not
                                                        yet obtained a USI you can apply for it directly at
                                                        http://www.usi.gov.au/create-your-USI/ on computer or
                                                        mobile device. Please note that if you would like to
                                                        specify your gender as ‘other’ you will need to contact
                                                        the USI Office for assistance.
                                                        <h5>
                                                            Enter your Unique Student Identifier (USI) (if you
                                                            already have one)
                                                        </h5>
                                                        You may already have a USI if you have done any nationally
                                                        recognised training, which could include training at work,
                                                        completing a first aid course or RSA (Responsible Service
                                                        of Alcohol) course, getting a white card, or studying at a
                                                        TAFE or training organisation. It is important that you
                                                        try to find out whether you already have a USI before
                                                        attemptingto create a new one. You should not have more
                                                        than one USI. To check if you already have a USI, use the
                                                        ‘Forgotten USI’ link on the USI website at
                                                        https://www.usi.gov.au/faws/i-have-forgotten-my-usi/
                                                        <h5>
                                                            {" "}
                                                            If you do not have a USI, you can apply at
                                                            www.usi.gov.au  {collegedata.college_name} College can apply to the Student
                                                            Identifiers registrar on your behalf for a USI. You will
                                                            be required to complete the Unique Student Identifier
                                                            Application Form
                                                        </h5>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="set">
                                                <label className={addClass}> Other Payable Fees</label>
                                                <div className="row">
                                                    <h5> Document</h5>
                                                    <p> Re-Issue of Testamurs / Statements of attainment</p>
                                                    <p> Reassessment Fee</p>
                                                    <p> RPL Fee</p>
                                                    <p> Re-Issue of Student Card</p>
                                                    <p> Late payment of semester fees</p>
                                                    <p>Change to CoE</p>
                                                    <p>Unit re-enrolment fee</p>
                                                </div>
                                                <div className="row">
                                                    <h5> Cost</h5>
                                                    <p> $50 each copy</p>
                                                    <p>$220.00 (after third attempt)</p>
                                                    <p> $250.00 per unit</p>
                                                    <p> $20.00</p>
                                                    <p> $250.00 per instalment</p>
                                                    <p>
                                                        {" "}
                                                        $200.00 Not applicable where support has been provided to
                                                        achieve learning outcomes.
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        $500.00 (for students who exceed the maximum
                                                        durationperiod)
                                                    </p>
                                                </div>

                                                <div className="set">
                                                    <label className={addClass}>Privacy Statement</label>
                                                    <p>
                                                        Under the Data Provision Requirements 2012,  {collegedata.college_name} College
                                                        Pty Ltd is required to collect personal information about
                                                        you and to disclose that personal information to the
                                                        National Centre for Vocational Education Research Ltd
                                                        (NCVER). Your personal information (including the personal
                                                        information contained on this enrolment form), may be used
                                                        or disclosed by  {collegedata.college_name} College Pty Ltd for statistical,
                                                        administrative, regulatory and research purposes.  {collegedata.college_name}
                                                        College Pty Ltd may disclose your personal information for
                                                        these purposes to: Commonwealth and State or Territory
                                                        government departments and authorised agencies; and NCVER.
                                                        Personal information that has been disclosed to NCVER may
                                                        be used or disclosed by NCVER for the following purposes:
                                                        populating authenticated VET transcripts; facilitating
                                                        statistics and research relating to education, including
                                                        surveys and data linkage; pre-populating RTO student
                                                        enrolment forms; pre-populating RTO student enrolment
                                                        forms; understanding how the VET market operates, for
                                                        policy, workforce planning and consumer information; and
                                                        administering VET, including program administration,
                                                        regulation, monitoring and evaluation You may receive a
                                                        student survey which may be administered by a government
                                                        department or NCVER employee, agent or third-party
                                                        contractor or other authorised agencies. Please note you
                                                        may opt out of the survey at the time of being contacted.
                                                        NCVER will collect, hold, use and disclose your personal
                                                        information in accordance with the Priva
                                                    </p>
                                                </div>

                                                <div className="set">
                                                    <label className={addClass}>  Student Agreement and Declaration</label>
                                                    <p>In signing this Application Form,</p>
                                                    <p>
                                                        1. I confirm I have been provided the necessary
                                                        information to make an informed choice into the
                                                        qualification/s for which I am applying including, entry
                                                        requirements,
                                                    </p>
                                                    course duration, modes of study and assessment requirements
                                                    <p>
                                                        2. I have a genuine interest in studying the course, and I
                                                        have the financial capacity to meet tuition fees, living
                                                        expenses, travel expenses, Overseas Student Health Cover
                                                        for the duration of my studies.
                                                    </p>
                                                    <p>
                                                        3. I understand that I can pay full fees if I wish to, but
                                                        I am not required to pay more than 50 percent up front. I
                                                        also agree to pay tuition fees as they become due.
                                                    </p>
                                                    <p>
                                                        4. I understand that the application fee is payable at the
                                                        time of lodging the application form and is
                                                        non-refundable. 5. I understand that the material fee is
                                                        payable upon commencement and is non-refundable.
                                                    </p>
                                                    <p>
                                                        6. I can view current policies and procedures online at
                                                        www.lennoxcollege.edu.au
                                                    </p>
                                                    <p>
                                                        7. The college has permission to use any photographic
                                                        image on which you may appear on marketing and advertising
                                                        materials;
                                                    </p>
                                                    <p>
                                                        8.  {collegedata.college_name} College Pty Ltd is required, under s19 of the
                                                        ESOS Act, to tell the Department about changes to
                                                        student's enrolment; and any breach by students of student
                                                        visa conditions relating to attendance or satisfactory
                                                        academic performance;
                                                    </p>
                                                    <p>
                                                        9. I understand that  {collegedata.college_name} College Pty Ltd has access to
                                                        my personal information for the purpose of verifying the
                                                        USI number.
                                                    </p>
                                                    <p>
                                                        10. As an overseas student or intending overseas student,
                                                        while in Australia and studying, I must notify  {collegedata.college_name}
                                                        College Pty Ltd within 7 days of any changes to my contact
                                                        details including: my current residential address, mobile
                                                        number (if any) and email address (if any) who to contact
                                                        in emergency situations any changes to those details,
                                                        within 7 days of the change being made
                                                    </p>
                                                    <p>
                                                        11. I declare that the information I have provided to the
                                                        best of my knowledge is true and correct.
                                                    </p>
                                                    <p>
                                                        12. I acknowledge that providing false information and /or
                                                        failing to disclose any information relevant to my
                                                        application for enrolment and /or failure to complete an
                                                        application form may result in the withdrawal of any offer
                                                    </p>
                                                    <p>
                                                        13. I consent to the collection, use and disclosure of my
                                                        personal information in accordance with the Privacy Notice
                                                        above.
                                                    </p>
                                                </div>
                                                <div className="set">
                                                    <label className={addClass}>Student Name : {state.first_name} {state.last_name}</label>
                                                </div>
                                                {previewupload == true ?
                                                    <div className="set" style={{ display: "none" }}>
                                                        <label className={addClass}>Student Signature : </label>
                                                        <input type="file" ref={inputRef}
                                                            onChange={handleChange_signature} id="uploadss" name="signature"
                                                            className="form-control" />
                                                    </div>
                                                    : ""}
                                                <div className="set">
                                                    <label className={addClass}> Date:</label>
                                                </div>
                                                {state.college_id != 42 && state.college_id != 39 && state.college_id != 60 && state.college_id != 36 && state.college_id != 61 ? <div className="set">
                                                    <label className={addClass}>OFFICE USE ONLY</label>
                                                    <p>
                                                        Documents attached: Yes
                                                        <input type="radio" value="Yes" name="document_attached" onChange={handleChange} />
                                                        No
                                                        <input type="radio" value="No" name="document_attached" onChange={handleChange} />
                                                        Staff Initial
                                                        <input type="radio" name="document_attached" />
                                                        ____________
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        Passport and Visa:

                                                        Yes
                                                        <input type="radio" value="Yes" name="passport_and_visa" onChange={handleChange} />

                                                        No
                                                        <input type="radio" value="No" name="passport_and_visa" onChange={handleChange} />

                                                        Staff Initial
                                                        <input type="radio" name="passport_and_visa" />
                                                        ____________
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        IELTS or Equivalent (or Australian AQF:
                                                        <p>
                                                            Yes
                                                            <input type="radio" value="Yes" name="itelsorEquivalent" onChange={handleChange} />
                                                        </p>
                                                        <p>
                                                            No
                                                            <input type="radio" value="No" name="itelsorEquivalent" onChange={handleChange} />
                                                        </p>
                                                        <p>
                                                            Staff Initial
                                                            <input type="radio" name="itelsorEquivalent" />
                                                        </p>
                                                        ____________
                                                    </p>
                                                    {state.college_id != 38 ? <p>
                                                        {" "}
                                                        Qualification IV or above:
                                                        <p>
                                                            Yes
                                                            <input type="radio" value="Yes" name="qualificationfourthabove" onChange={handleChange} />
                                                        </p>
                                                        <p>
                                                            No
                                                            <input type="radio" value="No" name="qualificationfourthabove" onChange={handleChange} />
                                                        </p>
                                                        <p>
                                                            Staff Initial
                                                            <input type="radio" name="qualificationfourthabove" onChange={handleChange} />
                                                        </p>
                                                        ____________
                                                    </p> : ""}
                                                    <p>
                                                        {" "}
                                                        High School Certificate:
                                                        <p>
                                                            Yes
                                                            <input type="radio" value="Yes" name="highschoolcertificate" onChange={handleChange} />
                                                        </p>
                                                        <p>
                                                            No
                                                            <input type="radio" value="No" name="highschoolcertificate" onChange={handleChange} />
                                                        </p>
                                                        <p>
                                                            Staff Initial
                                                            <input type="radio" name="highschoolcertificate" />
                                                        </p>
                                                        ____________
                                                    </p>
                                                    {state.college_id == 38 ? <p>
                                                        {" "}
                                                        AETA’s Determining Suitability Assessment:
                                                        <p>
                                                            Yes
                                                            <input type="radio" value="Yes" name="AETA" onChange={handleChange} />
                                                        </p>
                                                        <p>
                                                            No
                                                            <input type="radio" value="No" name="AETA" onChange={handleChange} />
                                                        </p>
                                                        <p>
                                                            Staff Initial
                                                            <input type="radio" name="AETA" />
                                                        </p>
                                                        ____________
                                                    </p> : ""}
                                                </div>

                                                    : ""}
                                            </div>

                                            <div className="row mb-3">
                                                <div className="col-sm-10">
                                                    <input onClick={() => nextFunction(4, "previous")} type="button" name="previous" class="previous action-button-previous" value="Previous" />
                                                    {preview == true ?
                                                        <button type="button" onClick={() => themainfunction("block")} className="next action-button">
                                                            Save & Preview
                                                        </button>
                                                        : ''}
                                                    {"  "}
                                                    {previewupload == true ?
                                                        <button type="button" onClick={handleClick} className="next action-button" disabled={disbaledButton}>
                                                            Upload Signature
                                                        </button>
                                                        : ''}
                                                    {"  "}
                                                    {signature != '' ?
                                                        <button type="submit" onClick={() => settyspe_pdf('')} className="next action-button">
                                                            Submit Application
                                                        </button>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>

                                    </fieldset>



                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

            {/* <Forgotpasswordlogin /> */}
            <Footer />
        </>
    );
}
export default Addapplication;
