
// import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
// import ReactDOM from 'react-dom';
// import { useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Loader from '../loader/Loader';
import Network from '../Network/Network';


function ProgramList() {

    const [programlistdata, setstateforprogram] = useState([]);
    const userid = localStorage.getItem('token-info');

    useEffect(() => { sendDetailsToServer(); }, []);

    const sendDetailsToServer = async () => {
        var formdata = new FormData();
        formdata.append('userid', userid);
        axios.post(Network.Network.api_url+"program_list",formdata)
            .then(function (response) {
                setstateforprogram(response.data.data)
            })
    }
    setTimeout(function(){
        $('.myTable').DataTable();
         } ,2000);
    return (<>
        <Header />
        <Sidebar />
        <main id="main" className="main">
            <section className="section Applicationmain"><div className="row"><div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Programs list</h5>
                       
                            

                <table className="table datatable dataTable-table myTable table-responsive">
                    <thead>
                        <tr>
                            <th scope="col"> Id</th>
                            <th scope="col">Course Name</th>
                            <th scope="col">Duration</th>
                            <th scope="col" >Program Level</th>
                            <th scope="col" >Application Fee</th>
                            <th scope="col" >Tuition Fee</th>
                            <th scope="col">Total Fee</th>
                            <th scope="col" >Website Url</th>
                            <th scope="col" >Date</th>

                        </tr>

                    </thead>
                    <tbody>
                  

                        {programlistdata.length > 0 ? 

                       programlistdata.map((items, i) => {
                                return (
                                    <tr key={items}>
                                        <td>{i + 1}</td>
                                        <td>{items.course_name}</td>
                                        <td>
                                              <span>weeks-{items.intake.year} </span>
                                              <span>{ items.intake.month.toString()}</span>
                                        </td>
                                        <td>{items.program_level}</td>
                                        <td>{items.application_fee}</td>
                                        <td>{items.tuition_fee}</td>
                                        <td>{items.total_fee}</td>
                                        <td>{items.website_url}</td>
                                        <td>{items.created_at}</td>

                                    </tr>
                                )
                            })

                         : <Loader></Loader>}
                        

                    </tbody>
                </table>

            
                </div>
                </div>
                </div>
                </div>
                </section></main>
        <Footer />
    </>)
}

export default ProgramList;
