import React, { useState } from 'react'
export default function Decline() {
 const [formvalue,setformvalue]=useState();    
 const onchangevalue=(e)=>{

    setformvalue(e.target.value);
           
 }

 const onsubmitreason=()=>{
     alert('yes')
 }


  return (
    <div>

</div>
  )
}
