import React from 'react'

export default function Master({ sendDataToParent, color, errors }) {

    return (<>
        <div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>upload class 10th or equivalent academic transcript </label>
            <div className="col-sm-9">

                <input type="file" accept=".pdf" className="form-control" name="tenth_doc" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'tenth_doc');
                }} />
            </div>
        </div>
        <div className='row'>
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Board of Education</label>
            <div className='col'>
                <input type="text" className="form-control" name="boardOfEducation" placeholder='Board of Education' onChange={(e) => {
                    sendDataToParent(e.target.value, "tenth_board_of_education");
                }} />
            </div>
        </div>
        <div className='row'>
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Year of Pass</label>
            <div className='col'>
                <input type="text" className="form-control" name="yearOfPass" placeholder='Year of Pass' onChange={(e) => {
                    sendDataToParent(e.target.value, "tenth_year_of_pass");
                }} />
            </div>
        </div>
        <div className='row'>
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Country</label>
            <div className='col'>
                <input type="text" className="form-control" name="boardOfCountry" placeholder='Country' onChange={(e) => {
                    sendDataToParent(e.target.value, "tenth_country");
                }} />
            </div>
        </div>
        <div className='row'>
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Best of 4 Core Subject % Upon</label>
            <div className='col'>
                <input type="text" className="form-control" name="bestOfCoreSubject" placeholder='Best of 4 Core Subject % Upon' onChange={(e) => {
                    sendDataToParent(e.target.value, "tenth_4_core_subject");
                }} />
            </div>
        </div>
        <div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}> Upload class 12th or equivalent academic transcript </label>
            <div className="col-sm-9">
                <input accept=".pdf" type="file" className="form-control" name="twelve_doc" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'twelve_doc');
                }} />
            </div>
        </div>

        <div className='row'>
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Board of Education</label>
            <div className='col'>
                <input type="text" className="form-control" name="boardOfEducation" placeholder='Board of Education' onChange={(e) => {
                    sendDataToParent(e.target.value, "twelve_board_of_education");
                }} />
            </div>
        </div>
        <div className='row'>
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Year of Pass</label>
            <div className='col'>
                <input type="text" className="form-control" name="yearOfPass" placeholder='Year of Pass' onChange={(e) => {
                    sendDataToParent(e.target.value, "twelve_year_of_pass");
                }} />
            </div>
        </div>
        <div className='row'>
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Country</label>
            <div className='col'>
                <input type="text" className="form-control" name="boardOfCountry" placeholder='Country' onChange={(e) => {
                    sendDataToParent(e.target.value, "twelve_country");
                }} />
            </div>
        </div>
        <div className='row'>
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Best of 4 Core Subject % Upon</label>
            <div className='col'>
                <input type="text" className="form-control" name="bestOfCoreSubject" placeholder='Best of 4 Core Subject % Upon' onChange={(e) => {
                    sendDataToParent(e.target.value, "twelve_4_core_subject");
                }} />
            </div>
        </div>
        <div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload 1st Semester document <span style={{ color: "red" }}>*</span><p style={{ color: "red" }}>{errors && errors.first_sem ? errors.first_sem : ""}</p></label>
            <div className="col-sm-9">

                <input accept=".pdf" type="file" className="form-control" name="first_sem" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'first_sem');
                }} />

            </div>
        </div><div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload 2nd Semester document <span style={{ color: "red" }}>*</span><p style={{ color: "red" }}>{errors && errors.second_sem ? errors.second_sem : ""}</p> </label>
            <div className="col-sm-9">

                <input accept=".pdf" type="file" className="form-control" name="second_sem" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'second_sem');
                }} />

            </div>
        </div>
        <div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload 3rd Semester document <span style={{ color: "red" }}>*</span><p style={{ color: "red" }}>{errors && errors.third_sem ? errors.third_sem : ""}</p></label>
            <div className="col-sm-9">

                <input accept=".pdf" type="file" className="form-control" name="third_sem" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'third_sem');
                }} />

            </div>
        </div>
        <div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload 4th Semester document <span style={{ color: "red" }}>*</span><p style={{ color: "red" }}>{errors && errors.first_sem ? errors.fourth_sem : ""}</p></label>
            <div className="col-sm-9">

                <input accept=".pdf" type="file" className="form-control" name="fourth_sem" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'fourth_sem');
                }} />

            </div>
        </div>
        <div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload 5th Semester document </label>
            <div className="col-sm-9">

                <input accept=".pdf" type="file" className="form-control" name="fiveth_sem" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'fiveth_sem');
                }} />

            </div>
        </div>

        <div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload 6th Semester document </label>
            <div className="col-sm-9">
                <input accept=".pdf" type="file" className="form-control" name="sixth_sem" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'sixth_sem');
                }} />
            </div>
        </div>
        <div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload 7th Semester document </label>
            <div className="col-sm-9">
                <input accept=".pdf" type="file" className="form-control" name="seventh_sem" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'seventh_sem');
                }} />
            </div>
        </div>
        <div className="row mb-3">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload 8th Semester document </label>
            <div className="col-sm-9">
                <input accept=".pdf" type="file" className="form-control" name="eighth_sem" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'eighth_sem');
                }} />

            </div>
        </div>

        <div className='row mb-3'>
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload Official Document</label>
            <div className='col-sm-9'>
                <input accept=".pdf" type="file" className="form-control" onChange={(e) => {
                    sendDataToParent(e.target.files[0], 'upload_official_document')
                }} />
            </div>
        </div>

        <div className="row">
            <div className="set col">
                <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Backlog Certificate</label>
                <input
                    type="file"
                    className="form-control"
                    name="backlog_certificate"
                    onChange={(e) => {
                        sendDataToParent(e.target.files[0], "backlogCertificate");
                    }}
                />
            </div>
        </div>
    </>
    );
};