import { Link } from 'react-router-dom';
// import Forgotpasswordlogin from '../forgotpassword/Forgotpasswordlogin';
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";

function Footer() {
    return (<>
<footer id="footer" className="footer">
    <div className="copyright">
      &copy; Copyright <strong><span>Enrolguru</span></strong>. All Rights Reserved
    </div>
            <div className="credits">
      
      Designed by <a href="#">Enrolguru</a>
    </div>
  </footer>

    </>)
}

export default Footer;