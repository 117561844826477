
import { Link } from 'react-router-dom';
import React from "react";
import Network from '../Network/Network';
function Home() {
 

    return (<>
   
        <main className="mainlogin">
            <div className="container">
            
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-md-9 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <a href="#" className="logo d-flex align-items-center w-auto">
                                    -<img src={Network.Network.image_url+`enrollog.png`} alt=""/>
                                        <span className="d-none d-lg-block">Enrolguru</span>
                                    </a>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">
                               <div className="row">
                                     
                            <div className="col-sm-12">
                         <h4 >Welcome To Enrolguru </h4>
                         <Link className="btn enrollogin" to="/admin/login"> Login as Admin</Link>
                         <Link className="btn enrollogin" to="/agent/login">Login as Agent </Link>
                         <Link className="btn enrollogin" to="/college/login">Login as College</Link>
                              </div>
                                 </div>
                                    </div>
                                </div>

                               

                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
        {/* <Forgotpasswordlogin /> */}
    </>)



}


export default Home;





