
import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";
import Loader from '../loader/Loader';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Network from '../Network/Network';
//end




function Mystaff() {

    const added_by = localStorage.getItem('token-info');
    const [data,setdata]=useState({});
    useEffect(() => { sendDetailsToServer();  }, []);


    const sendDetailsToServer = async () => {
        let formdata=new FormData();
        formdata.append('id',added_by);
        formdata.append('user_type','Admin');
        axios.post(Network.Network.api_url+"mystaff")
            .then(function (response) {
                setdata(response.data.data)
            })
    }

    
    setTimeout(function(){
        $('.myTable').DataTable();
         } ,2000);
    return (<>
        <Header />
        <Sidebar />
       
        <main id="main" className="main">
            <section className="section Applicationmain">


                <table className="table datatable dataTable-table myTable table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">Application Id</th>
                            <th scope="col">Student Name</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Email</th>
                            <th scope="col">College Name</th>
                            <th scope="col">Program Name</th>
                            <th scope="col">Application Satus</th>
                            <th scope="col">Chat</th>
                            <th scope="col">Added by</th>
                            <th scope="col">Pathway</th>
                            <th scope="col">COE</th>
                            <th scope="col">Created</th>

                        </tr>

                    </thead>
                    <tbody>


                       
                    </tbody>
                </table>

            </section>
        </main>
        <Footer />
    </>)
}

export default Mystaff;



