
import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";
import Loader from '../loader/Loader';
import { useNavigate } from 'react-router';
import Network from '../Network/Network';

function CollegeAddprogram() {
     const navigate=useNavigate();
    const onSubmit = (e) => {
        setIsLoading(false);
        e.preventDefault();
        sendDetailsToServer();
    }
    const [successmessage, setstateforsuccess] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [custome, setcustome] = useState('none');
    const user_id = localStorage.getItem('token-info');
    const [state, setState] = useState({
            intake_month: "",
            intake_year: "",
            course_name: "",
            duration: "",
            work_permit: "",
            program_level: "",
            application_fee: "",
            tuition_fee: "",
            total_fee: "",
            semester_fee: "",
            website_url: "",
    })


    useEffect(() => {
        check_duration(); 
       });
    const  check_duration=()=>{
        if(state.intake_year>=24)
        {
            setcustome('block');
        }
        else{
            setcustome('none');
        }
          
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        if (e.target.name === 'intake_month') {
            let selectedValues = Array.from(e.target.selectedOptions).map(opt => opt.value)
            setSelectedValues(selectedValues);
        }
        setState(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }

    const sendDetailsToServer = async () => {
        setIsLoading(true);

         let formdata={
            'college_university_id':user_id,
            'intake_month':selectedValues,
            'intake_year': state.intake_year,
            'course_name':state.course_name,
            'duration':state.duration,
            'work_permit':state.work_permit,
            'program_level':state.program_level,
            'application_fee':state.application_fee,
            'tuition_fee':state.tuition_fee,
            'total_fee':state.total_fee,
            'website_url':state.website_url,
            'added_by':user_id,
            'semester_fee':state.semester_fee,
            'payment_schedule':payment,
            'fees_charges':inputFields,
        };
       
        axios.post(Network.Network.api_url+"add_program", JSON.stringify(formdata))
            .then(function (response) {
                 console.log('responseresponseresponse',response); 
                 
                if (response.data.status == 'success') {
                    setIsLoading(false);
                    setstateforsuccess("Program Add Successfully")
                    navigate('/college/programlist');
                    setTimeout(() => {
                        navigate('/college/programlist');
                    },3000);
                  
                } else {
                   
                    setstateforsuccess("Something Went wrong please try again ")
                }
                setIsLoading(false);

            })

    }

    const [inputFields, setInputFields] = useState([{
        label:'',
        fees:'',
        refund:''  
    } ]);
    const addInputField = ()=>{

        setInputFields([...inputFields, {
            label:'',
            fees:'',
            refund:'' 
        } ])
      
    }
    const removeInputFields = (index)=>{
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
   }
   const handleChange_fee = (index, evnt)=>{
    
    const { name, value ,checked} = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    list[index][name] = name === "refund" ? checked : value;
    setInputFields(list);
    
}

// payment --
const [payment, setpayment] = useState([{
    feename:'',
    amount:'',
    due_date:''  
} ]);
const addpayment = ()=>{

    setpayment([...payment, {
        feename:'',
        amount:'',
        due_date:'' 
    } ])
  
}
const removepayment = (index)=>{
    const rows = [...payment];
    rows.splice(index, 1);
    setpayment(rows);
}
const handleChange_payment = (index, evnt)=>{

const { name, value } = evnt.target;
const list1 = [...payment];
list1[index][name] = value;
setpayment(list1);
}
console.log('payment',payment);

// 
    return (<>
        <Header />
        <Sidebar />
         
        <link rel="stylesheet" href="/../adminasset/css/style.css" />
        <link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" relation="stylesheet" type="text/css" />
        <main id="main" className="main">
            <p>{successmessage ? successmessage : ''}</p>
            

            <section className="section collegeadd ">
            {isLoading ? <Loader></Loader> : ''}
                <form onSubmit={onSubmit} >
                    <div className="row">

                        <div className="col-lg-6">

                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Add Program </h5>
                                    <div className="row mb-3">
                                        <label for="inputNumber" class="col-sm-3 col-form-label">Course Name</label>
                                        <div class="col-sm-9">
                                            <input type="text" className="form-control" name="course_name" value={state.course_name} required onChange={handleChange} placeholder="Course Name" required/>
                                        </div>
                                    </div>
                                    
                                    <div className="row mb-3">
                                        <label for="inputPassword" class="col-sm-3 col-form-label">Program Level</label>
                                        <div className="col-sm-9">
                                            
                                            <select name="program_level" value={state.program_level} onChange={handleChange} className='col-form-label form-control' required>
                                               <option value='' disabled selected>--Select Level--</option>
                                                <option value="Advanced Diploma">Advanced Diploma</option>
                                                <option value="Certificate III">Certificate III</option>
                                                <option value="Certificate IV">Certificate IV</option>
                                                <option value="Diploma">Diploma</option>
                                                <option value="Non AQF Award">Non AQF Award</option>
                                                <option value="Graduate Diploma">Graduate Diploma</option>
                                                <option value="Masters Degree (Coursework)">Masters Degree (Coursework)</option>
                                                <option value="Bachelor Degree">Bachelor Degree</option>
                                                <option value="Graduate Certificate">Graduate Certificate</option>
                                            </select>
                                        </div>
                                    </div>
                                 <div className="row mb-3">
                                        <label for="inputNumber" className="col-sm-3 col-form-label">Duration</label>
                                        <div className="col-sm-9">
                                            <select name="intake_year" value={state.intake_year} onChange={handleChange} className='col-form-label form-control' required>

                                                <option value="">Select Duration</option>
                                                <option value="32">32</option>
                                                <option value="41">41</option>
                                                <option value="45">45</option>
                                                <option value="60">60</option>
                                                <option value="65">65</option>
                                                <option value="75">75</option>
                                                <option value="76">76</option>  
                                                <option value="100">100</option>  
                                                <option value="52">52</option>  
                                                <option value="73">73</option>  
                                                <option value="70">70</option>  
                                                <option value="78">78</option>
                                                <option value="72">72</option>  
                                                <option value="26">26</option>  
                                                <option value="34">34</option>  
                                                <option value="75">75</option>  
                                                <option value="51">51</option>  
                                                <option value="80">80</option>  
                                                <option value="94">94</option>
                                                <option value="96">96</option>  
                                                <option value="104">104</option>  
                                               <option value="24">24</option>  
                                                <option value="156">156</option>
                                               
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3" style={{display:custome}}>
                                        <label for="inputPassword" className="col-sm-3 col-form-label">Intake Month</label>
                                        <div className="col-sm-9">
                                            <select className="form-control multiple intakeclasseess" name="intake_month" multiple value={selectedValues} onChange={handleChange} required>

                                                <option value="Jan">Jan</option>
                                                <option value="Feb">Feb</option>
                                                <option value="Mar">Mar</option>
                                                <option value="Apr">Apr</option>
                                                <option value="May">May</option>
                                                <option value="Jun">Jun</option>
                                                <option value="Jul">Jul</option>
                                                <option value="Aug">Aug</option>
                                                <option value="Sep">Sep</option>
                                                <option value="Oct">Oct</option>
                                                <option value="Nov">Nov</option>
                                                <option value="Dec">Dec</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label for="inputPassword" className="col-sm-3 col-form-label">Application Fee</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="application_fee" onChange={handleChange} value={state.application_fee} placeholder="Application Fee" required/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label for="inputPassword" className="col-sm-3 col-form-label">Tuition Fee</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="tuition_fee" onChange={handleChange} value={state.tuition_fee} placeholder="Tuition Fee" required  onKeyPress={(event) => {
      if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
      }
  }}/>
                                        </div>
                                    </div>
                                
                                    <div className="row mb-3">
                                        <label for="inputPassword" className="col-sm-3 col-form-label">Semester Fee</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="semester_fee" onChange={handleChange} value={state.semester_fee} placeholder="Semester Fee" required  onKeyPress={(event) => {
      if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
      }
  }}/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label for="inputPassword" className="col-sm-3 col-form-label">Total Fee</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="total_fee" onChange={handleChange} value={state.total_fee} placeholder="Total Fee" required  onKeyPress={(event) => {
      if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
      }
  }}/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label for="inputPassword" className="col-sm-3 col-form-label">Website Url</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="website_url" onChange={handleChange} value={state.website_url} placeholder="www.demo.com" required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                        <h4><strong>Payment Schedule</strong></h4>
                                    <div className="col-sm-8">
                                    {
                                        payment.map((data, index)=>{
                               const { feename, amount,due_date}= data;
                                           
                                            return(
                                                <div className="row my-3" key={index}>
                                     
                                        <div className="col-sm-4">
                                        <input type="text" onChange={(evnt)=>handleChange_payment(index, evnt)}  name="feename" value={feename}className="form-control" placeholder="Enter Fee Name"/>
                                        </div>


                                        <div className="col-sm-4">
                                        <input type="text" onChange={(evnt)=>handleChange_payment(index, evnt)}  name="amount" value={amount}className="form-control" placeholder="Enter Amount"/>
                                        </div>

                                        <div className="col-sm-4">
                                        <input type="text" onChange={(evnt)=>handleChange_payment(index, evnt)}  name="due_date" value={due_date}className="form-control"  placeholder="Enter due date"/>
                                        </div>
                                        
                                    <div className="col">
                                    {(payment.length!==1)? <button className="btn btn-outline-danger" type="button" onClick={removepayment}>Remove</button>:''}
                                        </div>
                                    </div>
                                            )
                                        })
                                    }
                                    <div className="row">
                                        <div className="col-sm-12">

                                        <button type="button" className="btn btn-outline-success " onClick={addpayment}>Add New</button>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                            </div>

                        </div>
                        <div className="col-lg-6">

                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <h4><strong>Fees and Associated Charges</strong></h4>
                                    <div className="col-sm-8">
                                    {
                                        inputFields.map((data, index)=>{
                                            const {label, fees, refund}= data;
                                            return(
                                                <div className="row my-3" key={index}>
                                        <div className="col-sm-4">
                                        <div className="form-group">
                                        <input type="text" onChange={(evnt)=>handleChange_fee(index, evnt)} value={label} name="label" className="form-control"  placeholder="Enter  Label" />
                                        </div>
                                        </div>
                                        <div className="col-sm-4">
                                        <input type="text" onChange={(evnt)=>handleChange_fee(index, evnt)} value={fees} name="fees" className="form-control" placeholder="Enter Amount" />
                                        </div>
                                        <div className="col-sm-4">
                                            
                                        <input type="checkbox" onChange={(evnt)=>handleChange_fee(index, evnt)}  name="refund" checked={refund} className="form-check-input" />
                                        <label> &nbsp; &nbsp;Refundable  </label>

                                        </div>
                                    <div className="col">
                                    {(inputFields.length!==1)? <button className="btn btn-outline-danger" type="button" onClick={removeInputFields}>Remove</button>:''}
                                        </div>
                                    </div>
                                            )
                                        })
                                    }
                                    <div className="row">
                                        <div className="col-sm-12">

                                        <button type="button" className="btn btn-outline-success " onClick={addInputField}>Add New</button>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="col-sm-4">

                                    </div>
                                                        
                                </div>
                            </div>

                        </div>
                        <div className="row mb-3">

                            <div className="col-sm-10">
                                <button type="submit" class="btn btn-primary">Submit Form</button>
                            </div>
                        </div>

                    </div>
                </form>
            </section>

        </main>

        {/* <Forgotpasswordlogin /> */}
        <Footer />
    </>)



}


export default CollegeAddprogram;
