import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";
import Loader from '../loader/Loader';
import firebase from 'firebase';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $, { jquery } from 'jquery';
//end
import { Link } from 'react-router-dom';
import Network from '../Network/Network';

function CollegeApplicationList() {
   const [loader,setloader]=useState(false);
    const [applicationlistdata, setstateforapplicationlist] = useState([]);
    const [status_list, setstatus] = useState([]);
    const added_by = localStorage.getItem('token-info');
    const tokenname = localStorage.getItem('token-name');

    console.log('applicationlistdata',applicationlistdata);
    useEffect(() => {   timers(); sendDetailsToServer(); }, []);

    const sendDetailsToServer = async () => {
        var formdata = new FormData();
        formdata.append('college_id', added_by);
        axios.post(Network.Network.api_url+"get_application_listfor_college", formdata)
            .then(function (response) {
                setloader(true);
                setstateforapplicationlist(response.data.data)
                setstatus(response.data.status_list)
            })
            setloader(true);
    }

    const timers= async ()  =>{
        // setTimeout(function(){
        //     $('.myTable').DataTable();
        //      } ,1000);
    }
     const [update_statuss,setupdatestatus]=useState('');
     const [appli_ids,setappli_id]=useState('');
     const [appli_ids1,setappli_id1]=useState('');
     const update_status= async (i,e,id,appli_id)=>{
        setloader(false);
        setupdatestatus(e);
        setappli_id(id);
        setappli_id1(appli_id);

         var formdata = new FormData();
         formdata.append('status_id', i); 
         formdata.append('status_name',e); 
         formdata.append('applicationid',id); 
         formdata.append('added_by',added_by); 
         formdata.append('college_name',tokenname); 
         axios.post(Network.Network.api_url+"apply_update_application_status", formdata)
         .then(function (response) {
             if(response.data.status=='success' && response.data.rowdata!='')
             {
                setloader(true);
                send_notification(response.data.rowdata);
             }
             else
             {
             }
             setloader(true);
         })

     }

     const send_notification=(data)=>{
        const db1 = firebase.database();
         db1.ref(`notification/${data.id}`).push({
           read: 0,
           seen: 0,
           messages:tokenname+' Update status',
           noti:`${tokenname } :-Update ${update_statuss} application of ${appli_ids1}`,
           mess_type: 'notification',
           user_type:'college',
           college_id: added_by,
           get_id:added_by,
           agent_id: '',
           application_id: appli_ids,
           url:'application_status',
           sender_id: added_by,
           sender_name: tokenname,
           sender_photo: '',
           sender_type: 'college',
           time: Date.now()
       });
       db1.ref(`notification/1`).push({
        read: 0,
        seen: 0,
        messages:tokenname+' Update status',
        noti:`${tokenname } :-Update ${update_statuss} application of ${appli_ids1}`,
        mess_type: 'notification',
        user_type:'college',
        college_id:added_by ,
        get_id:added_by,
        agent_id: '',
        application_id: appli_ids,
        url:'application_status',
        sender_id: added_by,
        sender_name: tokenname,
        sender_photo: '',
        sender_type: 'college',
        time: Date.now()
    });
      
     }
    return (<>
        <Header />
        <Sidebar />
       {loader==false ? <Loader></Loader> : '' }
        <main id="main" className="main">
            <section className="section Applicationmain">


                <table className="table datatable dataTable-table myTable table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">Application Id</th>
                            <th scope="col">Student Name</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Email</th>
                            <th scope="col">College Name</th>
                            {/* <th scope="col">Program Name</th> */}
                            <th scope="col">Application Satus</th>
                            <th scope="col">Chat</th>
                            <th scope="col">Added by</th>
                            <th scope="col">Created</th>

                        </tr>

                    </thead>
                    <tbody>


                        {applicationlistdata.length > 0 ?

                            applicationlistdata.map((items, i) => {
                                // items.action_datetime

                                return (
                                    <tr key={items.application_id} className={items.newsss}>
                                        <td>  <Link to={`/college/viewapplications_data?yaja=${items.id}`} >


                                        {items.application_id}</Link></td>
                                        <td>{items.first_name} {items.last_name}</td>
                                        <td>{items.mobile_number}</td>
                                        <td>{items.primary_email}</td>
                                        <td>{items.college_name}</td>
                                        {/* <td>{items.course_name}</td> */}
                                        <td className="text-center">



                     <div className="dropdown selectBox ">                         
                    <button  type="button" data-bs-toggle="modal" data-bs-target={`#exampleModal${items.application_id}`} >
                    {items.application_status}
                                            </button>          
                 <div className="modal fade " id={`exampleModal${items.application_id}`}  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                 <div className="modal-dialog modal-dialog-centered modal-xl mdl">
                   <div className="modal-content">
                        <div className="modal-header">
                         <h5 className="modal-title" id="exampleModalLabel">Update Application Status</h5>
                       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                         <div className="modal-body multiselect">
                        
                         {items.status_list.length > 0 ?
                            items.status_list.map((itemss, i) => {
                                 
                             return (
                             <label key={itemss.id} >
                               <input type="checkbox" className="one"  value={itemss.status_name} onClick={() => update_status(itemss.id,itemss.status_name,items.id,items.application_id)} defaultChecked={itemss.checked}
/>
                              {itemss.status_name} <span></span><span className="namestaff"></span><span className="department">  </span></label>
                                 )
                                })
                          :''}  
                        </div>
                          <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                     
                               </div>
                          </div>
                     </div>
                  </div> 
                
                </div>
             </td>
            <td><Link to={`/chat/chat?id=${items.id}`}><button className="btn-primary btnchat">View Chat</button></Link></td>
            <td>{items.agentfname} {items.agentlname}</td>
            <td>
            {new Date(items.created_at).toLocaleString("en-US", { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })}
                
            </td>
                </tr>
                                )
                            })
                            : ''}
                    </tbody>
                </table>

            </section>
        </main>
        <Footer />
    </>)
}

export default CollegeApplicationList;

