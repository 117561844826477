
import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyCpCVKkbHHciVn1cfRyQMwayVNOmkng1aI",
  authDomain: "applyborad.firebaseapp.com",
  projectId: "applyborad",
  storageBucket: "applyborad.appspot.com",
  messagingSenderId: "966261951771",
  appId: "1:966261951771:web:0de3133abbd361240d17be",
  measurementId: "G-8T5D6N8NF4"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;