import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import Loader from '../loader/Loader';
import Network from '../Network/Network';
export default function Stats_college() {
    const user_id = localStorage.getItem('token-info');
    const [stats,setstats]=useState([]);
    const [loader,setloader]=useState(false);


    useEffect(() => {
        get_colleges_stats();     
      },[]);

    const get_colleges_stats =() => {
        const formdata=new FormData();
        formdata.append('id',user_id);
          axios.post(Network.Network.api_url+'get_colleges_stats', formdata)
          .then(function (response) {
              console.log('response', response.data);
              if (response.data) {
              setstats(response.data);
              setloader(true)

                
              } 
          })
    }
 


       return(
                            <>
                             <div className="col-xxl-4 col-md-6">
                                <div className="card info-card sales-card">
                                    <div className="card-body">
                                        <h5 className="card-title">Total Appication<span></span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                {/* <i className="bi bi-cart"></i> */}
                                            </div>
                                            <div className="ps-3">
                                                <h6>{loader==true ? stats.application : <Loader></Loader>  }</h6>
                                               

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xxl-4 col-md-6">
                                <div className="card info-card revenue-card">
                                    <div className="card-body">
                                        <h5 className="card-title">Total Program <span></span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                {/* <i className="bi bi-currency-dollar"></i> */}
                                            </div>
                                            <div className="ps-3">
                                                <h6>{loader==true ? stats.progrma : <Loader></Loader>  }</h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-12">
                                <div className="card info-card customers-card">
                                    <div className="card-body">
                                        <h5 className="card-title">Approved <span></span></h5>

                                        <div className="d-flex align-items-center">
                                            <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                {/* <i className="bi bi-people"></i> */}
                                            </div>
                                            <div className="ps-3">
                                                <h6>##</h6>
                                               
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            </>
                   )
       }
