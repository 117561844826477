import React from "react";
import PrintButton from "./PrintButton";
import { useLocation } from "react-router";

export default function App() {
  const tableRef = React.useRef();
  const location = useLocation();
    const application_id = new URLSearchParams(location.search).get("offer");
  return (
    <>
      <div ref={tableRef}>
      <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <link rel="shortcut icon" href="images/fabicon.png" />
    <title>amrutam</title>
    <style dangerouslySetInnerHTML={{__html: "\n      @import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800,900&display=swap');\n      body{\n      font-family: 'Muli', sans-serif;\n      } \n   " }} />
    <table style={{width: '700px', margin: 'auto', border: '1px solid #e6e6e6', padding: '0px 20px 50px'}}>
      <tbody>
        <tr><td colSpan={3} style={{borderBottom: '1px solid #262626'}}>
            <h4 style={{textAlign: 'right'}}><img src="https://enrolguru.com/enrolguru_backend/api/images/1676276275_photo-1562774053-701939374585.jpeg" style={{height: '56px'}} /></h4>
          </td>
        </tr></tbody>
      <tbody><tr>
          <td colSpan={3} style={{fontSize: '22px', fontWeight: 600, paddingTop: '22px', textAlign: 'center'}}>Letter of Offer - International Student
          </td>
        </tr><tr>
          <td colSpan={2} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 400, paddingTop: '18px'}}>
            21 November 2022
          </td>
          <td colSpan={1} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 400, textAlign: 'right', paddingTop: '12px'}}>
            Offer ID: 4145
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 400}}>
            Mr Abdul Moiz<br />14/08/2003
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 400}}>
            Email: abdulmoiz14803@gmail.com<br />Phone: +923499991979
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 400}}>
            Dear Abdul Moiz,
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 400}}>
            We are pleased to advise that your application for admission to Sydney International School of Technology and Commerce (SISTC) has been accepted. Details of the offer and conditions (if any) are outlined below. This Letter of Offer constitutes a formal written agreement between you and SISTC.
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#000', paddingBottom: '10px', fontWeight: 600}}>
            We are pleased to advise you that an offer is made for you to commence in the second year of the three-year Bachelor of Information Technology, with a credit of 8 units towards the 24 units which are required to complete the degree. This offer is conditional on meeting the requirements specified below (please see the Special Conditions in the Fees and Associated Charges section below).
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#000', paddingBottom: '10px', fontWeight: 600}}>
            Details of Offer:
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, paddingTop: '10px'}}>
            Institution Details:
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <table style={{width: '100%'}}>
              <tbody><tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>CRICOS Code</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>03836J</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>TEQSA Registration Number</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>PRV14311</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Address</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Sydney International School of Technology and Commerce<br />
                    Level 14<br />
                    233 Castlereagh Street<br />
                    Sydney NSW 2000
                  </td>
                </tr>
              </tbody></table>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, paddingTop: '10px'}}>
            Student Details:
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <table style={{width: '100%'}}>
              <tbody><tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Surname </td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Moiz</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Given name</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Abdul</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Gender</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Male</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Date of birth</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>14/08/2003</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Citizenship</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Pakistani</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Identification No </td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>AT0918283</td>
                </tr>
              </tbody></table>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{paddingTop: '30px'}}>
            <table>
              <tbody><tr>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Course </th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Study Mode</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>WIL 1</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>CRICOS Code</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Start Date</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Finish Date</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Duration 2</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Tuition Fee Total 3</th>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Bachelor of</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Blende</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Y</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>010212</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>15/07/2024</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>12/07/2026</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>104</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$28,000</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Informatio n Technology</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>d</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>1</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>week(s)</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Major</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', textAlign: 'center'}} colSpan={7}>Digital Enterprise / Business Information Systems</td>
                </tr>
              </tbody></table>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, paddingTop: '20px', lineHeight: '22px'}}>
            1 WIL refers to Work Integrated Learning and is included in the final year of the BIT course as described in the SISTC Work Integrated Learning Policy and Protocols.<br />
            2 Normal course duration is 156 weeks/3 years (24 units).<br />
            3 Total Fees may change as fees are subject to an annual increase.
          </td>
        </tr><tr>
        </tr><tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, paddingTop: '10px', textDecoration: 'underline'}}>
            Minimum IT requirements for Your Course
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, lineHeight: '22px'}}>
            To get the most from your learning experience, it is vital that you own or have frequent access to a fast and reliable computer. You will need to access video recordings and digital learning resources, submit assignments, and participate in class discussions and collaboration via the learning management system.
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#000', fontWeight: 600, paddingTop: '10px'}}>
            We recommend systems that meet or exceed the following specifications: 
          </td>
        </tr><tr>
        </tr><tr>
          <td colSpan={3}>
            <table style={{width: '100%', fontSize: '14px', color: '#262626', fontWeight: 500}}>
              <tbody><tr>
                  <td>Processor (CPU):</td>
                  <td>Intel Core i5 (sixth generation or newer) or equivalent</td>
                </tr>
                <tr>
                  <td>Operating System:</td>
                  <td>Microsoft Windows 10 x64</td>
                </tr>
                <tr>
                  <td>Memory:</td>
                  <td>8 GB RAM</td>
                </tr>
                <tr>
                  <td>Storage:</td>
                  <td>250 GB internal storage drive</td>
                </tr>
                <tr>
                  <td>Monitor/Display:</td>
                  <td>13" LCD monitor, resolution of 1600 x 900 or better.</td>
                </tr>
                <tr>
                  <td>Network Adapter:</td>
                  <td>802.11ac 2.4/5 GHz wireless adapter</td>
                </tr>
                <tr>
                  <td>Other:</td>
                  <td>Internal or external Webcam &amp; Microphone.</td>
                </tr>
              </tbody></table>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 600, paddingTop: '20px'}}>Software requirements:</td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500}}>
            Access to an up-to-date web browser - free downloads (for example, Chrome, Safari, Firefox or Edge).
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500}}>
            Office software to create documents, spreadsheets and presentations. Office 365 is available as a free online resource for SISTC students.
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, paddingTop: '10px'}}>Internet requirements: Minimum download speed = 0.75Mbps. Minimum upload speed = 0.35Mbps.</td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, paddingTop: '10px'}}>Guidance about these IT requirements is available on the SISTC <a href="#">website</a>.</td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, paddingTop: '10px'}}>
            Fees and Associated Charges:
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, paddingTop: '10px', lineHeight: '22px'}}>
            Please note that the SISTC Policy regarding fees, charges and refunds can be found in the SISTC Fees, Charges and Refund Policy on the SISTC website.
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, paddingTop: '10px', lineHeight: '22px'}}>
            The course fees (per semester) are indicated below and are based on a standard full-time study load and duration. Your course fees and duration may vary if you have applied for and been granted Advanced Standing. Tuition fees may increase during your studies. Students are advised in advance of any increase in tuition fees being implemented.
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{paddingTop: '15px'}}>
            <table style={{width: '100%'}}>
              <tbody><tr>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Fee Type</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Fee Amount (AUD)</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Refundable</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Non Refundable</th>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Total tuition fee</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$28,000</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>First instalment</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$5,000</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Non-tuition fees</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}} />
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Application fee</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$250</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Cancellation fee – Offshore visa refusal</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>250 </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Cancellation fee – Onshore visa refusal</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>250 </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr><td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Cancellation fee – non visa refusal </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>500 </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Overseas Student Health Cover (OSHC)</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$2,107</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔ 1</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Material fee (eBook for each unit enrolled)</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$65</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Academic Transcripts, per copy</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$50 </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Assessment Re-checking</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$20</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Assessment Re-assessment/marking</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$80</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Holiday/Travel Letter</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$20</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Confirmation of Enrolment Letter</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$20 </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Supplementary Assessment Fee</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$300</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Re-issue of Student Card</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$20</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Late course commencement fee </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$50 </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Late payment per week late </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$100 </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>✔</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Total fee due before issuing the CoE </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>$5,000</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}} />
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}} />
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', verticalAlign: 'top'}}>Special Conditions</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', lineHeight: '22px'}} colSpan={3}>
                    Please note that this is a conditional Letter of Offer. The
                    student must satisfy the below requirements to secure
                    their enrolment at SISTC:
                    <ul style={{paddingLeft: '30px'}}>
                      <li>Student must provide a copy of their visa grant letter once received.</li>
                      <li>Student must complete the Genuine Temporary Entry (GTE) form provided with this Letter of Offer. The GTE form must be assessed by SISTC admission staff to confirm the student’s suitability for studies.</li>
                      <li>Student must successfully complete the ICT50220 Diploma of Information Technology at the Actors College of Theatre and Television (ACTT) before commencing the Bachelor of Information Technology [0102121].</li>
                      <li>Upon successful completion of the Diploma of Information Technology at ACTT, block advanced standing for 8 first-year units would be granted to the student.</li>
                      <li>Advanced standing would reduce the student’s course duration to 16 units for the Bachelor of Information Technology [0102121] on the basis that above conditions are met.</li>
                    </ul>
                    <p>Please note: the GTE form will need to be assessed by the
                      SISTC Admissions Team to confirm suitability for the
                      course. Therefore, the student should not commence
                      payment for the course until instructed to do so by the
                      SISTC Admissions Team.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{fontSize: '13px', color: '#262626', paddingBottom: '10px', fontWeight: 500, lineHeight: '22px'}}>
                    1 Subject to the refund policy of the provider of OSHC, please refer to their policy regarding refunds.
                  </td>
                </tr>
                <tr>
                </tr><tr>
                  <td colSpan={3} style={{fontSize: '14px', color: '#000', fontWeight: 600, textDecoration: 'underline'}}>
                    Payment Schedule*:
                  </td>
                </tr>
                <tr>
                </tr><tr>
                  <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, lineHeight: '22px'}}>
                    If you wish to accept this offer, you will need to pay the total fees shown below. Payment should be made in the form of a bank cheque or money order in Australian dollars made payable to "Sydney International School of Technology and Commerce” or payment may be remitted by Telegraphic Transfer direct to our account.
                  </td>
                </tr>
                <tr>                      
                </tr></tbody></table>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{paddingTop: '15px'}}>
            <table style={{width: '100%'}}>
              <tbody><tr>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>COURSE</th>
                  <th colSpan={2} style={{padding: 0, margin: '-2px', display: 'block'}}>
                    <table style={{width: '100%'}}>
                      <tbody><tr>
                          <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff', width: '250px'}}>FEE NAME</th>
                          <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>AMOUNT</th>
                          <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>DUE DATE</th>
                        </tr>
                      </tbody></table>
                  </th>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>0102121</td>
                  <td colSpan={2} style={{padding: 0, margin: '-2px', display: 'block'}}>
                    <table style={{width: '100%'}}>
                      <tbody><tr>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', width: '250px'}}>1 st instalment</td>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$5,000.00</td>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Paid at acceptance of offer</td>
                        </tr>
                        <tr>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', width: '250px'}}>2 nd instalment (1 st teaching period) 
                          </td><td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$2,000.00</td>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>08/07/2024</td>
                        </tr>
                        <tr>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', width: '250px'}}>3 rd instalment (2 nd teaching period)</td>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$7,000.00</td>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>10/03/2025</td>
                        </tr>
                        <tr>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', width: '250px'}}>4 th instalment (3 rd teaching period)</td>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$7,000.00</td>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>07/07/2025</td>
                        </tr>
                        <tr>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', width: '250px'}}>5 th instalment (4 th teaching period)</td>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>$7,000.00 </td>
                          <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>09/03/2026</td>
                        </tr>
                      </tbody></table>
                  </td>
                </tr>
              </tbody></table>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingBottom: '10px', fontWeight: 500, lineHeight: '22px'}}>Payment can be made by telegraphic transfer, cash, cheque or direct deposit into the School’s bank account. The SISTC bank details are as follows:
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <table style={{width: '100%'}}>
              <tbody><tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Account name:</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Australia Advance Education Group</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Bank Name:</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Commonwealth Bank</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Bank address</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Sydney NSW</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Branch Number (BSB):</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>062 005</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>Account Number:</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>1130 6286</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>SWIFT Code:</td>
                  <td style={{border: '1px solid', padding: '8px', fontSize: '14px'}}>CTBAAU2S</td>
                </tr>
              </tbody></table>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#000', fontWeight: 600, paddingTop: '20px', textDecoration: 'underline'}}>
            Conditions of Enrolment
          </td>
        </tr>
        <tr>
        </tr><tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500}}>
            As part of signing this Letter of Offer your responsibilities are that you will need to:
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            <ul>
              <li>fulfil the Visa requirements of the Australian Government. Note that <a href="#">Study Australia</a> estimated that as of October 2019, the 12-month living costs for an International student in Australia was $21,041.</li>
              <li> notify SISTC of your contact details including your current residential address, mobile number (if any) and email address (if any); who to contact in emergency situations; and any changes to those details, within 7 calendar days of the change. while you are enrolled in the above course;</li>
              <li>provide evidence of meeting <a href="#">Admission Requirements</a> for SISTC Courses including English Language requirements and confirmation that you will be at least 18 years of age prior to commencing the course;</li>
              <li> maintain your Overseas Student Health Cover (OSHC) for the period of your stay to meet the terms of this written agreement. You can find further information on OSHC at <a href="#">Private Health</a></li>
              <li> keep a copy for your records of this written Letter of Offer and Acceptance, and receipts for any payments of fees;</li>
              <li> maintain satisfactory course progress as outlined in the SISTC Course Rules, Progression and Completion Policy, which can be found on the SISTC <a href="#">website</a>;</li>
              <li>
                make yourself aware of your rights and obligations while studying at SISTC. This information is available to you on the SISTC <a href="#">website</a>, which also provides access to a range of policies which are designed to help you complete your chosen course of study. In particular, you will be required to abide by the:
                <ul>
                  <li>SISTC Academic Integrity Policy and associated requirements;</li>
                  <li>SISTC Student Rights and Obligations Policy;</li>
                  <li>SISTC Acceptable use of IT Policy;</li>
                </ul>
              </li><li>
                apply for Advanced Standing if you wish to gain entry to the course on the basis of equivalent qualifications:
                <ul>
                  <li>SISTC Credit Transfer and Advanced Standing Policy and Procedures.</li>
                </ul>
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#000', fontWeight: 600, paddingBottom: '10px'}}>
            CSISTC’s Responsibilities
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            Our responsibilities as an Institute of Higher Education are listed below. These include a number of key policies which you can find on the SISTC <a href="#">website</a>:
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            <ul>
              <li>As a registered higher education provider in Australia SISTC is committed to operating in full compliance with all regulatory requirements including the TEQSA Act (2011); the Higher Education Standards Framework (Threshold Standards) (2021) and the ESOS Framework including the Education Services for Overseas Students Act (ESOS Act) and the National Code
                of Practice for Providers of Education and Training to Overseas Students 2018.
              </li>
              <li>SISTC is committed to Australia’s visa integrity program and upholding the reputation of Australia’s higher education system nationally and internationally.</li>
              <li>SISTC is committed to providing an equitable and supportive learning environment and recognise the rights of students, and the need to provide clear information, orientation, advice and support to all students.</li>
              <li>SISTC welcomes students regardless of cultural background, gender, sexuality, disability or age acknowledging that you have the right to study in an environment that is free from discrimination and harassment and be treated in a fair and considerate manner while you are studying with us. More information is provided in the SISTC Anti-discrimination Policy, the SISTC Equity and Diversity Policy, the SISTC Reasonable Adjustment Policy, the SISTC Bullying, Harassment and Violence Prevention and Response Policy and the SISTC Sexual Misconduct Prevention and Response Policy.</li>
              <li>SISTC has mechanisms for you to provide feedback as described in the SISTC Participation and Feedback Policy.</li>
              <li>Where needed, SISTC can recommend welfare and guidance services to students who may require it as described in the SISTC Student Support Policy.</li>
              <li>SISTC provides you with mechanisms to make a complaint and to review options for students under the SISTC Student Complaints, Appeals and Grievance Policy and Procedure (see: If you are not satisfied below).</li>
              <li>SISTC has developed the SISTC Critical Incident Policy and Procedure and is committed to the SISTC Health and Safety Policy and Framework.</li>
              <li>In accordance with SISTC Privacy Policy, SISTC will not disclose personal information about students to people, bodies or agencies outside SISTC including parents, spouses or other relatives or friends of the student, or to staff who have no need of the information, unless:<br />
                a) the student has given written permission for SISTC to disclose the information; <br />
                b) SISTC is required by law or by Commonwealth or State funding bodies to disclose the information; <br />
                c)SISTC has taken reasonable steps to inform the student that information of that kind is usually passed on to those people, bodies or agencies; 
                d) SISTC believes the disclosure is necessary to prevent or lessen a serious and imminent threat to the life or health of the individual concerned, or another person. SISTC also maintains accurate, confidential and secure education and financial records.
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#000', fontWeight: 600, paddingBottom: '10px'}}>
            Terms and Conditions
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 600, paddingTop: '10px', textDecoration: 'underline'}}>
            Validity of Offer
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            This Letter of Offer which contains your rights and responsibilities is valid for a period of (14) working days from the date of this document. This letter of offer is valid as acceptance into the SISTC as a full fee paying international student. Once accepted and initial payment has been paid, a confirmation of enrolment and invoice will be sent to you via email.
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 600, paddingTop: '10px', textDecoration: 'underline'}}>
            Duration of Enrolment
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            You are required to complete this course within the timeframe allocated as it appears on the first page of this Letter of Offer. If you require additional time to complete your studies (for example, due to compelling and compassionate circumstances) you must follow the requirements laid out in the SISTC Course Rules, Progression and Completion Policy.
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 600, paddingTop: '10px', textDecoration: 'underline'}}>
            Refund and Tuition Protection Policies
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            SISTC Fees, Charges and Refund Policy is attached. The policy on the refund of tuition fees has been determined in accordance with the National Code of Practice for Registration Authorities and Providers of Education and Training to Overseas Students (“the National Code 2018”), the Education Services for Overseas Students Act 2000 (“ESOS Act 2000”) and Education Services for Overseas Student Regulations 2001 (“ESOS Regulations 2001”).
          </td>
        </tr>
        <tr><td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            All tuition fees paid by the student are fully refundable within two weeks after the day on which SISTC is in default of its obligations under the National Code 2018. Tuition fees will be refunded in the following additional circumstances to international students:
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{paddingTop: '15px', paddingBottom: '20px'}}>
            <table style={{width: '100%'}}>
              <tbody><tr>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Notification Period</th>
                  <th style={{border: '1px solid #538135', padding: '8px', fontSize: '14px', background: '#46a06f', color: '#fff'}}>Eligible Refund</th>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>10 Weeks before commencement </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Full Refund of Tuition fees paid minus the relevant Cancellation Fee</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>4 weeks before commencement</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Refund is minus 20% of tuition fees paid for the teaching period and the relevant Cancellation Fee</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Less than 4 weeks and before commencement</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>Refund is minus 50% of tuition fees paid for the teaching period and the relevant Cancellation Fee</td>
                </tr>
                <tr>
                  <td colSpan={2} style={{border: '1px solid #000', padding: '8px', fontSize: '14px', fontWeight: 600, background: '#bbe1cc'}}>Notification to withdraw received after the commencement of the teaching period</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #46a06f', fontWeight: 600, textTransform: 'uppercase', padding: '8px', fontSize: '14px'}}>WITHDRAWAL FROM A UNIT</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>After 9.00am Monday of Week 1 of the teaching period </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>No refund</td>
                </tr>
                <tr>
                  <td style={{border: '1px solid #46a06f', fontWeight: 600, textTransform: 'uppercase', padding: '8px', fontSize: '14px'}}>WITHDRAWAL FROM COURSE</td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}} />
                </tr>
                <tr>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>After 9.00am Monday of Week 1 of the teaching period </td>
                  <td style={{border: '1px solid #538135', padding: '8px', fontSize: '14px'}}>No refund</td>
                </tr>
              </tbody></table>
          </td>
        </tr>
        <tr><td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            International Students Fees are protected by the Australian Government's Tuition Protection Service (TPS) as described in the SISTC Tuition Protection, Teach Out and Transition Policy which is also attached and provides details of the steps SISTC takes to protect your fees and ability to complete its courses in certain situations. In the unlikely event that the SISTC ceases to provide a course of study in which a student is enrolled, the student is entitled to a choice of:
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            <ul>
              <li>an offer of a place in a similar course of study with another higher education provider where possible, without any requirement to pay that provider any student contribution or tuition fee for any replacement units; or</li>
              <li> a refund of his or her pre-paid fees for any unit of study that the student commences but does not complete because of SISTC ceasing to provide the course of study of which the unit forms part.</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>SISTC has in place tuition insurance which protects student fees paid in advance through the
            Australian Government’s Tuition Protection Service (TPS) https://tps.gov.au. SISTC is committed to
            ensuring tuition assurance is current and available prior to enrolling students in all courses of study.
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            This written agreement, and the right to make complaints and seek appeals of decisions and action under various processes, does not affect the rights of the student to take action under Australian Consumer Law if it applies.
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#000', fontWeight: 600}}>If you are not satisfied</td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, lineHeight: '22px'}}>
            SISTC makes provision for all decisions/actions and matters affecting a student (including as it relates to payment of fees) to be independently reviewed under the SISTC Complaints, Appeals and Grievances Policy and Procedures. There is more information
          </td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#000', fontWeight: 600, borderBottom: '1px solid #262626', paddingTop: '20px', paddingBottom: '10px'}}>Acceptance Form</td>
        </tr>
        <tr>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', paddingTop: '8px', fontWeight: 600, paddingBottom: '15px'}}>Please complete this form and return it to SISTC within 14 calendar days of receipt.</td>
        </tr>
        <tr style={{paddingBottom: '60px'}}>
          <td colSpan={3} style={{fontSize: '14px', color: '#262626', fontWeight: 500, border: '1px solid #bbb', padding: '10px 12px'}}>
            &lt;
            <table>
              <tbody><tr>
                  <td style={{paddingBottom: '30px'}}>
                       have read and understood the information set out in this Letter of Offer.</td>
                </tr>
                <tr>
                  <td style={{paddingBottom: '30px'}} >By signing this application form, I agree to all the conditions of enrolment as well as any other conditions provided by SISTC.</td>
                </tr>
                <tr>
                  <td style={{paddingBottom: '20px'}}>Student Family Name:<input style={{border: 'none', borderBottom: '1px solid #626262'}} type="text" name /> Student Given Name(s):<input style={{border: 'none', borderBottom: '1px solid #626262'}} type="text" name /> </td>
                </tr>
                <tr>
                  <td style={{paddingBottom: '20px'}}>Signature:<input style={{border: 'none', borderBottom: '1px solid #626262'}} type="text" name /> Date:<input style={{border: 'none', borderBottom: '1px solid #626262'}} type="text" name /> </td>
                </tr>
                <tr>
                  <td style={{paddingBottom: '20px'}}>Please note: if you are under 18 years of age, your parent or guardian needs to sign this form.</td>
                </tr>
                <tr><td style={{paddingBottom: '20px'}}>Parent/Guardian Family Name: <input style={{border: 'none', borderBottom: '1px solid #626262'}} type="text" name /></td>
                </tr>
                <tr>
                  <td style={{paddingBottom: '20px'}}>Parent/Guardian Given Name(s): <input style={{border: 'none', borderBottom: '1px solid #626262'}} type="text" name /></td>
                </tr>
                <tr>
                  <td style={{paddingBottom: '20px'}}>Parent/Guardian Signature:<input style={{border: 'none', borderBottom: '1px solid #626262'}} type="text" name /> Date:<input style={{border: 'none', borderBottom: '1px solid #626262'}} type="text" name /></td>
                </tr>
              </tbody></table>
            </td>
        </tr>
      </tbody>
    </table>
    </div>
      <PrintButton refsToPrint={[tableRef]} style={{top: '20px'}} download/>
    </>
  );
}
