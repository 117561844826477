


import Footer from './Footer';
import React, { useEffect, useState } from "react";
import Sidebar from './Sidebar';
import Header from "../Admin/Header";
import Loader from '../loader/Loader';
import axios from 'axios';
import { Link, useLocation } from "react-router-dom";
import { Navigate, useNavigate } from 'react-router';
import Network from '../Network/Network';


 function View_applications_admins() {
    const navigate=useNavigate();    
    const location = useLocation();
    const param = new URLSearchParams(location.search).get("yaja");
    const userid= localStorage.getItem('token-info');
    const [data,setdata]=useState([]);
    const [error,seterror]=useState([]);
    const [loader,setloader]=useState(false);
    if(param==null)
    {
        navigate('/admin/Dashboard');
    }


 
    useEffect(() => {
                   
   get_application_information();
      },[]);

    const get_application_information= async()=>{
        setloader(true);
        if(param=='')
        {
            navigate('/admin/Dashboard');
        }
        const formdata=new FormData();
        formdata.append('userid',userid);
        formdata.append('id',param);
        axios.post(Network.Network.api_url+'get_application_information_for_admin',formdata)
        .then(response => {
             if(response.data.status=='success')
             {
                
                setdata(response.data.data[0]);
             }
             else
             {
                navigate('/admin/Dashboard');
  
             }
             setloader(false);
        })
        .catch(error => {
         seterror(error);
        }); 
    } 


  return (
       <><Header/><Sidebar />
       {loader ? <Loader></Loader> : ''}
       { data !=='' ? 
        <main id="main" className="main">
       <div className="pagetitle">
         <h1>Application </h1>
       </div>
   
       <section className="section profile">
         <div className="row">
           <div className="col-xl-12">
   
             <div className="card">
               <div className="card-body pt-3">
                 <ul className="nav nav-tabs nav-tabs-bordered">
   
                   <li className="nav-item">
                     <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                   </li>
                 </ul>
                 <div className="tab-content pt-2">
   
                   <div className="tab-pane fade show active profile-overview" id="profile-overview">
                     {/* <h5 className="card-title">About</h5>
                     <p className="small fst-italic">Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.</p> */}
   
                     <h5 className="card-title">Application Details</h5>
                     <div className="row">
                     <div className="col-md-4 col-lg-4">  
                     <div className="row">
                       <div className="col-lg-6 label ">Application Id	</div>
                       <div className="col-lg-6 text-end">{data.application_id}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">First Name</div>
                       <div className="col-lg-6 text-end">{data.first_name}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Last Name</div>
                       <div className="col-lg-6 text-end">{data.last_name}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Gender	</div>
                       <div className="col-lg-6 text-end">{data.gender}</div>
                     </div> 
                     </div>

                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Marital Status	</div>
                       <div className="col-lg-6 text-end">{data.marital_status}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Student Country	</div>
                       <div className="col-lg-6 text-end">{data.student_country}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Student State	</div>
                       <div className="col-lg-6 text-end">{data.student_state}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Student City	</div>
                       <div className="col-lg-6 text-end">{data.student_city}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Address	</div>
                       <div className="col-lg-6 text-end">{data.address}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Area	</div>
                       <div className="col-lg-6 text-end">{data.area}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Postal Zipcode	</div>
                       <div className="col-lg-6 text-end">{data.postal_zipcode}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Primary Email	</div>
                       <div className="col-lg-6 text-end">{data.primary_email}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Country code	</div>
                       <div className="col-lg-6 text-end">{data.country_code}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Mobile Number	</div>
                       <div className="col-lg-6 text-end">{data.mobile_number}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Father Occupation	</div>
                       <div className="col-lg-6 text-end">{data.father_occupation}</div>
                     </div> 
                     </div>
                     {/* <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Family Income	</div>
                       <div className="col-lg-6 text-end">{data.family_income}</div>
                     </div> 
                     </div> */}
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">College Name	</div>
                       <div className="col-lg-6 text-end">{data.college_name}</div>
                     </div> 
                     </div>
                     { data.programs ?
                      data.programs.map((e,i)=>{

                      
                     
                     return( <><div key={i} className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Course Name	</div>
                       <div className="col-lg-6 text-end">{e.course_name}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Program Intake	</div>
                       <div className="col-lg-6 text-end">{e.weeks}  {e.month}</div>
                     </div> 
                     </div></>)
                    })
                     
                      : '---' }
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Availability	</div>
                       <div className="col-lg-6 text-end">{data.shore}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Is this a pathway ?	</div>
                       <div className="col-lg-6 text-end">{data.pathway}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Is this a COE ?	</div>
                       <div className="col-lg-6 text-end">{data.coe}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Availability Country	</div>
                       <div className="col-lg-6 text-end">{data.shore_country}</div>
                     </div> 
                     </div>
                     {/* <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Upload Visa details	</div> */}
                       {/* <div className="col-lg-6 text-end">???????</div>
                        */}
                         {/* <button onClick={handleDownload}>Download PDF</button> */}
                         {/* <div className="col-lg-6 text-end"> <a className="btn-primary" href={`https://enrolguru.com/enrolguru_backend/api/images/${data.visa_details_upload}`} target="_blank" download>Download</a></div> */}
                     {/* </div> 
                     </div> */}
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Visa Expiry date	</div>
                       <div className="col-lg-6 text-end">{data.visa_details_expire_date}</div>
                     </div> 
                     </div>
                     {/* <div className="col-md-4 col-lg-4">  */}
                     {/* <div className="row"> */}
                       {/* <div className="col-lg-6 label ">Upload Policy	</div> */}
                       {/* <div className="col-lg-6 text-end">?????????????????</div> */}
                       {/* <div className="col-lg-6 text-end"> <a className="btn-primary" href={`https://enrolguru.com/enrolguru_backend/api/images/${data.policy_upload}`} target="_blank" download>Download</a></div> */}
                     {/* </div>  */}
                     {/* </div> */}


                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Policy Expiry date	</div>
                       <div className="col-lg-6 text-end">{data.policy_expire_date}</div>
                     </div> 
                     </div>


                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Test Type	</div>
                       <div className="col-lg-6 text-end">{data.test_score}</div>
                     </div> 
                     </div>
                     {/* <div className="col-md-4 col-lg-4">  */}
                     {/* <div className="row"> */}
                       {/* <div className="col-lg-6 label ">Upload Test	</div> */}
                       {/* <div className="col-lg-6 text-end"></div> */}
                       {/* <div className="col-lg-6 text-end">   <a className="btn-primary" href={`https://enrolguru.com/enrolguru_backend/api/images/${data.upload_ielts}`} target="_blank" download>Download</a></div> */}
                     {/* </div>  */}
                     {/* </div> */}
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Overall Score	</div>
                       <div className="col-lg-6 text-end">{data.overall_score}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Listing Score	</div>
                       <div className="col-lg-6 text-end">{data.listing_score}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Reading Score	</div>
                       <div className="col-lg-6 text-end">{data.reading_score}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Writing Score	</div>
                       <div className="col-lg-6 text-end">{data.writing_score}</div>
                     </div> 
                     </div>
                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Speaking Score	</div>
                       <div className="col-lg-6 text-end">{data.speaking_score}</div>
                     </div> 
                     </div>
                    
            
                     {/* <div className="col-md-4 col-lg-4">  */}
                     {/* <div className="row"> */}
                       {/* <div className="col-lg-6 label ">Other Doc	</div> */}
                       {/* <div className="col-lg-6 text-end">??????</div> */}
                       {/* <div className="col-lg-6 text-end">  <a className="btn-primary" href={`https://enrolguru.com/enrolguru_backend/api/images/${data.other_doc}`} target="_blank" download>Download</a></div> */}
                     {/* </div>  */}
                     {/* </div> */}
                     {/* <div className="col-md-4 col-lg-4">  */}
                     {/* <div className="row"> */}
                       {/* <div className="col-lg-6 label ">Upload Academics	</div> */}
                       {/* <div className="col-lg-6 text-end">????????????????</div> */}
                       {/* <div className="col-lg-6 text-end"><a className="btn-primary" href={`https://enrolguru.com/enrolguru_backend/api/images/${data.upload_academics}`} target="_blank" download>Download</a></div> */}
                     {/* </div>  */}
                     {/* </div> */}



                     {/* <div className="col-md-4 col-lg-4">  */}
                     {/* <div className="row"> */}
                       {/* <div className="col-lg-6 label ">Upload Passport */}
	                   {/* </div> */}
                       {/* <div className="col-lg-6 text-end">???????????</div> */}
                       {/* <div className="col-lg-6 text-end"> <a className="btn-primary" href={`https://enrolguru.com/enrolguru_backend/api/images/${data.upload_passport}`} target="_blank" download>Download</a></div> */}
                     {/* </div>  */}
                     {/* </div> */}


                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Which Country Passport	</div>
                       <div className="col-lg-6 text-end">{data.country_passport}</div>
                     </div> 
                     </div>


                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Passport Number	</div>
                       <div className="col-lg-6 text-end">{data.passport_number}</div>
                     </div> 
                     </div>


                     <div className="col-md-4 col-lg-4"> 
                     <div className="row">
                       <div className="col-lg-6 label ">Create At</div>
                       <div className="col-lg-6 text-end">{data.created_at}</div>
                     </div> 
                     </div>
                     </div>
                     </div>
                     

                    

                 </div>
               </div>
             </div>
   
           </div>
         </div>
       </section>
   
     </main>
 : <Loader></Loader> }</>
  )
}

export default  View_applications_admins;










