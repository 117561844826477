import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import firebase from './component/firebase_config/firebase';
import AdminLogin from './component/Admin/Login';
import CollegeLogin from './component/College/Login';
import Dashboard from './component/Admin/Dashboard';
import All_applications from './component/Admin/All_applications';
import Offerletter from './component/Chat/Offerletter';
import Edit_agent from './component/Admin/Edit_agent';
import Mystaff from './component/Admin/Mystaff';
import Home from './component/Admin/home';






import CollegeAdd from './component/Admin/Collegeadd';
import CollegeList from './component/Admin/Collegelist';
import CollegeApplicationList from './component/College/CollegeApplicationList';
import CollegeDashboard from './component/College/Dashboard';
import CollegeAddprogram from './component/College/Addprogram';
import Myprofile from './component/College/Myprofile';
import firebase from './component/firebase_config/firebase.js';
import AgentAdd from './component/Admin/Agentadd';
import AgentList from './component/Admin/Agentlist';
import ProgramList from './component/College/Programlist';


// agent
import Addapplication from './component/Agent/Addapplication';
import Application_list from './component/Agent/Application_list';
import AgentDashboard from './component/Agent/AgentDashboard';
import AgentLogin from './component/Agent/AgentLogin';
import Append from './component/Agent/Append';
import View_application from './component/Agent/View_application';
import College_viewApplication from './component/College/College_viewApplication';
import View_applications_admins from './component/Admin/View_applications_admins';
import Chat from './component/Chat/Chat';
// import ChatRoom from './component/Chat/Chat1';
import Logout from './component/logout/Logout';
import New_staff from './component/Admin/New_staff';
import Addapplication1 from './component/Agent/Addapplication1';



function App() {
  
  return (
    <>
      <BrowserRouter>
        <Routes>
           <Route path="/" element={<Home />}/>

           {/* Chat  */}
           <Route path="/chat/chat" element={<Chat />} />
           <Route path="/chat/offerletter" element={<Offerletter />} />



           {/* /// */}
     
          {/* logouts */}
          <Route path="/logout/logoutuser" element={<Logout />} />
          {/* end */}

          {/* admin routes */}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/collegelist" element={<CollegeList />} />
          <Route path="/admin/agentadd" element={<AgentAdd />} />
          <Route path="/admin/agentlist" element={<AgentList />} />
          <Route path="/admin/Dashboard" element={<Dashboard />} />
          <Route path="/admin/collegeadd" element={<CollegeAdd />} />
          <Route path="/admin/all_applications" element={<All_applications />} />
          <Route path="/admin/view_applications_admins" element={<View_applications_admins />} />
          <Route path="/admin/New_staff" element={<New_staff />} />
          <Route path="/admin/mystaff" element={<Mystaff />} />
          <Route path="/admin/Edit_agent/:id" element={<Edit_agent />} />

          

          {/* college */}

          <Route path="/college/programlist" element={<ProgramList />} />
          <Route path="/college/applicationlist" element={<CollegeApplicationList />} />
          <Route path="/college/login" element={<CollegeLogin />} />
          <Route path="/College/Dashboard" element={<CollegeDashboard />} />
          <Route path="/college/addprogram" element={<CollegeAddprogram />} />
          <Route path="/college/myprofile" element={<Myprofile />} />
          <Route path="/college/viewapplications_data" element={<College_viewApplication />} />


          {/* agent */}
          <Route path="/agent/applicationlist" element={<Application_list />} />
          <Route path="/agent/login" element={<AgentLogin />} />
          <Route path="/agent/Dashboard" element={<AgentDashboard />} />
          <Route path="/agent/addapplication" element={<Addapplication />} />
          <Route path="/agent/addapplication1" element={<Addapplication1 />} />
          <Route path="/agent/Append" element={<Append />} />
          <Route path="/agent/viewapplication" element={<View_application />} />
        

          {/* front routes */}
          </Routes>
        </BrowserRouter>
          </>
  );
}

export default App;
