
import Header from "../Admin/Header";
import Footer from './Footer';
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
import Loader from '../loader/Loader';
import Network from "../Network/Network";
function Edit_agent() {
     const navigate=useNavigate();
    const onSubmit = (e) => {
        e.preventDefault();
        sendDetailsToServer();
    }
    const [loader,setloader]=useState(false);
    const [successmessage, setstateforsuccess] = useState('');
    const [state, setState] = useState({
        
    gender: "",
    country_code: "",
    first_name: "",
    last_name: "",
    company_name: "",
    country: "",
    state: "",
    city: "",
    primary_email: "",
    referby: "",
    designation: "",
    commission: "",
    primary_number: "",
    id_proof: "",
    upload_certificate: "",
    user_photo: "",
    })

    const [file, setFile] = useState()
    const [file1, setFile1] = useState()
    const [file2, setFile2] = useState()
    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }

    const handleChange1 = (e) => {
        const { name, value } = e.target
        setFile(e.target.files[0])

    }
    const handleChange2 = (e) => {
        const { name, value } = e.target
        setFile1(e.target.files[0])

    }
    const handleChange3 = (e) => {
        const { name, value } = e.target
        setFile2(e.target.files[0])

    }

    const sendDetailsToServer = async () => {
        // alert('test');
           setloader(true);


           
        let formdata = new FormData();

        formdata.append('gender', state.gender);
        formdata.append('country_code', state.country_code);
        formdata.append('first_name', state.first_name);
        formdata.append('last_name', state.last_name);
        formdata.append('company_name', state.company_name);
        formdata.append('country', state.country);
        formdata.append('state', state.state);
        formdata.append('city', state.city);
        formdata.append('primary_email', state.primary_email);
        formdata.append('referby', state.referby);
        formdata.append('designation', state.designation);
        formdata.append('commission', state.commission);
        formdata.append('primary_number', state.primary_number);
        
        if (file != undefined) {
            formdata.append('id_proof', file);
            console.log("file", file)
        }
        if (file1 != undefined) {
            formdata.append('upload_certificate', file1);
        }
        if (file2 != undefined) {
            formdata.append('user_photo', file2);
        }


        axios.post(Network.Network.api_url+"addagent", formdata)
            .then(function (response) {
                console.log('response', response.data);
                if (response.data.status == 'success') {
                    setloader(false);
                    setstateforsuccess(" Add Successfully")
                    alert(response.data.message);
                    navigate('/admin/agentlist');

                } else {
                    setstateforsuccess(response.data.message)
                    alert(response.data.message);
                    setloader(false);

                }
              
            })
            // setloader(false);
    }
    return (<>
        <Header />
        <Sidebar />
        {/* <Loader></Loader> */}
        { loader==true ? <Loader></Loader> : ''}
        <main id="main" className="main">
            <p>{successmessage ? successmessage : ''}</p>
            <section className="section collegeadd">
                <form onSubmit={onSubmit} >
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    
                                    <h5 className="card-title">Update  Agent details</h5>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">First Name</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="first_name" value={state.first_name} required onChange={handleChange} placeholder="First Name"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Last Name</label>
                                        <div className="col-sm-9">
                                            <input className="form-control" type="text" id="formFile" name="last_name" value={state.last_name} required onChange={handleChange} placeholder="Last Name"/>
                                        </div>
                                    </div>
                                  

                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Company Name</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="company_name" value={state.company_name} onChange={handleChange} placeholder="Company Name "/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Country</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="country" value={state.country} onChange={handleChange}  placeholder="Country"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">State</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="state" onChange={handleChange} value={state.state}  placeholder="State"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">City</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="city" onChange={handleChange} value={state.city} placeholder="City"/>
                                        </div>
                                    </div>
                                    
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Refer By</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="referby" onChange={handleChange} value={state.referby} placeholder="Refer by"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                         <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <br/>
                                <br/>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Designation</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="designation" onChange={handleChange} value={state.designation}  placeholder="Designation"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Commission</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="commission" onChange={handleChange} value={state.commission}  placeholder="Commission"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Primary Email</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="primary_email" onChange={handleChange} value={state.primary_email} placeholder="Primary Email"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Primary Number</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="primary_number" onChange={handleChange} value={state.primary_number} placeholder="Primary Number"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Id Proof</label>
                                        <div className="col-sm-9">
                                            <input type="file" className="form-control" name="id_proof" onChange={handleChange1} value={state.id_proof} placeholder="Id Proof"/>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">Logo</label>
                                        <div className="col-sm-9">
                                            <input type="file" className="form-control" name="upload_certificate" onChange={handleChange2} value={state.upload_certificate} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-sm-3 col-form-label">User Photo</label>
                                        <div className="col-sm-9">
                                            <input type="file" className="form-control" name="user_photo" onChange={handleChange3} value={state.user_photo} />
                                        </div>
                                    </div>   
                            </div>
                        </div>
                    </div> 
                        <div className="row mb-3">

                            <div className="col-sm-10">
                                <button type="submit" className="btn btn-primary">Submit Form</button>
                            </div>
                        </div>

                    </div>
                </form>
            </section>
        </main>
        <Footer />
    </>)



}


export default Edit_agent;