
import React, { useEffect, useState } from "react";
import Notification from "../Notification/Notification";
function Header() {
  const [profilename, setstateforname] = useState('');
  useEffect(() => {
    // agent_oradmin_profile();
    console.log("token-info", localStorage.getItem('token-info'));
    console.log("token-name", localStorage.getItem('token-name'));
    setstateforname(localStorage.getItem('token-name'))
  }, []
  );
    return (<>
  <header id="header" className="header fixed-top d-flex align-items-center">

<div className="d-flex align-items-center justify-content-between">
  <a href="#" className="logo d-flex align-items-center">
    <img src="assets/img/logo.png" alt=""/>
    <span className="d-none d-lg-block" style={{color: "black"}}>Enrolguru</span>
  </a>
  {/* <i className="bi bi-list toggle-sidebar-btn"></i> */}
</div>

{/* <div className="search-bar">
  <form className="search-form d-flex align-items-center" method="POST" action="#">
    <input type="text" name="query" placeholder="Search" title="Enter search keyword"/>
    <button type="submit" title="Search"><i className="bi bi-search"></i></button>
  </form>
</div> */}

<nav className="header-nav ms-auto">
  <ul className="d-flex align-items-center">

    <li className="nav-item d-block d-lg-none">
      <a className="nav-link nav-icon search-bar-toggle " href="#">
        <i className="bi bi-search"></i>
      </a>
    </li>

    <li className="nav-item dropdown">
       <Notification></Notification>
    </li>

    {/* <li className="nav-item dropdown">

      <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
        <i className="bi bi-chat-left-text"></i>
        <span className="badge bg-success badge-number">3</span>
      </a>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
        <li className="dropdown-header">
          You have 3 new messages
          <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li className="message-item">
          <a href="#">
            <img src="assets/img/messages-1.jpg" alt="" className="rounded-circle"/>
            <div>
              <h4>Maria Hudson</h4>
              <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
              <p>4 hrs. ago</p>
            </div>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li className="message-item">
          <a href="#">
            <img src="assets/img/messages-2.jpg" alt="" className="rounded-circle"/>
            <div>
              <h4>Anna Nelson</h4>
              <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
              <p>6 hrs. ago</p>
            </div>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li className="message-item">
          <a href="#">
            <img src="assets/img/messages-3.jpg" alt="" className="rounded-circle"/>
            <div>
              <h4>David Muldon</h4>
              <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
              <p>8 hrs. ago</p>
            </div>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li className="dropdown-footer">
          <a href="#">Show all messages</a>
        </li>

      </ul>

    </li> */}

    <li className="nav-item dropdown pe-3">

      <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
        {/* <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle"/> */}
            <span className="d-none d-md-block dropdown-toggle ps-2">{profilename}</span>
      </a>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li className="dropdown-header">
              <h6>{profilename}</h6>
          {/* <span>Admin</span> */}
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        {/* <li>
          <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
            <i className="bi bi-person"></i>
            <span>My Profile</span>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="users-profile.html">
            <i className="bi bi-gear"></i>
            <span>Account Settings</span>
          </a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
          <a className="dropdown-item d-flex align-items-center" href="pages-faq.html">
            <i className="bi bi-question-circle"></i>
            <span>Need Help?</span>
          </a>
        </li> */}
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li>
              {/* <a className="dropdown-item d-flex align-items-center" href="/admin/login"> */}
<a className="dropdown-item d-flex align-items-center" href="/logout/logoutuser">
            <i className="bi bi-box-arrow-right"></i>
            <span>Sign Out</span>
          </a>
        </li>

      </ul>
    </li>

  </ul>
</nav>

</header>

    </>)
}


export default Header;

