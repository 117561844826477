import Header from "../Admin/Header";
// import { Link } from 'react-router-dom';
import Footer from './Footer';
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import Sidebar from './Sidebar';
// import Header from './Header';
import Loader from '../loader/Loader';
import { Navigate } from 'react-router';
import Network from "../Network/Network";


function Collegeadd() {
    const [desh,setdesh]=useState([]);
    const navigate=useNavigate();
    const onSubmit = (e) => {
        e.preventDefault();
        sendDetailsToServer();
    }
  useEffect(() => {
    country_list();
  },[]);
    const country_list = async () => {
        axios.post(Network.Network.api_url+"country_list")
            .then(function (response) {
             setdesh(response.data.data)

            })

    }

    const [successmessage, setstateforsuccess] = useState('');
    const [loader,setloader]=useState(false);
    const [state, setState] = useState({
        collegename: "",
        collegeemail: "",
        collegenumber: "",
        collegeadress: "",
        universityname: "",
        weblink: "",
        applicationfees: "",
        provinces: "",
        gallery: "",
        city: "",
        logo: "",
        photo: "",
        represent: "",
        campus: "",
        representname: "",
        representemail: "",
        campus: "",
        year: "",
        intakes: "",
        information: "",
        country_id:"",
        college_weblink:"",
    })

    const [file, setFile] = useState()
    const [file1, setFile1] = useState()
    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }

    const inputsHandler1 = (e) => {
        const { name, value } = e.target
        setFile(e.target.files[0])
        setState(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }
    const inputsHandler2 = (e) => {
        const { name, value } = e.target
        setFile1(e.target.files[0])
        setState(prevState => ({
            ...prevState,
            [name]: value,
        }))


    }

    const sendDetailsToServer = async () => {
        setloader(true);
        let formdata = new FormData();
        
        formdata.append('college_name', state.collegename);
        formdata.append('college_email', state.collegeemail);
        formdata.append('college_phone', state.collegenumber);
        formdata.append('country_id', state.country_id);
        formdata.append('college_weblink', state.college_weblink);



        formdata.append('college_address', state.collegeadress);
        if (file1 != undefined) {
            formdata.append('college_photo', file1);
        }

        if (file != undefined) {
            formdata.append('college_logo', file);
            console.log("file", file)
        }
        axios.post(Network.Network.api_url+"addnewcollege", formdata)
            .then(function (response) {
                if (response.data.status == 'success') {
                    setstateforsuccess("College Add Successfully")
                    alert(response.data.message);
                    navigate('/admin/collegelist');
                    setloader(false);


                } else {
                    setstateforsuccess(response.data.message);
                    alert(response.data.message);
                    setloader(false);
                }
            })
            
    }

    

    return (<>
        <Header />
        <Sidebar />
        {loader ? <Loader></Loader> : ''}

        <link rel="stylesheet" href="/../adminasset/css/style.css" />
        <link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" relation="stylesheet" type="text/css" />
       

            <main id="main" class="main">

                <div class="pagetitle">
                
                <p>{successmessage ? successmessage : ''}</p>
                    {/* <h1>College Add</h1> */}
                    {/* <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="breadcrumb-item">Forms</li>
                            <li class="breadcrumb-item active">Elements</li>
                        </ol>
                    </nav> */}
                </div>

                <section class="section collegeadd">
                <form onSubmit={onSubmit} >
                    <div class="row">
                        
                        <div class="col-lg-6">

                            <div class="card ">
                                <div class="card-body">
                                    <h5 class="card-title">Add College </h5>
                                  

                                     <div class="row mb-3">
                                        <label for="inputText" class="col-sm-3 col-form-label">Country</label>
                                            <div class="col-sm-9">
                                            <select className="form-control" name="country_id" value={state.country_id} onChange={handleChange}  required> 
                                                <option value=""  disabled>--select--</option>
                                                    {desh.length > 0 ? desh.map((e, key) => {
                                                             return <option key={key} value={e.id}>{e.country_name}</option>;
                                                         }) : ""}  


                                            </select>
                                            </div>
                                        </div>
                                    
                                        <div class="row mb-3">
                                        <label for="inputText" class="col-sm-3 col-form-label">College Name</label>
                                            <div class="col-sm-9">
                                            <input type="text" class="form-control" name="collegename" value={state.collegename} required onChange={handleChange} placeholder="College Name" />
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                        <label for="inputEmail" class="col-sm-3 col-form-label">College Email</label>
                                            <div class="col-sm-9">
                                            <input type="email" class="form-control" name="collegeemail" value={state.collegeemail} required onChange={handleChange}  placeholder="College Email"/>
                                            </div>
                                        </div>
                                        
                                        <div class="row mb-3">
                                        <label for="inputNumber" class="col-sm-3 col-form-label">College Number</label>
                                            <div class="col-sm-9">
                                            <input type="number" class="form-control" name="collegenumber" value={state.collegenumber} required onChange={handleChange} placeholder="College Number"/>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                        <label for="inputNumber" class="col-sm-3 col-form-label">College Address</label>
                                            <div class="col-sm-9">
                                            <input class="form-control" type="text" id="formFile" name="collegeadress" value={state.collegeadress} required onChange={handleChange} placeholder="College Address"/>
                                            </div>
                                        </div>


                                        <div class="row mb-3">
                                        <label for="inputNumber" class="col-sm-3 col-form-label">College Website Url</label>
                                            <div class="col-sm-9">
                                            <input class="form-control" type="text" id="formFile" name="college_weblink" value={state.college_weblink} required onChange={handleChange} placeholder="College Website Url"/>
                                            </div>
                                        </div>
                                       

                                    <div class="row mb-3">
                                        <label for="inputEmail" class="col-sm-3 col-form-label">College Logo</label>
                                        <div class="col-sm-9">
                                            <input type="file" class="form-control" name="logo" value={state.logo}  onChange={inputsHandler1}  required/>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="inputPassword" class="col-sm-3 col-form-label">College Photo</label>
                                        <div class="col-sm-9">
                                            <input type="file" class="form-control" name="photo" onChange={inputsHandler2}  value={state.photo}   required/>
                                        </div>
                                    </div>

                                    

                                </div>
                            </div>

                        </div>

                        

                    {/* <div class="col-lg-6">

                        <div class="card">
                            <div class="card-body">
                                <br/>
                                <br/>
                                
                                


                               
                                    <div class="row mb-3">
                                        <label for="inputText" class="col-sm-2 col-form-label">City</label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" name="city" value={state.city} required onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="inputEmail" class="col-sm-2 col-form-label">College Logo</label>
                                        <div class="col-sm-10">
                                            <input type="file" class="form-control" name="logo" value={state.logo} required onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">College Photo</label>
                                        <div class="col-sm-10">
                                            <input type="file" class="form-control" name="photo" value={state.photo}  required onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="inputNumber" class="col-sm-2 col-form-label">Country Id</label>
                                        <div class="col-sm-10">
                                            <input type="number" class="form-control" required onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="inputNumber" class="col-sm-2 col-form-label">Representative Details</label>
                                        <div class="col-sm-10">
                                            <input class="form-control" type="text" name="representname" value={state.representname} id="formFile" required onChange={handleChange} />
                                            <input class="form-control" type="email" name="representname" value={state.representemail} id="formFile" required onChange={handleChange} />
                                        </div>
                                        
                                    </div>
                                    <div class="row mb-3">
                                        <label for="inputDate" class="col-sm-2 col-form-label">Campus</label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" name="campus" value={state.campus} required onChange={handleChange} />
                                            <input type="text" class="form-control" name="campus" value={state.campus} required onChange={handleChange} />
                                        </div>
                                    </div>
                                    
                                    <div class="row mb-3">
                                        <label for="inputTime" class="col-sm-2 col-form-label">Year</label>
                                        <div class="col-sm-10">
                                            <select name="year" value={state.year} required onChange={handleChange}>
                                                <option>2023</option>
                                                <option>2024</option>
                                                <option>2025</option>
                                                <option>2026</option>
                                                <option>2027</option>
                                                
                                            </select>

                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="inputTime" class="col-sm-2 col-form-label">Intakes</label>
                                        <div class="col-sm-10">
                                            <select name="intakes"  multiple required onChange={handleChange}>
                                                <option value="jan">Jan</option>
                                                <option value="jan">Feb</option>
                                                <option value="jan">March</option>
                                                <option value="jan">April</option>
                                                <option value="jan">May</option>
                                                
                                            </select>
                                          
                                        </div>
                                    </div>

                                    
                                    <div class="row mb-3">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Additional Information</label>
                                        <div class="col-sm-10">
                                            <textarea class="form-control" name="information" value={state.information} onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    
                                   


                                    

                                

                            </div>
                        </div>

                    </div> */}
                        <div class="row mb-3">

                            <div class="col-sm-10">
                                <button type="submit" class="btn btn-primary">Submit Form</button>
                            </div>
                        </div>
                    
                    </div>
                </form>
                </section>

            </main>
        
        {/* <Forgotpasswordlogin /> */}
        <Footer />
    </>)



}


export default Collegeadd;