import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import firebase from 'firebase'
export default function Notification() {

    const [Notification,setNotification]=useState([]);
    const count=Notification.length;
    // console.log('fghgf',Notification);

    const user_id = localStorage.getItem('token-info');
    const user_type = localStorage.getItem('user_type');
    useEffect(() => {
        // if(user_type!=='Admin')
        // {
            get_notification();
        // }
         
    }, []);

    const get_notification=()=>{
         try{
            const db = firebase.database();


            const chatRef = db.ref(`notification/${user_id}`);
    
            chatRef.on("value", (snapshot) => {
                const data = snapshot.val();
                const  sortedNotifications=Object.values(data || []);
                console.log("sortednotification", sortedNotifications);
                const allNotification=sortedNotifications?.sort((a, b) => b.time - a.time);
                    setNotification(allNotification);
    
    
            });  
         }
         catch(e){
          console.log('emeeages',e.message);
         }
        
    }
  return (
    <div>                                           
    <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                <i className="bi bi-bell"></i>
                <span className="badge bg-primary badge-number"> {count}</span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">                                               
    <li className="dropdown-header">
                You have {count} new notifications
                <a href="#">
                    <span className="badge rounded-pill bg-primary p-2 ms-2">View all</span>
                </a>
                </li>
                <li>
                <hr className="dropdown-divider"/>
                </li>                                                           
   {
       count > 0 ?
        Notification.map((e,i) => (
       <>
       <li key={i} className="notification-item">
        <i className="bi bi-exclamation-circle text-success"></i>
        <div>
            <h4>{e.id}</h4>
            {/* {`/chat/chat?id=${e.application_id}`} */}
            <p><Link  to="#">{e.noti}</Link></p>
            <p>{new Date(e.time).toLocaleString("en-US", { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true })}</p>
        </div>
         </li>
        <li>
            <hr className="dropdown-divider" />
        </li></>
      ))
      : 
      ""} 
       </ul>                                          
      

            
    </div>
  )
}
