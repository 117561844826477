
import Header from "../Admin/Header";
import Footer from './Footer';
import axios from "axios";

import React, { useEffect, useState } from "react";
import Sidebar from './Sidebar';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Loader from '../loader/Loader';
import { Link } from "react-router-dom";
import Network from "../Network/Network";
function AgentList() {
    const [loader,setloader]=useState(false);

    const [agentlistdata, setstateforagentlist] = useState([]);
    console.log('agentlistdata',agentlistdata);
    useEffect(() => { sendDetailsToServer(); }, []);
    const sendDetailsToServer = async () => {
        setloader(true);
        axios.post(Network.Network.api_url+"agent_list")
            .then(function (response) {
                setloader(false);
                setstateforagentlist(response.data.data)
            })

    }
    setTimeout(function(){
        $('.table').DataTable();
         } ,2000);
    return (<>
        <Header />
        <Sidebar />
        {loader==true ? <Loader></Loader> :''}
        <main id="main" class="main">
            <section class="section Applicationmain"><h5 class="card-title">Agent List</h5><div class="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns"><div class="dataTable-container">

                <table class="table datatable dataTable-table">
                    <thead>
                        <tr><th>Id</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Company</th>
                        <th>Country</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Eamil</th>
                        <th>Number</th>
                        {/* <th>Edit</th> */}
                        
                        </tr>

                    </thead>
                    <tbody>
                       
                        {agentlistdata.length > 0 ? agentlistdata.map((items, i) => {
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{items.first_name}</td>
                                    <td>{items.last_name}</td>
                                    <td>{items.company_name}</td>
                                    <td>{items.country==null ? '' : items.country}</td>
                                    <td>{items.state}</td>
                                    <td>{items.city}</td>
                                    <td>{items.primary_email}</td>
                                    <td>{items.primary_number}</td>
                                    {/* <td><Link  to={`/admin/Edit_agent/${items.id}`}>Update details</Link></td> */}
                                </tr>
                            )
                        })

                            : ""}

                    </tbody>
                </table>

            </div></div></section></main>
        <Footer />
    </>)
}

export default AgentList;

