import React from "react";
 function Loader() {
  return (

<div className="loaderss">
  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </div>
  )
}
export default Loader;

