import React from 'react'
export default function Graduate({ sendDataToParent, color, errors}) {
  
    return (<>
        <div className="row">
        <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>upload class 10th or equivalent academic transcript <span style={{color: "red"}}>*</span><h6>{errors.twelve_doc}</h6></label>
        <div className="col">

        <input type="file" accept=".pdf" className="form-control" name="tenth_doc" onChange={(e) => {
            sendDataToParent(e.target.files[0],'twelve_doc');
         }} />
        </div>
    </div>
       <div className='row'>
          <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Board of Education <span style={{color: "red"}}>*</span><h6>{errors.tenth_board_of_education}</h6></label>
          <div className='col'>
             <input type="text" className="form-control" name="boardOfEducation" placeholder='Board of Education' onChange={(e) => {
                 sendDataToParent(e.target.value, "tenth_board_of_education");
             }} />
          </div>
      </div> 
 <div className='row'>
          <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Year of Pass <span style={{color: "red"}}>*</span><h6>{errors.tenth_year_of_pass}</h6></label>
          <div className='col'>
             <input type="text" className="form-control" name="yearOfPass" placeholder='Year of Pass' onChange={(e) => {
                 sendDataToParent(e.target.value, "tenth_year_of_pass");
             }} />
          </div>
      </div> 
      <div className='row'>
          <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Country <span style={{color: "red"}}>*</span><h6>{errors.tenth_country}</h6></label>
          <div className='col'>
             <input type="text" className="form-control" name="boardOfCountry" placeholder='Country' onChange={(e) => {
                 sendDataToParent(e.target.value, "tenth_country");
             }} />
          </div>
      </div>
      <div className='row'>
          <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Best of 4 Core Subject % Upon <span style={{color: "red"}}>*</span><h6>{errors.tenth_4_core_subject}</h6></label>
          <div className='col'>
             <input type="text" className="form-control" name="bestOfCoreSubject" placeholder='Best of 4 Core Subject % Upon' onChange={(e) => {
                 sendDataToParent(e.target.value, "tenth_4_core_subject");
             }} />
          </div>
      </div>
    <div className="row">
            <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Upload class 12th or equivalent academic transcript  <span style={{color: "red"}}>*</span><h6>{errors.twelve_doc}</h6></label>
            <div className="col-sm-9">
                <input type="file" accept=".pdf" className="form-control" name="twelve_doc" onChange={(e) => {
            sendDataToParent(e.target.files[0],'twelve_doc');
           }}/>
            </div>
        </div>
      
         <div className='row'>
          <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Board of Education <span style={{color: "red"}}>*</span><h6>{errors.twelve_board_of_education}</h6></label>
          <div className='col'>
             <input type="text" className="form-control" name="boardOfEducation" placeholder='Board of Education' onChange={(e) => {
                 sendDataToParent(e.target.value, "twelve_board_of_education");
             }} />
          </div>
      </div> 
     <div className='row'>
          <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Year of Pass <span style={{color: "red"}}>*</span><h6>{errors.twelve_year_of_pass}</h6></label>
          <div className='col'>
             <input type="text" className="form-control" name="yearOfPass" placeholder='Year of Pass' onChange={(e) => {
                 sendDataToParent(e.target.value, "twelve_year_of_pass");
             }} />
          </div>
      </div> 
     <div className='row'>
          <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Country <span style={{color: "red"}}>*</span><h6>{errors.twelve_country}</h6></label>
          <div className='col'>
             <input type="text" className="form-control" name="boardOfCountry" placeholder='Country' onChange={(e) => {
                 sendDataToParent(e.target.value, "twelve_country");
             }} />
          </div>
      </div>
 <div className='row'>
          <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`} >Best of 4 Core Subject % Upon <span style={{color: "red"}}>*</span><h6>{errors.twelve_4_core_subject}</h6></label>
          <div className='col'>
             <input type="text" className="form-control" name="bestOfCoreSubject" placeholder='Best of 4 Core Subject % Upon' onChange={(e) => {
                 sendDataToParent(e.target.value, "twelve_4_core_subject");
             }} />
          </div>
      </div> 

    <div className="row">
                        <div className="set col">
                          <label for="inputPassword" className={`col-sm-3 col-form-label ${color}`}>Backlog Certificate</label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={(e) => {
                                sendDataToParent(e.target.files[0], "backlogCertificate");
                            }}
                          />
                        </div>
                      </div> 

        </>
    );
  };